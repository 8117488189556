import { CustomError } from 'ts-custom-error';

export class UseCaseError extends CustomError {
  customMessage =
    'Definice akcí pro práci s dokumenty není k dispozici. Zkontrolujte nastavení v Dativery a zkuste to prosím za chvíli';
  actionButtonText = 'Načíst znovu';
  resetBoundary = true;
  public constructor(message: string) {
    super(message);
  }
}

export class AppCustomError extends CustomError {
  customMessage =
    'Došlo k neočekáváané chybě. Zkuste aplikaci restartovat. Pokud problémy přetrvají, kontaktujte technickou podporu';
  actionButtonText = 'Načíst znovu';
  resetBoundary = true;
  public constructor(message: string, customMessage) {
    super(message);
    this.customMessage = customMessage ? customMessage : this.customMessage;
  }
}
