import { Alert, Snackbar, Typography } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import React from 'react';

export type ErrorMessageProps = StandardProps & {
  open: boolean;
  handleClose: (event: any) => void;
  message: string;
  severity?: AlertColor;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ open = true, message, severity = 'error', handleClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        <Typography component={'pre'}>{message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default ErrorMessage;
