import { List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { memo } from 'react';
import { Transaction, TransactionPouchEntity } from '../../../model';
import { HEADER_HEIGHT } from '../../common/AppHeader';
import TransactionsListItem from './TransactionsListItem';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    overflow: 'auto',
    paddingTop: HEADER_HEIGHT,
  },
  list: {
    padding: 0,
  },
}));

type Props = StandardProps & {
  onItemClick: (event: any) => void;
  items: Transaction[];
  searchText: string;
};

const TransactionsList: React.FC<Props> = memo(({ items, onItemClick, searchText, ...others }) => {
  const classes = useStyles({});

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <List className={classes.list} data-test="transactionsList">
        {items.map((item) => (
          <TransactionsListItem
            data-test="transaction"
            onClick={onItemClick}
            transaction={item}
            key={(item as TransactionPouchEntity)._id}
          />
        ))}
      </List>
    </div>
  );
});

export default TransactionsList;
