import { StateNode, ProcessConfig } from '../types';

export function htmlDialog(options: ProcessConfig): StateNode {
  return {
    entry: ['assignCustomMsg', 'appendDisplayedWarningById'],
    on: {
      GO_BACK: 'goBack',
    },
  };
}
