/* eslint-disable react-hooks/exhaustive-deps */
import debounce from 'debounce';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PouchDB, useAllDocs, useDB, useGet } from 'react-pouchdb';
import { useRoute, useRouter } from 'react-router5';
import { RouteState } from 'react-router5/dist/types';
import { toast } from 'react-toastify';
import { useLogger } from '../../appLogger';
import { AppConfigContext } from '../../config';
import { UseCaseProvider } from '../../hooks/useCase';
import { useUseCaseDefinition } from '../../hooks/useCaseDefinition';
import { Transaction, TransactionPouchEntity, User } from '../../model';
import { logOut, userState$ } from '../../service/authService';
import { DBInfo, init } from '../../service/pouchService';
import LoadingSpinner from '../common/LoadingSpinner';
import SyncError from '../errors/SyncError';
import ListView from '../listView/ListView';
import { transactionStatusOrder } from '../listView/sorting';
import ScannerView from '../scannerView/ScannerView';
import { Settings } from '../settings/Settings';

function List({ user, transactions }: { user: User; transactions: Transaction[] }) {
  const router = useRouter();
  if (!transactions) {
    router.navigate('app.transaction');
  }

  return (
    <ListView
      user={user}
      transactions={transactions}
      onLogout={() => {
        logOut();
        router.navigate('login');
      }}
      onTransactionClick={(transaction) => {
        router.navigate('app.transaction.detail', {
          id: encodeURIComponent((transaction as TransactionPouchEntity)._id),
        });
      }}
    />
  );
}

type DetailProps = {
  id: string;
  user: User;
};

const Detail: React.FC<DetailProps> = ({ id, user }) => {
  const db = useDB();
  const router = useRouter();
  // odo - there is an error with react listeners
  const transaction = useGet({ id: decodeURIComponent(id) });
  const appLogger = useLogger();
  const useCaseDefinition = useUseCaseDefinition(transaction.processId);
  const processConfig = useMemo(() => useCaseDefinition.processConfig, [useCaseDefinition]);

  const saveTransaction = useMemo(
    () =>
      debounce(async (transactionToSave: Transaction) => {
        if ((transactionToSave as TransactionPouchEntity)._rev !== (transaction as TransactionPouchEntity)._rev) {
          (transactionToSave as TransactionPouchEntity)._rev = (transaction as TransactionPouchEntity)._rev;
        }
        return db.put(transactionToSave).catch((error: any) => {
          // Note: In most cases conflicts could be ignored.
          // It may happen, that conflict in DB is the last save event. In that case, it should be handled
          // todo - handle conflict of last save
          appLogger.warn(error, 'Save transaction error: ' + error?.message);
        });
      }, 1000),
    [db, transaction]
  );

  if (!transaction) {
    router.navigate('app.transaction.list');
    return <div />;
  }

  return (
    <UseCaseProvider useCaseDefinition={processConfig}>
      <ScannerView
        transaction={transaction}
        user={user}
        navigateToList={() => {
          toast.dismiss();
          router.navigate('app.transaction.list');
        }}
        saveTransaction={saveTransaction}
      />
    </UseCaseProvider>
  );
};

interface AllDocRecord {
  doc: any;
}

function AppComponent({ route, user }: { route: RouteState['route']; user: User }) {
  const transactionsData: AllDocRecord[] = useAllDocs({
    include_docs: true,
    startkey: 'transaction-',
    endkey: 'transaction-\ufff0',
  });

  const transactions = transactionsData
    .map((item) => item.doc)
    .filter((doc) => transactionStatusOrder.includes(doc.status));

  switch (route.name) {
    case 'app.transaction.detail':
      return <Detail id={route.params.id} user={user} />;
    case 'app.settings':
      return <Settings />;
    default:
    case 'app.transaction.list':
      return <List transactions={transactions} user={user} />;
  }
}

enum Status {
  SYNCHRONIZATION = 'SYNCHRONIZATION',
  ERROR = 'ERROR',
  OK = 'OK',
}

export function AppRouterComponent() {
  const { route } = useRoute();
  const config = useContext(AppConfigContext);
  const [currentUser, setCurrentUser] = useState<User>({ company: 'NULL', username: 'Null User', tenantId: 'NULL' });
  const [status, setStatus] = useState(Status.SYNCHRONIZATION);
  const [dbName, setDbName] = useState<string | null>(null);
  const [dbInfo, setDbInfo] = useState<DBInfo | null>(null);
  const appLogger = useLogger();
  useEffect(() => {
    const userSubscription = userState$.subscribe((user) => {
      if (user) {
        setCurrentUser(user);

        const localDbName = `db-${user.company}`; // todo - tenant-id
        const remoteDbUrl = `${config.REACT_APP_API_URL}/app/db`;

        setDbName(localDbName);

        if (dbInfo?.syncHandler?.cancel) {
          dbInfo.syncHandler.cancel();
        }

        init(localDbName, remoteDbUrl, appLogger)
          .then((dbInfo) => {
            setDbInfo(dbInfo);
            setStatus(Status.OK);

            //init views on startup
            dbInfo.localDB
              .query('views/ean_code_view', {
                limit: 0, // don't return any results! Just init the view on app start, if does not exists
              })
              .then(function (result) {
                appLogger.debug('Init views-ean_code_view successfully', result); //todo - trace
              })
              .catch(function (err) {
                appLogger.error(err);
              });

            //cleanup views
            dbInfo.localDB
              .viewCleanup()
              .then(function (result) {
                // handle result
                appLogger.debug('cleanup views', result);
              })
              .catch(function (err) {
                appLogger.error(err);
              });

            // indexing progress
            dbInfo.localDB.on('indexing', (result) => {
              appLogger.debug('Indexing views', result);
            });

            appLogger.trace('initialization complete');
          })
          .catch((e) => {
            setStatus(Status.ERROR);
            appLogger.error(e, 'initialization error: ' + e.message);
          });
      } else {
        if (dbInfo) {
          dbInfo.syncHandler.cancel();
        }
      }
    });

    return () => {
      userSubscription.unsubscribe();
    };
  }, []);

  switch (status) {
    case Status.SYNCHRONIZATION:
      return <LoadingSpinner title="Synchronizace databáze...." />;

    case Status.ERROR:
      return (
        <SyncError
          title="Chyba synchronizace"
          onTryAgainClick={() => {
            window.location.reload();
          }}
        />
      );

    case Status.OK:
      return (
        <>
          {dbName && (
            <PouchDB name={dbName}>
              <AppComponent route={route} user={currentUser} />
            </PouchDB>
          )}
        </>
      );
  }
}
