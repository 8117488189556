import { all, any, equals, gt, lt, pathEq } from 'ramda';
import { Transaction, TransactionItem } from '../../model';
import { getCurrentItem } from './actions';

const compareCounts =
  (comparator: (a: number, b: number) => boolean) =>
  ({ amountCompleted, amount = 0 }: TransactionItem) => {
    return comparator(amountCompleted, amount);
  };

export const hasItemInsufficientAmount = compareCounts(lt);
export const hasItemRequiredAmount = compareCounts(equals);
export const hasItemExcessiveAmount = compareCounts(gt);

export const hasCode = (code: string) => pathEq(['product', 'code'], code);

export const isTransactionComplete = (transaction: Transaction) => {
  return all(hasItemRequiredAmount, transaction.items);
};

export const containsItemWithEan = (transaction: Transaction, code: string) => any(hasCode(code), transaction.items);

export function shouldNotifyInput(transaction: Transaction, currentIndex: number) {
  const currentItem = getCurrentItem(transaction, currentIndex);
  return currentItem && hasItemInsufficientAmount(currentItem);
}
