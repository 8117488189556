import { cannotOverrideCount, canOverrideCount, openTransaction } from '../stateDefinitionOptions';
import { ProcessConfig, StateNode } from '../types';

export function itemCounter(options: ProcessConfig): StateNode {
  const handleSerialNumbers = {
    target: 'editSerialNumbers',
    cond: (context) => options.tracking && context.transaction.items[context.currentIndex].product.trackSerialNumbers,
  };

  const handleBatchesAndExpirations = {
    target: 'editBatchesAndExpirations',
    cond: (context) =>
      options.tracking &&
      (context.transaction.items[context.currentIndex].product.trackBatches ||
        context.transaction.items[context.currentIndex].product.trackExpirations),
  };

  const handleItemCounter = {
    target: 'itemCounter',
    actions: [options.openList ? 'notifyNeedAction' : 'notifyInputIfNotCompleteOrOverAmounted'],
  };

  const always = cannotOverrideCount(options)
    ? [
        { target: 'itemComplete', cond: 'itemIsComplete' },
        { target: 'itemBadAmount', cond: 'itemHasBadAmount' },
      ]
    : [];
  const on =
    openTransaction(options) || canOverrideCount(options)
      ? {
          COMPLETE: { target: 'transactionBeforeComplete' },
        }
      : {};

  return {
    entry: ['addItemAmount', 'assignLastModifiedDate', 'save'],
    always,
    on: {
      PLUS: [handleSerialNumbers, handleBatchesAndExpirations, handleItemCounter],
      MINUS: [handleSerialNumbers, handleBatchesAndExpirations, handleItemCounter],
      CHANGE_AMOUNT: { target: 'itemCounter', actions: ['notifyInputIfNotCompleteOrOverAmounted'] },
      ENTER_EAN: { target: 'eanEntered', actions: ['notifyInputIfNotCompleteOrOverAmounted'] },
      ENTER_CODE: { target: 'codeEntered', actions: ['notifyInputIfNotCompleteOrOverAmounted'] },
      CLOSE_ITEM: { target: 'readyToScan', actions: 'clearCurrentCode' },
      DELETE_ITEM: [
        {
          target: 'itemCounter',
          actions: ['removeCurrentProduct', 'clearCurrentCode', 'save'],
        },
      ],
      ...on,
    },
  };
}
