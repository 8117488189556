import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDB } from 'react-pouchdb';
import { ScannerMachineMode, ScannerMachineType } from '../../receiver/scanner/scannerMachineOptions';
import { disconnectDevice } from '../../service/authService';
import { deleteDatabase } from '../../service/pouchService';
import { Theme } from '../../theme';
import { HEADER_HEIGHT } from '../common/AppHeader';
import router from '../router';
import { AppSettingKeys } from '../store/settings/appSettings';
import { useSettingsState } from '../store/settings/selectors';
import { SettingSelect } from './SettingSelect';
import SettingSwitch from './SettingSwitch';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    backgroundColor: palette.background.default,
    height: '100%',
  },
  header: {
    width: '100%',
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '10px',
  },
  content: {
    padding: spacing(8, 0, 0),
    height: '100%',
    overflow: 'auto',
  },
  settingSection: {
    backgroundColor: palette.background.paper,
  },
  back: {
    color: palette.secondary.contrastText,
  },
  sectionTitle: {
    [breakpoints.down('sm')]: {
      paddingLeft: spacing(2),
    },
  },
  listText: {
    paddingRight: 150,
  },
  topMargin: {
    marginTop: spacing(3),
  },
  buttonWidth: {
    width: 125,
  },
}));

export const Settings = () => {
  const classes = useStyles();
  const { scannerMachineMode, scannerMachineType } = useSettingsState();
  const db = useDB();

  const StartStopHelpText = {
    [ScannerMachineMode.DEFAULT]: 'Aplikace nepracuje s počátečními a koncovými znaky',
    [ScannerMachineMode.ZEBRA]: 'Aplikace předpokládá počáteční a koncové znaky "---"',
  };

  const ScannerMachineHelpText = {
    [ScannerMachineType.DEFAULT]: 'Defaultní zpracování znaků ze scanneru',
    [ScannerMachineType.INPUT]: 'Alternativní zpracování znaků ze skeneru pro starší čtečky (experimentální)',
  };

  const handleCloseClick = () => {
    router.navigate('app.transaction.list');
  };

  const handleDeleteDatabase = useCallback(() => {
    deleteDatabase(db).then(() =>
      router.navigate('app', () => {
        window.location.reload();
      })
    );
  }, [db]);

  const handleDisconnectDevice = useCallback(() => {
    disconnectDevice(db).then(() => window.location.reload());
  }, [db]);

  return (
    <Box className={classes.root} data-test="settingsPage">
      <AppBar color="secondary" className={classes.header}>
        <IconButton onClick={handleCloseClick} data-test={'settingsBack'} size="large">
          <ArrowBackIcon className={classes.back} />
        </IconButton>
        <Typography variant="h3">Nastavení</Typography>
      </AppBar>
      <Container className={classes.content} maxWidth="sm">
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Nastavení čtečky
        </Typography>
        <Paper square>
          <List dense>
            <ListItem classes={{ secondaryAction: classes.listText }}>
              <ListItemText
                primary="Počáteční a koncová sekvence"
                data-test="scanner-description"
                secondary={StartStopHelpText[scannerMachineMode]}
              />
              <ListItemSecondaryAction>
                <SettingSelect
                  data-test="scanner-select"
                  settingsKey={AppSettingKeys.scannerMachineMode}
                  valuesDefinition={[
                    { value: ScannerMachineMode.DEFAULT, text: 'Default' },
                    { value: ScannerMachineMode.ZEBRA, text: '---' },
                  ]}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem classes={{ secondaryAction: classes.listText }}>
              <ListItemText primary="Mód čtečky čárových kódů" secondary={ScannerMachineHelpText[scannerMachineType]} />
              <ListItemSecondaryAction>
                <SettingSelect
                  settingsKey={AppSettingKeys.scannerMachineType}
                  valuesDefinition={[
                    { value: ScannerMachineType.DEFAULT, text: 'Default' },
                    { value: ScannerMachineType.INPUT, text: 'Input' },
                  ]}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
        <Typography variant="subtitle1" className={classNames(classes.sectionTitle, classes.topMargin)}>
          Obsah
        </Typography>
        <Paper square>
          <List dense>
            <ListItem classes={{ secondaryAction: classes.listText }}>
              <ListItemText primary="Zobrazovat poznámku v seznamu dokladů" />
              <ListItemSecondaryAction>
                <SettingSwitch settingsKey={AppSettingKeys.showNoteInList} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
        <Typography variant="subtitle1" className={classNames(classes.sectionTitle, classes.topMargin)}>
          Experimentální
        </Typography>
        <Paper square>
          <List dense>
            <ListItem classes={{ secondaryAction: classes.listText }}>
              <ListItemText primary="Zobrazovat v dokladech množství produktu na skladě" />
              <ListItemSecondaryAction>
                <SettingSwitch settingsKey={AppSettingKeys.showProductTotalAmount} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
        <Typography variant="subtitle1" className={classNames(classes.sectionTitle, classes.topMargin)}>
          Akce zařízení
        </Typography>
        <Paper square>
          <List dense>
            <ListItem classes={{ secondaryAction: classes.listText }}>
              <ListItemText
                primary="Odstranění databáze ze zařízení"
                data-test="removeDatabase"
                secondary="Vymaže data ze zařízení a provede novou synchronizaci ze serveru"
              />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonWidth}
                  onClick={handleDeleteDatabase}
                >
                  Vymazat DB
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem classes={{ secondaryAction: classes.listText }}>
              <ListItemText
                primary="Odpárování zařízení"
                data-test="removeDatabase"
                secondary="Vymaže data a odebere zařízení ze společnosti"
              />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonWidth}
                  onClick={handleDisconnectDevice}
                >
                  Odpárovat
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
      </Container>
    </Box>
  );
};
