import { includes } from 'ramda';
import React, { useEffect, useMemo } from 'react';
import { useLogger } from '../../../appLogger';
import { useUseCaseDefinition } from '../../../hooks/useCaseDefinition';
import {
  Transaction,
  transactionStateTexts,
  TransactionStatus,
  TransactionType,
  transactionTypeTexts,
} from '../../../model';
import { getTenantInfo } from '../../../service/authService';
import { getDocumentTheme } from '../../common/helpers';
import { useSettingsState } from '../../store/settings/selectors';
import { TransactionsListItemPure } from './TransactionListItemPure';

type TransactionListProps = StandardProps & {
  onClick: (event: any) => void;
  transaction: Transaction;
};

const TransactionsListItem: React.FC<TransactionListProps> = (props) => {
  const { transaction } = props;
  const { showNoteInList } = useSettingsState();
  const useCaseDefinition = useUseCaseDefinition(transaction.processId);
  const appLogger = useLogger();

  const transactionType = useMemo(
    () => `${useCaseDefinition.name || transactionTypeTexts[TransactionType.UNKNOWN]}`,
    [useCaseDefinition]
  );
  const useCaseTheme = useMemo(() => getDocumentTheme(useCaseDefinition), [useCaseDefinition]);
  const suppressed = useMemo(
    () => includes(transaction.status, [TransactionStatus.INTERRUPTED, TransactionStatus.SUSPENDED]),
    [transaction.status]
  );
  const active = useMemo(() => includes(transaction.status, [TransactionStatus.IN_PROGRESS]), [transaction.status]);

  useEffect(() => {
    if (
      (transaction.status === TransactionStatus.ERROR && !transaction.responseError) ||
      (transaction.status !== TransactionStatus.ERROR && transaction.responseError)
    ) {
      appLogger.warn(
        `DATA INCONSISTENCY: Wrong ERROR combination for transaction: ${transaction.transNumber}. Tenant:${
          getTenantInfo().tenantId
        }`
      );
    }
  }, [transaction.transNumber, transaction.status, transaction.responseError, appLogger]);

  return (
    <TransactionsListItemPure
      {...props}
      error={transaction.status === TransactionStatus.ERROR || Boolean(transaction.responseError)}
      errorMsg={transaction.responseError}
      transactionType={transactionType}
      suppressed={suppressed}
      active={active}
      useCaseTheme={useCaseTheme}
      status={transactionStateTexts[transaction.status]}
      showNote={showNoteInList}
    />
  );
};

export default TransactionsListItem;
