import { StateNode, ProcessConfig } from '../types';
import { cannotOverrideCount } from '../stateDefinitionOptions';

export function itemSelected(options: ProcessConfig): StateNode {
  const always = cannotOverrideCount(options)
    ? [
        { target: 'itemCounter', cond: 'needsMorePieces' },
        { target: 'itemComplete', cond: 'itemIsComplete' },
        { target: 'itemBadAmount', cond: 'itemHasBadAmount' },
      ]
    : [{ target: 'itemCounter' }];
  return {
    entry: 'processSelection',
    always: [...always],
  };
}
