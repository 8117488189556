import PouchDB from 'pouchdb';
import { useCallback } from 'react';
import { useDB } from 'react-pouchdb';
import { useLogger } from '../appLogger';

export const useDbFind = () => {
  const db: PouchDB.Database<any> = useDB();
  const appLogger = useLogger();

  const dbFind = useCallback(
    async <T extends {}>(findRequest: PouchDB.Find.FindRequest<T>): Promise<PouchDB.Find.FindResponse<T>> => {
      try {
        const result = await db.find(findRequest);
        if (result.warning) {
          //todo - check sentry
          appLogger.warn({ findRequest: JSON.stringify(findRequest) }, result.warning);
        }
        return result;
      } catch (error: any) {
        appLogger.error(error, 'Error in find', { findRequest });
        throw error; // or return { docs: [] }; ?
      }
    },
    [db, appLogger]
  );

  return dbFind;
};
