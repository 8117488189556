import { StateNode, ProcessConfig } from '../types';

export function assignCurrentCode(options: ProcessConfig): StateNode {
  return {
    entry: ['assignCurrentCodeFromEvent'],
    always: [
      {
        target: 'itemCounter',
        actions: 'assignCurrentProductIndex',
      },
    ],
  };
}
