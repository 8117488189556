import { equals } from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import { HandlePosition, ProcessConfig, StateNode } from '../types';

export function handlePositions(options: ProcessConfig): StateNode {
  const hasPosition = (context, event) => event.data && isNotEmpty(event.data.position);
  const isIfEmptyConfig = equals(HandlePosition.IF_EMPTY, options.handlePosition);

  return {
    exit: 'positionHandled',
    invoke: isIfEmptyConfig
      ? {
          src: 'getWarehouseState',
          onDone: {
            cond: hasPosition,
            target: 'itemSelected',
          },
          onError: { target: 'itemSelected' },
        }
      : {
          src: 'getWarehouseState',
          onError: { target: 'itemSelected' },
        },
    on: {
      SAVE_POSITIONS: {
        actions: ['updatePositionWithChangeRequest'],
        target: 'itemSelected',
      },
      GO_BACK: 'itemSelected',
    },
  };
}
