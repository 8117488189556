import { ProcessConfig, StateNode } from '../types';

export function editBatchesAndExpirations(options: ProcessConfig): StateNode {
  return {
    invoke: {
      src: 'getWarehouseState',
      onDone: { actions: ['assignCurrentItemWarehouseState'] },
      onError: { target: 'preItemSelected' },
    },
    on: {
      PLUS_BATCH: {
        target: 'editBatchesAndExpirations',
        actions: ['notifyNeedAction', 'assignCurrentBatchIndex', 'addBatchAndExpirationAmount'],
      },
      MINUS_BATCH: {
        target: 'editBatchesAndExpirations',
        actions: ['notifyNeedAction', 'assignCurrentBatchIndex', 'addBatchAndExpirationAmount'],
      },
      ENTER_EAN: [
        {
          cond: 'hasBatchAndExpirationActionError',
          target: 'editBatchesAndExpirations',
          actions: ['notifyError'],
        },
        {
          cond: 'hasNotBatch',
          target: 'editBatchesAndExpirations',
          actions: ['assignBatchToItem', 'assignCurrentBatchIndex', 'addBatchAndExpirationAmount', 'notifyNeedAction'],
        },
        {
          target: 'editBatchesAndExpirations',
          actions: ['assignCurrentBatchIndex', 'addBatchAndExpirationAmount', 'notifyNeedAction'],
        },
      ],
      DISCARD_BATCH: {
        actions: ['assignCurrentBatchIndex', 'discardBatchFromItem'],
      },
      DELETE_ITEM: [
        {
          target: 'itemCounter',
          actions: ['removeCurrentProduct', 'save'],
        },
      ],
      SAVE_BATCH: {
        target: 'itemCounter',
        actions: ['notifyOk', 'assignLastModifiedDate', 'save'],
      },
    },
  };
}
