import { v4 as uuidv4 } from 'uuid';
import { ObjectType, TransactionOrigin, TransactionStatus, UseCaseDefinition, User } from '../model';

export async function createNewTransaction(
  localDB: PouchDB.Database,
  user: User,
  useCaseDefinition: UseCaseDefinition
) {
  const id = uuidv4();

  // todo - document interface
  const result = await localDB.put({
    _id: `${ObjectType.TRANSACTION}-${id}`,
    processId: useCaseDefinition.processId,
    objectType: ObjectType.TRANSACTION,
    dateCreated: new Date(),
    canEdit: true,
    items: [],
    status: TransactionStatus.NEW,
    transNumber: id.substr(0, 6),
    displayName: id.substr(0, 6),
    assignee: user,
    notifyUrl: useCaseDefinition.webhook,
    origin: TransactionOrigin.APP,
  });

  if (result.ok) {
    return result.id;
  } else {
    throw new Error('Creating new transaction failed');
  }
}
