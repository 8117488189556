import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ErrorOutline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

import { Theme } from '../../theme';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    borderTop: '2px solid',
    borderColor: palette.error.main,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${palette.error.light}0F`,
    minHeight: '208px',
  },
  errorIcon: {
    fontSize: '6rem',
    margin: 'auto',
  },
  header: {
    textAlign: 'center',
    marginTop: spacing(1),
    color: palette.error.main,
    fontWeight: 'bold',
  },

  actionButton: {
    margin: `auto 0 ${spacing(1)}`,
  },
}));

type Props = StandardProps & {
  onTryAgainClick: (event: any) => void;
  title?: string;
};

const SyncError: React.FC<Props> = ({ title, onTryAgainClick, ...others }) => {
  const classes = useStyles();
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Typography className={classes.header} variant="h5">
        <ErrorOutline className={classes.errorIcon} color="error" />
        {title}
      </Typography>
      <Button
        className={classes.actionButton}
        size="large"
        variant="contained"
        onClick={onTryAgainClick}
        color="primary"
      >
        Znovu načíst aplikaci
      </Button>
    </div>
  );
};

export default SyncError;
