import { path } from 'ramda';
import Expedice from '../../images/documents/expedice.png';
import Inventura from '../../images/documents/inventura.png';
import Prevodka from '../../images/documents/prevodka.png';
import Prijemka from '../../images/documents/prijemka.png';
import Vydejka from '../../images/documents/vydejka.png';
import Zamena from '../../images/documents/zamena.png';
import {
  ErrorMessages,
  ErrorSeverity,
  ErrorType,
  TransactionTypeColors,
  TransactionTypeIcons,
  UseCaseDefinition,
} from '../../model';
import theme, {
  blueTheme,
  brownTheme,
  greenTheme,
  greyTheme,
  orangeTheme,
  purpleTheme,
  redTheme,
  yellowTheme,
} from '../../theme';

export const getDocumentTheme = (useCaseDefinition: UseCaseDefinition) => {
  switch (path(['uiConfig', 'color'], useCaseDefinition)) {
    case TransactionTypeColors.GREEN:
      return greenTheme;
    case TransactionTypeColors.YELLOW:
      return yellowTheme;
    case TransactionTypeColors.BLUE:
      return blueTheme;
    case TransactionTypeColors.ORANGE:
      return orangeTheme;
    case TransactionTypeColors.GREY:
      return greyTheme;
    case TransactionTypeColors.RED:
      return redTheme;
    case TransactionTypeColors.BROWN:
      return brownTheme;
    case TransactionTypeColors.PURPLE:
      return purpleTheme;
    default:
      return theme;
  }
};

export const getDocumentColor = (useCaseDefinition: UseCaseDefinition) => {
  switch (path(['uiConfig', 'color'], useCaseDefinition)) {
    case TransactionTypeColors.GREEN:
      return greenTheme.palette.primary.main;
    case TransactionTypeColors.YELLOW:
      return yellowTheme.palette.primary.main;
    case TransactionTypeColors.BLUE:
      return blueTheme.palette.primary.main;
    case TransactionTypeColors.ORANGE:
      return orangeTheme.palette.primary.main;
    case TransactionTypeColors.GREY:
      return greyTheme.palette.primary.main;
    case TransactionTypeColors.RED:
      return redTheme.palette.primary.main;
    case TransactionTypeColors.BROWN:
      return brownTheme.palette.primary.main;
    case TransactionTypeColors.PURPLE:
      return purpleTheme.palette.primary.main;
    default:
      return theme.palette.primary.main;
  }
};

export const getDocumentIcon = (useCaseDefinition: UseCaseDefinition) => {
  switch (path(['uiConfig', 'icon'], useCaseDefinition)) {
    case TransactionTypeIcons.RECEIPT:
      return Prijemka;
    case TransactionTypeIcons.ISSUE:
      return Expedice;
    case TransactionTypeIcons.TRANSFER:
      return Prevodka;
    case TransactionTypeIcons.DISPATCH:
      return Vydejka;
    case TransactionTypeIcons.INVENTORY:
      return Inventura;
    case TransactionTypeIcons.SUBSTITUTION:
      return Zamena;
    default:
      return Prijemka;
  }
};

// Messages
export const errorMessages: ErrorMessages = {
  [ErrorType.PRODUCT_DOES_NOT_EXIST]: 'Neznámý produkt',
  [ErrorType.COUNT_ERROR]: 'Překročení maximálního počtu kusů',
  [ErrorType.PRODUCT_CODE_ERROR]: 'Zboží není v tomto dokladu',
};

export const errorInstructions: ErrorMessages = {
  [ErrorType.PRODUCT_DOES_NOT_EXIST]: 'Neznámý produkt',
  [ErrorType.COUNT_ERROR]:
    'Odeberte prosím přebytečné množství zboží a klikněte na "Opraveno" pro nastavení maximálního počtu kusů',
  [ErrorType.PRODUCT_CODE_ERROR]: 'Zboží není v tomto dokladu',
};

export const severityLevel: ErrorSeverity = {
  [ErrorType.PRODUCT_DOES_NOT_EXIST]: 'error',
  [ErrorType.PRODUCT_CODE_ERROR]: 'warning',
  [ErrorType.COUNT_ERROR]: 'warning',
};

// [::1] is the IPv6 localhost address.
const DEV_DOMAINS = ['mobilni-skladnik-app.commity.cz', 'localhost', '[::1]'];

const isDev = Boolean(
  DEV_DOMAINS.includes(window.location.hostname) ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    window.location.hostname.startsWith('192.168.') ||
    window.location.hostname.startsWith('10.0.') ||
    window.location.hostname.endsWith('.local')
);

export const getEnvironmentType = isDev ? 'dev' : '';
