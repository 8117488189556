import { useState, useCallback } from 'react';

export const useModalState = (onSuccess: Function, onCancel?: Function) => {
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    onCancel && onCancel();
    setOpen(false);
    return;
  }, [setOpen, onCancel]);

  const onConfirm = useCallback(() => {
    setOpen(false);
    onSuccess();
    return;
  }, [setOpen, onSuccess]);

  return {
    open,
    setOpen,
    onClose,
    onConfirm,
  };
};
