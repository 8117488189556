import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import debounce from 'debounce';
import PouchDB from 'pouchdb';
import { isEmpty, isNil, mergeAll } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDB, useGet } from 'react-pouchdb';
import { useDispatch } from 'react-redux';
import { useRoute } from 'react-router5';
import normalizeText from '../../../helpers/normalizeText';
import { useProcessConfig } from '../../../hooks/useCase';
import { Product, ProductPouchEntity, Transaction } from '../../../model';
import { useDbFind } from '../../../service/databaseService';
import { Theme } from '../../../theme';
import { setDisabledKeydown } from '../../store/scanner';
import ProductCodeInput, { SearchVariant } from './ProductCodeInput';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    outline: '1px solid',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogContent: {
    padding: spacing(0.5, 2),
    margin: 0,
  },
  dialogActions: {
    paddingTop: 0,
  },
  searchType: {
    position: 'absolute',
  },
  dialogTitle: {
    padding: '8px 24px',
    [breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      margin: 0,
    },
  },
  hidden: {
    display: 'none',
  },
}));

type Props = StandardProps & {
  open: boolean;
  onConfirm: (event: any) => void;
  onClose: (event: any) => void;
};

const ProductCodeModal: React.FC<Props> = ({ open = false, onConfirm, onClose, ...others }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { route } = useRoute();
  const transactionId = route.params.id;
  const dbFind = useDbFind();
  const db: PouchDB.Database = useDB();

  const transaction = useGet({ id: decodeURIComponent(transactionId) }) as Transaction;
  const transactionItems = useMemo(() => transaction.items.map(({ product }) => product), [transaction]);
  const { isOpen } = useProcessConfig();

  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [selectedCode, setSelectedCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchVariant, setSearchVariant] = useState<SearchVariant>(SearchVariant.Ean);
  const [options, setOptions] = useState<Product[]>(transactionItems);
  const [eanView, setEanView] = useState<{ [key in string]: number }>({});

  const disableConfirm = isNilOrEmpty(selectedCode) || !selectedCode;

  useEffect(() => {
    if (open) {
      dispatch(setDisabledKeydown(true));
    } else {
      dispatch(setDisabledKeydown(false));
    }
    setInputValue('');
  }, [open, dispatch]);

  /**
   * returns map of {ean: index} for search products
   */
  useEffect(() => {
    if (!isOpen) {
      setEanView(
        mergeAll(transactionItems.map((item, index) => item.ean.map((ean) => ({ [normalizeText(ean)]: index }))).flat())
      );
    }
  }, [isOpen, transactionItems]);

  const confirmCode = useCallback(() => {
    onConfirm(selectedCode);
    onClose({});
  }, [selectedCode, onConfirm, onClose]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      confirmCode();
    },
    [confirmCode]
  );

  const handleChangeSearchByText = useCallback(
    (event: SyntheticEvent, newValue: SearchVariant) => {
      setInputValue('');
      setSearchVariant(newValue);
    },
    [setInputValue, setSearchVariant]
  );

  // why not use useCallback https://github.com/facebook/react/issues/19240
  const getOptionsByText = useMemo(
    () =>
      debounce(async (text: string, fullText = false) => {
        if (isEmpty(text)) {
          setOptions(transactionItems);
          setIsLoading(false);
          return;
        }

        if (!isOpen) {
          if (fullText) {
            const result = transactionItems.filter((item) =>
              item.nameNormalized.toLowerCase().includes(normalizeText(text).toLowerCase())
            );
            setOptions(result);
          } else {
            const filteredEans = Object.keys(eanView).filter((ean) => ean.includes(normalizeText(text)));
            const filteredItems = filteredEans.map((ean) => transactionItems[eanView[ean]]);
            setOptions(filteredItems);
          }
        } else {
          if (fullText) {
            let result: PouchDB.Find.FindResponse<ProductPouchEntity> | undefined = await dbFind<ProductPouchEntity>({
              use_index: 'productsNamesIndex',
              selector: {
                objectType: { $eq: 'product' },
                nameNormalized: { $regex: RegExp(normalizeText(text), 'i') },
              },
              ...(() => (text.length > 3 ? {} : { limit: 5 }))(),
            });
            setOptions(result?.docs);
          } else {
            const response = await db.query<ProductPouchEntity>('views/ean_code_view', {
              startkey: normalizeText(text),
              endkey: normalizeText(text) + '\ufff0',
              ...(() => (text.length > 5 ? {} : { limit: 5 }))(),
              include_docs: true,
            });
            const docs: ProductPouchEntity[] = response.rows.map((row) => row.doc!);
            setOptions(docs);
          }
        }
        setIsLoading(false);
      }, 400),
    [db, dbFind, setOptions, setIsLoading, eanView, transactionItems, isOpen]
  );

  const findItemsByEan = useCallback(
    (text: string) => {
      getOptionsByText(text);
    },
    [getOptionsByText]
  );

  const findItemsByName = useCallback(
    (text: string) => {
      getOptionsByText(text, true);
    },
    [getOptionsByText]
  );

  const handleInputChange = useCallback(
    (_, value) => {
      //todo - add search in local options if isOpen === false
      setIsLoading(true);
      setOptions([]);
      if (searchVariant === SearchVariant.Name) {
        findItemsByName(value);
      } else {
        findItemsByEan(value);
      }
      setSelectedCode('');
      setInputValue(value);
    },
    [setInputValue, setOptions, findItemsByName, findItemsByEan, searchVariant]
  );

  const handleSelectionChangeCode = useCallback(
    (_, value) => {
      if (!value?.code) {
        setSelectedCode('');
      } else {
        setSelectedCode(value.code);
        setInputValue(value.code);
      }
    },
    [setSelectedCode]
  );

  const hasNoMatchingProducts = useMemo(() => {
    return !selectedCode && !isNil(inputValue) && isNilOrEmpty(options) && !isLoading;
  }, [options, inputValue, isLoading, selectedCode]);

  return (
    <Dialog open={open} fullScreen onClose={onClose} className={classNames(classes.root, others.className)}>
      <DialogTitle className={classes.dialogTitle}>Hledání produktu</DialogTitle>
      <IconButton aria-label="close" onClick={onClose} className={classes.closeButton} size="large">
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit}>
          <ProductCodeInput
            onInputChange={handleInputChange}
            onChange={handleSelectionChangeCode}
            setSelectedCode={setSelectedCode}
            options={options}
            searchVariant={searchVariant}
            inputValue={inputValue}
            handleChangeSearchByText={handleChangeSearchByText}
            isLoading={isLoading}
            error={hasNoMatchingProducts}
          />
          <button type="submit" className={classes.hidden}>
            Login
          </button>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} color="secondary" fullWidth variant="contained">
          Zrušit
        </Button>
        <Button
          data-test={'Potvrdit'}
          onClick={confirmCode}
          type="submit"
          color="primary"
          fullWidth
          variant="contained"
          disabled={disableConfirm}
        >
          Potvrdit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductCodeModal;
