import { StateNode, ProcessConfig } from '../types';

export function itemBadAmount(options: ProcessConfig): StateNode {
  return {
    entry: ['notifyError'],
    on: {
      FIX_ITEM_AMOUNT: {
        target: 'readyToScan',
        actions: 'fixItemAmount',
      },
    },
  };
}
