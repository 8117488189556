import { StateNode, ProcessConfig } from '../types';

export function itemToAdd(options: ProcessConfig): StateNode {
  return {
    entry: 'addCurrentProductToTransaction',
    always: [
      {
        target: 'preItemSelected',
        cond: 'isEanInTransaction',
        actions: ['assignCurrentProductCode', 'assignCurrentProductIndex'],
      },
      {
        target: 'preItemSelected',
        cond: 'isCodeInTransaction',
        actions: 'assignCurrentProductIndex',
      },
      {
        target: 'unknownItemInOrder',
        actions: 'notifyError',
      },
    ],
  };
}
