import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppConfigContext } from '../../config';
import { fastMachineFactory } from '../../receiver/scanner/stateMachine-fast';
import { authenticate, isPairedDevice, pair, requireLogin } from '../../service/authService';
import LoginPage from './LoginPage';

type Props = StandardProps & {
  onLogin: () => void;
};

const LoginView: React.FC<Props> = ({ onLogin }) => {
  const { REACT_APP_API_URL } = useContext(AppConfigContext);
  const [errorMessage, setErrorMessage] = useState('');

  const login = useCallback(
    (loginData?: string) => {
      authenticate(REACT_APP_API_URL, loginData)
        .then(() => {
          onLogin();
        })
        .catch((e) => {
          if (e.status === 403) {
            setErrorMessage('Neplatný kód');
          } else {
            setErrorMessage('Chyba serveru - zkuste to později');
          }
        });
    },
    [REACT_APP_API_URL, onLogin]
  );

  const submitHandler = useCallback(
    (loginData: any) => {
      setErrorMessage('');
      isPairedDevice()
        ? login(loginData)
        : pair(REACT_APP_API_URL, loginData)
            .then(() => {
              document.location.reload();
            })
            .catch((e) => {
              if (e.status === 403) {
                setErrorMessage('Neplatný kód');
              } else {
                setErrorMessage('Chyba serveru - zkuste to později');
              }
            });
    },
    [login, setErrorMessage, REACT_APP_API_URL]
  );

  useEffect(() => {
    if (isPairedDevice() && !requireLogin()) {
      login();
    }
  }, [login]);

  const onCodeReceived = useCallback(
    (qrCode: string) => {
      if (qrCode.indexOf('MS:pair:') !== -1) {
        submitHandler(qrCode.replace(/[^0-9]/g, ''));
      } else {
        submitHandler(qrCode);
      }
    },
    [submitHandler]
  );

  const receiver = useMemo(() => fastMachineFactory(onCodeReceived), [onCodeReceived]);

  const readKeyboard = useCallback(
    (event: KeyboardEvent | InputEvent) => {
      const key = (event as KeyboardEvent).key || (event as InputEvent).data || '';

      receiver.send({ type: 'KEYPRESS', key });
    },
    [receiver]
  );

  useEffect(() => {
    document.addEventListener('keydown', readKeyboard);

    return () => {
      document.removeEventListener('keydown', readKeyboard);
    };
  }, [readKeyboard]);

  return <LoginPage data-test="loginPage" onSubmit={submitHandler} errorMessage={errorMessage} />;
};

export default LoginView;
