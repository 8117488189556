import { StateNode, ProcessConfig } from '../types';

export function itemComplete(options: ProcessConfig): StateNode {
  return {
    entry: ['notifyOk', 'assignLastModifiedDate', 'save'],
    on: {
      CLOSE_ITEM: 'readyToScan',
      ENTER_EAN: 'eanEntered',
      ENTER_CODE: 'codeEntered',
    },
    always: [
      {
        target: 'readyToScan',
      },
    ],
  };
}
