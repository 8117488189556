import { ascend, descend, indexOf, isNil, pipe, sortWith } from 'ramda';
import { Transaction, TransactionStatus } from '../../model';

export const transactionStatusOrder = [
  TransactionStatus.ERROR,
  TransactionStatus.IN_PROGRESS,
  TransactionStatus.INTERRUPTED,
  TransactionStatus.NEW,
  TransactionStatus.SUSPENDED,
];

const getDateString = (item: Transaction) => {
  return isNil(item.lastModifiedDate) ? new Date().toISOString() : item.lastModifiedDate;
};
const getDisplayName = (item: Transaction) => (item.displayName ? item.displayName : item.transNumber);

const sortByStatus = ascend<Transaction>((transition) => indexOf(transition.status, transactionStatusOrder));
const sortByDate = descend<Transaction>(pipe(getDateString, (date: string) => new Date(date)));

const sortByNameAsc = ascend<Transaction>(getDisplayName);
const sortByNameDesc = descend<Transaction>(getDisplayName);

export enum SortType {
  BY_STATE_AND_TIME = 'byStateAndTime',
  BY_STATE_AND_NAME = 'byStateAndName',
  BY_NAME_ASC = 'byNameAsc',
  BY_NAME_DESC = 'byNameDesc',
  BY_TIME = 'byTime',
}

export type SortDefinition = {
  type: SortType;
  title: string;
  sorting: Function; // TODO better type
};

export const sortDefinitions: SortDefinition[] = [
  {
    type: SortType.BY_STATE_AND_TIME,
    title: 'Dle stavu a data',
    sorting: sortWith<Transaction>([sortByStatus, sortByDate]),
  },
  {
    type: SortType.BY_TIME,
    title: 'Dle času',
    sorting: sortWith<Transaction>([sortByDate]),
  },
  {
    type: SortType.BY_STATE_AND_NAME,
    title: 'Dle stavu a názvu',
    sorting: sortWith<Transaction>([sortByStatus, sortByNameAsc]),
  },
  {
    type: SortType.BY_NAME_ASC,
    title: 'Dle názvu (A-Z)',
    sorting: sortWith<Transaction>([sortByNameAsc]),
  },
  {
    type: SortType.BY_NAME_DESC,
    title: 'Dle názvu (Z-A)',
    sorting: sortWith<Transaction>([sortByNameDesc]),
  },
];

export function getSortingFunction(type: SortType) {
  return (sortDefinitions.find((d) => d.type === type) as SortDefinition).sorting;
}
