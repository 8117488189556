import { all, any, equals, gt, includes, lt, pathEq, pathOr, pipe, propEq } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { toast } from 'react-toastify';
import {
  BatchAndExpiration,
  Transaction,
  TransactionItem,
  TransactionStatus,
  WarehouseStatePouchEntity,
} from '../../../model';
import { getCurrentItem } from './actions';
import { HandlePosition, ScannerViewContext, TrackingWarehouseOperation } from './types';

const compareItemAmount =
  (comparator: (a: number, b: number) => boolean) =>
  ({ amountCompleted, amount = 0 }: TransactionItem) => {
    return comparator(amountCompleted, amount);
  };

const compareBatchAmount =
  (comparator: (a: number, b: number) => boolean) =>
  ({ expectedAmount = 0, amount }: BatchAndExpiration) => {
    return comparator(amount, expectedAmount);
  };

export const hasItemInsufficientAmount = compareItemAmount(lt);
export const hasItemRequiredAmount = compareItemAmount(equals);
export const hasItemExcessiveAmount = compareItemAmount(gt);

export const hasBatchRequiredAmount = compareBatchAmount(equals);

export const hasCode = (code: string) => pathEq(['product', 'code'], code);
export const hasEan = (code: string) => pipe(pathOr([], ['product', 'ean']), includes(code));
export const hasBatch = (batchId: BatchAndExpiration['id']) => propEq('id', batchId);

export const isTransactionNew = (transaction: Transaction) => transaction.status === TransactionStatus.NEW;
export const isTransactionComplete = (transaction: Transaction) =>
  [TransactionStatus.COMPLETE, TransactionStatus.ERROR].includes(transaction.status);

export const isTransactionFulfilled = (transaction: Transaction) => all(hasItemRequiredAmount, transaction.items);

export const containsItemWithCode = (transaction: Transaction, code: string) => any(hasCode(code), transaction.items);
export const containsItemWithEan = (transaction: Transaction, ean: string) => any(hasEan(ean), transaction.items);

export const transactionHasNoteEdited = ({ noteContext }: ScannerViewContext) => noteContext?.hasNoteEdited;
export const transactionHasRequiredNote = ({ noteContext }: ScannerViewContext) => noteContext?.hasNoteRequired;
export const validateNoteRequirements = (context: ScannerViewContext) =>
  transactionHasRequiredNote(context) && !transactionHasNoteEdited(context);

export const validatePositionRequirements = (context: ScannerViewContext) => {
  const decisionMap = {
    [HandlePosition.NEVER]: false,
    [HandlePosition.ALWAYS]: true,
    [HandlePosition.IF_EMPTY]: !context.positionContext.handledCodes.includes(context.currentCode),
    [HandlePosition.ON_REQUEST]: false,
  };
  return decisionMap[context.processConfig.handlePosition];
};

export function shouldNotifyInput(transaction: Transaction, currentIndex: number) {
  const currentItem = getCurrentItem(transaction, currentIndex);
  return currentItem && hasItemInsufficientAmount(currentItem);
}

export const hasExpectedSerialNumbersInTransaction = (transaction: Transaction, currentIndex: number) => {
  return isNotNilOrEmpty(transaction.items[currentIndex]?.expectedSerialNumbers);
};

export const hasExpectedSerialNumber = (transaction: Transaction, currentIndex: number, code: string) => {
  return (transaction.items[currentIndex]?.expectedSerialNumbers ?? []).includes(code);
};

export const hasWarehouseSerialNumber = (
  currentWarehouseState: WarehouseStatePouchEntity | undefined,
  code: string
) => {
  return (currentWarehouseState?.serialNumbers ?? []).includes(code);
};

export const hasSerialNumberMarkedGuard = (transaction: Transaction, currentIndex: number, code: string) => {
  return (transaction.items[currentIndex]?.serialNumbers ?? []).includes(code);
};

export const isSerialNumberActionValid = (
  { processConfig, transaction, ...context }: ScannerViewContext,
  code: string
) => {
  const trackingWarehouseOperation: TrackingWarehouseOperation = processConfig.trackingWarehouseOperation;

  const hasExpectedSerialNumbers = hasExpectedSerialNumbersInTransaction(transaction, context.currentIndex);
  const hasSerialNumberMarked = hasSerialNumberMarkedGuard(transaction, context.currentIndex, code);

  if (processConfig.fixedCount && hasItemRequiredAmount(transaction.items[context.currentIndex])) {
    toast.error('Výrobek má plný počet');
    return false;
  }

  switch (trackingWarehouseOperation) {
    case TrackingWarehouseOperation.ADD:
      if (hasExpectedSerialNumbers && !processConfig.trackingOpenDocument) {
        if (processConfig.trackingExisting === 'warn' && hasSerialNumberMarked) {
          toast.warn('Výrobní číslo v dokladu již existuje');
        }
        if (processConfig.trackingExisting === 'error' && hasSerialNumberMarked) {
          toast.error('Výrobní číslo v dokladu již existuje');
          return false;
        }

        const result = hasExpectedSerialNumber(transaction, context.currentIndex, code);
        if (!result) {
          toast.error('Výrobní číslo není v dokladu');
        }
        return result;
      }
      //has SN and is NOT limited to expected SN
      if (hasExpectedSerialNumbers && processConfig.trackingOpenDocument) {
        if (
          processConfig.trackingExisting === 'warn' &&
          (hasSerialNumberMarked || hasWarehouseSerialNumber(context.currentWarehouseState, code))
        ) {
          toast.warn('Výrobní číslo v dokladu již existuje');
        }
        if (
          processConfig.trackingExisting === 'error' &&
          (hasSerialNumberMarked || hasWarehouseSerialNumber(context.currentWarehouseState, code))
        ) {
          toast.error('Výrobní číslo v dokladu již existuje');
          return false;
        }
        return true;
      }

      // has NOT expected SN and is limited to expected SN
      if (!hasExpectedSerialNumbers && !processConfig.trackingOpenDocument) {
        if (
          processConfig.trackingExisting === 'warn' &&
          (hasSerialNumberMarked || hasWarehouseSerialNumber(context.currentWarehouseState, code))
        ) {
          toast.warn('Výrobní číslo v dokladu již existuje');
        }
        if (
          processConfig.trackingExisting === 'error' &&
          (hasSerialNumberMarked || hasWarehouseSerialNumber(context.currentWarehouseState, code))
        ) {
          toast.error('Výrobní číslo v dokladu již existuje');
          return false;
        }

        return true;
      }

      // has NOT expected SN and is NOT limited to expected SN
      if (!hasExpectedSerialNumbers && processConfig.trackingOpenDocument) {
        if (
          processConfig.trackingExisting === 'warn' &&
          (hasSerialNumberMarked || hasWarehouseSerialNumber(context.currentWarehouseState, code))
        ) {
          toast.warn('Výrobní číslo v dokladu již existuje');
        }
        if (
          processConfig.trackingExisting === 'error' &&
          (hasSerialNumberMarked || hasWarehouseSerialNumber(context.currentWarehouseState, code))
        ) {
          toast.error('Výrobní číslo v dokladu již existuje');
          return false;
        }

        return true;
      }
      break;

    case TrackingWarehouseOperation.REMOVE:
      if (processConfig.trackingMissing === 'warn' && !hasWarehouseSerialNumber(context.currentWarehouseState, code)) {
        toast.warn('Výrobní číslo není skladem');
      }
      if (processConfig.trackingMissing === 'error' && !hasWarehouseSerialNumber(context.currentWarehouseState, code)) {
        toast.error('Výrobní číslo není skladem');
        return false;
      }

      if (hasExpectedSerialNumbers) {
        //if trackingOpenDocument is false, trackingOpenWarehouse is irrelevant
        if (!processConfig.trackingOpenDocument) {
          return !hasSerialNumberMarked && hasExpectedSerialNumber(transaction, context.currentIndex, code);
        }

        if (processConfig.trackingOpenDocument && !processConfig.trackingOpenWarehouse) {
          return (
            !hasSerialNumberMarked &&
            (hasExpectedSerialNumber(transaction, context.currentIndex, code) ||
              hasWarehouseSerialNumber(context.currentWarehouseState, code))
          );
        }
        return false;
      }

      if (!hasExpectedSerialNumbers) {
        if (!processConfig.trackingOpenDocument) {
          return false;
        }

        if (processConfig.trackingOpenDocument && !processConfig.trackingOpenWarehouse) {
          const result = !hasSerialNumberMarked && hasWarehouseSerialNumber(context.currentWarehouseState, code);
          if (!result) {
            toast.error('Výrobní číslo není skladem');
          }
          return result;
        }
        return false;
      }
      break;

    case TrackingWarehouseOperation.NONE:
      break;
  }
  toast.error(`Výrobní číslo ${code} nelze zpracovat`);
  return false;
};

export const hasBatchInWarehouseState = (
  currentWarehouseState: WarehouseStatePouchEntity | undefined,
  batchId: string
) => {
  return currentWarehouseState?.batchesAndExpirations?.some((batchAndExpiration) => batchAndExpiration.id === batchId);
};

export const hasBatchInTransaction = (transactionItem: TransactionItem | undefined, batchId: string) => {
  return transactionItem?.batchesAndExpirations?.some((batchAndExpiration) => batchAndExpiration.id === batchId);
};

export const isBatchAndExpirationActionValid = (
  { processConfig, transaction, currentIndex, ...context }: ScannerViewContext,
  batchId: string
) => {
  if (!batchId) {
    toast.error('Neznámá chyba při zpracování šarže');
    return false;
  }

  if (!processConfig.trackingOpenDocument && !hasBatchInTransaction(transaction.items[currentIndex], batchId)) {
    toast.error('Šarže není v dokladu');
    return false;
  }

  const trackingWarehouseOperation: TrackingWarehouseOperation = processConfig.trackingWarehouseOperation;

  switch (trackingWarehouseOperation) {
    case TrackingWarehouseOperation.ADD:
      return true;
    case TrackingWarehouseOperation.REMOVE:
      if (
        processConfig.trackingMissing === 'warn' &&
        !hasBatchInWarehouseState(context.currentWarehouseState, batchId)
      ) {
        toast.warn('Neočekávaná šarže');
        return true;
      }
      if (
        processConfig.trackingMissing === 'error' &&
        !hasBatchInWarehouseState(context.currentWarehouseState, batchId)
      ) {
        toast.error('Šarže není skladem');
        return false;
      }
      break;
    case TrackingWarehouseOperation.NONE:
      return true;
  }

  return true;
};

export const hasSerialNumberGuardDeprecated = (
  transaction: Transaction,
  currentIndex: number,
  code: string,
  currentWarehouseState: WarehouseStatePouchEntity | undefined
) => {
  return (
    (transaction.items[currentIndex]?.serialNumbers ?? []).includes(code) ||
    currentWarehouseState?.serialNumbers?.includes(code)
  );
};
