import { createInstance } from '@jonkoops/matomo-tracker-react';
import { AppConfig } from './config';

export const matomoInstance = ({ REACT_APP_MATOMO_URL_BASE, REACT_APP_MATOMO_SITE_ID }: AppConfig) =>
  createInstance({
    urlBase: REACT_APP_MATOMO_URL_BASE,
    siteId: REACT_APP_MATOMO_SITE_ID,
    trackerUrl: `${REACT_APP_MATOMO_URL_BASE}/matomo.php`, // optional, default value: `${urlBase}matomo.php`
    srcUrl: `${REACT_APP_MATOMO_URL_BASE}/matomo.js`, // optional, default value: `${urlBase}matomo.js`
    disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
      // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10, // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: {
      // optional, default value: {}
      // any valid matomo configuration, all below are optional
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST',
    },
  });
