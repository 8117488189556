import { ArrowForward, Close, Search } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isNonEmptyString } from 'ramda-adjunct';
import React, { useCallback, useMemo } from 'react';
import { useProcessConfig } from '../../../../hooks/useCase';
import { useXState } from '../../../../hooks/xState';
import { TransactionItem } from '../../../../model';
import { Theme } from '../../../../theme';
import { EventTypes, ScannerViewContext, TrackingWarehouseOperation } from '../../stateMachine';
import { useStateMachineEvents } from '../../useStateMachineEvents';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  formField: {
    margin: spacing(4, 0),
  },
  toBeDeleted: {
    color: palette.error.main,
  },
}));

type Props = StandardProps & {
  onClose?: () => void;
  transactionItem: TransactionItem;
  context: ScannerViewContext;
};

const helpText = {
  [TrackingWarehouseOperation.ADD]: 'Skenováním přidejte nová sériová čísla',
  [TrackingWarehouseOperation.REMOVE]: 'Skenováním označte sériová čísla k výdeji',
  [TrackingWarehouseOperation.NONE]: 'Skenováním označte sériová čísla',
};

const TransactionSerialNumbersDialog: React.FC<Props> = ({ onClose, transactionItem, context, ...others }) => {
  const classes = useStyles();
  const { trackingWarehouseOperation, openList, trackingOpenDocument, countOverrides } = useProcessConfig();

  const { send } = useXState();
  const stateMachineEvents = useStateMachineEvents(send);

  const [showEANField, setShowEANField] = React.useState(false);
  const [newValue, setNewValue] = React.useState('');

  const hasExpectedSN = useMemo(
    () => (context.transaction.items[context.currentIndex]?.expectedSerialNumbers ?? []).length > 0,
    [context.transaction.items, context.currentIndex]
  );

  const { showTracked, showExpected, showWarehoused } = useMemo(() => {
    switch (trackingWarehouseOperation) {
      case TrackingWarehouseOperation.ADD:
        return {
          showTracked: !(hasExpectedSN && countOverrides && !trackingOpenDocument),
          showExpected: hasExpectedSN,
          showWarehoused: false,
        };

      case TrackingWarehouseOperation.REMOVE:
        // Define the conditions for REMOVE operation
        return {
          showTracked: false,
          showExpected: hasExpectedSN,
          showWarehoused: trackingOpenDocument,
        };

      case TrackingWarehouseOperation.NONE:
        // Define the conditions for NONE operation
        return { showTracked: false, showExpected: hasExpectedSN, showWarehoused: false };
    }
  }, [hasExpectedSN, countOverrides, trackingOpenDocument, trackingWarehouseOperation]);

  const filteredWsSerialNumbers = (context.currentWarehouseState?.serialNumbers ?? [])
    //show selected first
    .sort((item) => (context.transaction.items[context.currentIndex]?.serialNumbers?.includes(item) ? -1 : 1))
    //show search results only
    .filter((item) => (isNonEmptyString(newValue) ? item.includes(newValue) : true));

  const handleManuallyAdd = useCallback(() => {
    if (isNonEmptyString(newValue)) {
      stateMachineEvents.enterEan(String(newValue));
    }
  }, [newValue, stateMachineEvents]);

  const handleSave = useCallback(() => {
    if (openList && context.transaction.items[context.currentIndex].amountCompleted === 0) {
      stateMachineEvents.deleteCurrentItem();
    } //todo - probably should be else
    send(EventTypes.SAVE_SERIAL_NUMBERS);
  }, [openList, context.transaction.items, stateMachineEvents, send, context.currentIndex]);

  const isItemMarked = useCallback(
    (item) => {
      return (context.transaction.items[context.currentIndex]?.serialNumbers ?? []).includes(item);
    },
    [context.transaction.items, context.currentIndex]
  );

  const isItemExpected = useCallback(
    (item) => {
      return (context.transaction.items[context.currentIndex]?.expectedSerialNumbers ?? []).includes(item);
    },
    [context.transaction.items, context.currentIndex]
  );

  const handleDiscard = useCallback(
    (item) => {
      stateMachineEvents.discardSerialNumber(item);
    },
    [stateMachineEvents]
  );

  return (
    <Dialog open {...others} className={classNames(classes.root)} data-test="positionModal" fullWidth>
      <DialogTitle variant={'h3'} sx={{ p: 1 }}>
        {transactionItem.product.name}
      </DialogTitle>
      <IconButton onClick={handleSave} className={classes.closeButton} size="large">
        <Close />
      </IconButton>
      <DialogContent sx={{ p: 1, pt: 0 }}>
        <DialogContentText color={'text.primary'} component={'div'}>
          {/*common for all*/}
          {showExpected && (
            <>
              <Typography variant={'h6'}>Očekáváná sériová čísla</Typography>
              <List sx={{ gap: '2px', display: 'flex', flexWrap: 'wrap' }}>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setShowEANField((currentValue) => !currentValue)}
                >
                  <Search />
                </IconButton>
                {context.transaction.items[context.currentIndex]?.expectedSerialNumbers?.map((item, index) => (
                  <Chip
                    color={isItemMarked(item) ? 'primary' : 'default'}
                    key={index + item}
                    label={item}
                    onDelete={isItemMarked(item) ? () => handleDiscard(item) : undefined}
                  />
                ))}
              </List>
            </>
          )}

          {trackingWarehouseOperation === TrackingWarehouseOperation.ADD && (
            <>
              {showTracked && (
                <>
                  <Typography variant={'h6'}>Nová sériová čísla</Typography>
                  <List sx={{ gap: '2px', display: 'flex', flexWrap: 'wrap' }}>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setShowEANField((currentValue) => !currentValue)}
                    >
                      <Add />
                    </IconButton>
                    {context.transaction.items[context.currentIndex]?.serialNumbers
                      //?.filter((item) => !isItemExpected(item))
                      ?.map((item, index) => (
                        <Chip
                          color={hasExpectedSN && isItemMarked(item) ? 'primary' : 'default'}
                          key={index + item}
                          label={item}
                          onDelete={() => handleDiscard(item)}
                        />
                      ))}
                  </List>
                </>
              )}
            </>
          )}

          {trackingWarehouseOperation === TrackingWarehouseOperation.REMOVE && (
            <>
              {showTracked && (
                <>
                  <Typography variant={'h6'}>Nová sériová čísla</Typography>
                  <List sx={{ gap: '2px', display: 'flex', flexWrap: 'wrap' }}>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setShowEANField((currentValue) => !currentValue)}
                    >
                      <Add />
                    </IconButton>
                    {context.transaction.items[context.currentIndex]?.serialNumbers
                      ?.filter((item) => !isItemExpected(item))
                      .map((item, index) => (
                        <Chip
                          color={hasExpectedSN && isItemMarked(item) ? 'primary' : 'default'}
                          key={index + item}
                          label={item}
                          onDelete={() => handleDiscard(item)}
                        />
                      ))}
                  </List>
                </>
              )}

              {showWarehoused && (
                <>
                  <Typography variant={'h6'} sx={{ mt: 1 }}>
                    Existující sériové čísla
                  </Typography>
                  <List sx={{ gap: '2px', display: 'flex', flexWrap: 'wrap' }}>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setShowEANField((currentValue) => !currentValue)}
                    >
                      <Search />
                    </IconButton>
                    {filteredWsSerialNumbers.map((item, index) => (
                      <Chip
                        color={isItemMarked(item) ? 'primary' : 'default'}
                        key={index + item}
                        label={item}
                        onDelete={isItemMarked(item) ? () => handleDiscard(item) : undefined}
                        onClick={() => showEANField && setNewValue(item)}
                      />
                    ))}
                  </List>
                </>
              )}
            </>
          )}

          {trackingWarehouseOperation === TrackingWarehouseOperation.NONE && (
            <>
              <Typography variant={'h6'} sx={{ mt: 1 }}>
                Existující sériové čísla
              </Typography>
              <List sx={{ gap: '2px', display: 'flex', flexWrap: 'wrap' }}>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setShowEANField((currentValue) => !currentValue)}
                >
                  <Search />
                </IconButton>
                {filteredWsSerialNumbers.map((item, index) => (
                  <Chip color={isItemMarked(item) ? 'primary' : 'default'} key={index + item} label={item} />
                ))}
              </List>
            </>
          )}
          {showEANField && (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, width: '100%' }}>
              <TextField
                autoFocus
                autoComplete={'off'}
                variant="standard"
                color="primary"
                fullWidth
                helperText="Vložte sériové číslo"
                value={newValue}
                onChange={(event) => setNewValue(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleManuallyAdd();
                  }
                }}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ height: 36, width: 36, minWidth: 36 }}
                onClick={handleManuallyAdd}
              >
                <ArrowForward />
              </Button>
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary" fullWidth variant="contained">
          Pokračovat
        </Button>
      </DialogActions>
      <Typography sx={{ fontSize: '.75rem', m: 0, p: 0, textAlign: 'center' }}>
        {helpText[trackingWarehouseOperation]}
      </Typography>
    </Dialog>
  );
};

export default TransactionSerialNumbersDialog;
