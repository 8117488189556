import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isNil } from 'ramda';
import React, { memo } from 'react';
import { WarehouseStatePouchEntity } from '../../../model';
import { Theme } from '../../../theme';

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    color: palette.text.secondary,
    fontSize: '0.875rem',
    marginRight: spacing(1),
  },
}));

type Props = StandardProps & {
  amount?: WarehouseStatePouchEntity['amount'];
};

const TransactionItemAmount: React.FC<Props> = memo(({ className, amount }) => {
  const classes = useStyles();

  if (isNil(amount)) {
    return null;
  }

  return <div className={classNames(classes.root, className)}>({amount} ks)</div>;
});

export default TransactionItemAmount;
