import { ArrowForward, Close, Search } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isNonEmptyString } from 'ramda-adjunct';
import React, { useCallback, useMemo } from 'react';
import { useProcessConfig } from '../../../../hooks/useCase';
import { useXState } from '../../../../hooks/xState';
import { BatchAndExpiration, TransactionItem } from '../../../../model';
import { Theme } from '../../../../theme';
import PlusMinusCounter from '../../../common/PlusMinusCounter';
import { EventTypes, ScannerViewContext, TrackingWarehouseOperation } from '../../stateMachine';
import { useStateMachineEvents } from '../../useStateMachineEvents';

type BatchesAndExpirationsModalProps = StandardProps & {
  onClose?: () => void;
  transactionItem: TransactionItem;
  context: ScannerViewContext;
};

const helpText = {
  [TrackingWarehouseOperation.ADD]: 'Skenováním šarže zvyšte počet',
  [TrackingWarehouseOperation.REMOVE]: 'Skenováním šarže zvyšte počet',
  [TrackingWarehouseOperation.NONE]: 'Skenováním šarže zvyšte počet',
};

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {},
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const TransactionBatchesAndExpirationsDialog: React.FC<BatchesAndExpirationsModalProps> = ({
  onClose,
  transactionItem,
  context,
  ...others
}) => {
  const classes = useStyles();
  const { trackingWarehouseOperation, fixedCount, openList, countOverrides } = useProcessConfig();

  const { send } = useXState();
  const stateMachineEvents = useStateMachineEvents(send);

  const [showEANField, setShowEANField] = React.useState(false);
  const [newValue, setNewValue] = React.useState('');

  const batchesAndExpirationsList = useMemo(() => {
    return [...(transactionItem.batchesAndExpirations ?? []), ...(transactionItem.expectedBatchesAndExpirations ?? [])];
  }, [transactionItem.batchesAndExpirations, transactionItem.expectedBatchesAndExpirations]);

  const autocompleteOptions = useMemo(() => {
    return (
      context?.currentWarehouseState?.batchesAndExpirations?.map((batch) => ({
        label: batch.batchName,
        id: batch.id,
      })) ?? []
    );
  }, [context.currentWarehouseState?.batchesAndExpirations]);

  const handleManuallyAdd = useCallback(() => {
    if (isNonEmptyString(newValue)) {
      stateMachineEvents.enterEan(String(newValue));
    }
  }, [newValue, stateMachineEvents]);

  const handleSave = useCallback(() => {
    if (openList && context.transaction.items[context.currentIndex].amountCompleted === 0) {
      stateMachineEvents.deleteCurrentItem();
    } else {
      send(EventTypes.SAVE_BATCH);
    }
  }, [openList, context.transaction.items, stateMachineEvents, send, context.currentIndex]);

  // const onPlusClick = () => onPlus(calculateIncreaseQuantity(transactionItem, isCountOverrides, 1));
  // const onMinusClick = () => onMinus(1);

  const onPlusClick = useCallback(
    (batchId: BatchAndExpiration['id']) => {
      stateMachineEvents.plusBatch(batchId);
    },
    [stateMachineEvents]
  );

  const onMinusClick = useCallback(
    (batchId: BatchAndExpiration['id']) => {
      stateMachineEvents.minusBatch(batchId);
    },
    [stateMachineEvents]
  );

  return (
    <Dialog open {...others} className={classNames(classes.root)} data-test="positionModal" fullWidth>
      <DialogTitle variant={'h3'} sx={{ p: 1 }}>
        {transactionItem.product.name}
      </DialogTitle>
      <Box className={classes.closeButton}>
        <IconButton
          aria-label="close"
          color="warning"
          size="small"
          onClick={() => setShowEANField((currentValue) => !currentValue)}
        >
          {trackingWarehouseOperation === TrackingWarehouseOperation.ADD ? <Add /> : <Search />}
        </IconButton>
        <IconButton onClick={handleSave} size="large">
          <Close />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 1, pt: 0 }}>
        <DialogContentText color={'text.primary'} component={'div'}>
          <Typography variant={'h6'}>Šarže</Typography>
        </DialogContentText>

        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {batchesAndExpirationsList.map((row) => (
            <ListItem
              disablePadding
              alignItems="flex-start"
              secondaryAction={
                <React.Fragment>
                  <PlusMinusCounter
                    amount={row.amount}
                    expectedAmount={row.expectedAmount}
                    unit={'Ks'}
                    isOpen={openList}
                    isComplete={row.amount === row.expectedAmount && !countOverrides}
                    fixedCount={fixedCount}
                    onPlus={() => onPlusClick(row.id)}
                    onMinus={() => onMinusClick(row.id)}
                    onDelete={() => stateMachineEvents.deleteBatch(row.id)}
                    dense
                    useTrash
                  />
                </React.Fragment>
              }
            >
              <ListItemText
                primary={`${row.id} - ${row.batchName}`}
                secondary={
                  <React.Fragment>
                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                      {row.expiration}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>

        {showEANField && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, width: '100%' }}>
            <Autocomplete
              fullWidth
              id="combo-box-demo"
              options={autocompleteOptions}
              onChange={(event, newValue) => {
                setNewValue(newValue?.id ?? ``);
              }}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  sx={{ width: '100%' }}
                  variant="standard"
                  color="primary"
                  helperText="Vložte šarži"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleManuallyAdd();
                    }
                  }}
                  {...params}
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ height: 36, width: 36, minWidth: 36 }}
              onClick={handleManuallyAdd}
            >
              <ArrowForward />
            </Button>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSave} color="primary" fullWidth variant="contained">
          Zpět
        </Button>
      </DialogActions>
      <Typography sx={{ fontSize: '.75rem', m: 0, p: 0, textAlign: 'center' }}>
        {helpText[trackingWarehouseOperation]}
      </Typography>
    </Dialog>
  );
};

export default TransactionBatchesAndExpirationsDialog;
