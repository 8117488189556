import { MailOutline } from '@mui/icons-material';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import { Box, Collapse, Divider, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { includes, isEmpty, isNil } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLogger } from '../../../appLogger';
import { getCustomMsg } from '../../../helpers/transactionItem';
import { useProcessConfig } from '../../../hooks/useCase';
import { useWarehouseState } from '../../../hooks/useWarehouseState';
import { useXState } from '../../../hooks/xState';
import { TransactionItem as TransactionItemModel, TransactionItemAction } from '../../../model';
import { userState$ } from '../../../service/authService';
import { Theme } from '../../../theme';
import { useSettingsState } from '../../store/settings/selectors';
import { hasItemInsufficientAmount } from '../guards';
import ItemCounter from '../itemCounter/ItemCounter';
import ProductDetailTable from '../productDetail/ProductDetailTable';
import { HandlePosition } from '../stateMachine';
import { formatAmount, productHasDetailInfo } from '../transactionHelpers';
import { useStateMachineEvents } from '../useStateMachineEvents';
import TransactionItemAmount from './TransactionItemAmount';

type ThemeProps = {
  backgroundProgressNumber: number | string;
};

const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) => ({
  root: {
    padding: spacing(0.5, 1),
  },
  subtractItem: {
    borderLeft: '3px solid red',
  },
  subtractText: { color: 'red' },
  processed: (props: ThemeProps) => ({
    boxShadow: `inset 0px 2px 0px -1px ${palette.grey.A100}`,
    '&::before': {
      borderTop: '3px solid',
      borderColor: palette.success.main,
      position: 'absolute',
      content: `''`,
      background: `${palette.success.light}4C`,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      transition: 'all 1s',
      '-webkit-transition': 'all 1s',
      width: props.backgroundProgressNumber + '%',
    },
  }),

  done: (props: ThemeProps) => ({
    '&::before': {
      background: `${palette.success.light}4C`,
      borderTop: '3px solid',
      position: 'absolute',
      content: `''`,
      borderColor: palette.success.main,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      transition: 'all 1s',
      '-webkit-transition': 'all 1s',
      width: props.backgroundProgressNumber + '%',
    },
  }),

  secondaryInfo: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: spacing(0.5),
    [breakpoints.down(breakpoints.values.zebra)]: {
      maxWidth: 120,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  quantity: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'end',
    whiteSpace: 'nowrap',
    '& > *': {
      whiteSpace: 'nowrap',
    },
  },
  batchAmount: {
    fontSize: '0.8rem',
    fontColor: palette.text.secondary,
  },
  batchName: {
    fontSize: '0.8rem',
    fontColor: palette.text.secondary,
  },
}));

type TransactionItemProps = StandardProps & {
  item: TransactionItemModel;
  isCurrent: boolean;
  onPlus: (event: any) => void;
  onMinus: (event: any) => void;
  onAmountChange: (event: any) => void;
  onDelete: (code: string) => void;
  onHtmlDialog: (instruction: string, productId?: number) => void;
};

const TransactionItem: React.FC<TransactionItemProps> = ({
  item,
  isCurrent,
  onPlus,
  onMinus,
  onAmountChange,
  onDelete,
  onHtmlDialog,
}) => {
  const appLogger = useLogger();
  const [open, setOpen] = useState(false);
  const { warehouseState, mutate: mutateWarehouseState } = useWarehouseState(item.productId);
  const processConfig = useProcessConfig();
  const { isOpen, isCountOverrides, showSellPrice, handlePosition, tracking } = processConfig;
  const { send, fsm } = useXState();
  const stateMachineEvents = useStateMachineEvents(send);
  const { showProductTotalAmount } = useSettingsState();
  const product = useMemo(() => item.product, [item]);
  const backgroundProgressNumber: number = !isOpen ? (item.amountCompleted / (item.amount || 1)) * 100 : 0;
  const classes = useStyles({ backgroundProgressNumber });
  const backgroundColorType = !isOpen || hasItemInsufficientAmount(item) ? classes.processed : classes.done;
  const showExpandIcon = useMemo(
    () => productHasDetailInfo(product, warehouseState, processConfig),
    [product, warehouseState, processConfig]
  );
  const withoutEan = useMemo(() => !(product?.ean?.length > 1), [product]); // EAN always contains product.CODE
  const hasInsufficientAmount = useMemo(() => hasItemInsufficientAmount(item), [item]);
  const itemToRemove = useMemo(() => item.action === TransactionItemAction.REMOVE, [item.action]);
  const quantity = useMemo(() => {
    const amount = formatAmount(itemToRemove && item.amount ? -item.amount : item.amount);
    const amountCompleted = formatAmount(itemToRemove ? -item?.amountCompleted : item.amountCompleted);

    return !isOpen ? `${amountCompleted} / ${amount}` : amountCompleted;
  }, [item.amountCompleted, item.amount, itemToRemove, isOpen]);

  const alreadyDisplayed = useMemo(
    () => includes(item.id, fsm.context.displayedWarningById),
    [item.id, fsm.context.displayedWarningById]
  );
  const handleWithTouch = withoutEan && hasInsufficientAmount;
  const canSelect = !isCurrent && (isOpen || handleWithTouch || (isCountOverrides && item.amountCompleted > 0));

  const trackBatchesAndExpirations = tracking && (item.product.trackBatches || item.product.trackExpirations);

  const handleClick = useCallback(() => {
    if (canSelect) {
      stateMachineEvents.selectItemAndOpenCounter(item.product.code);
    } else if (isCurrent || showExpandIcon) {
      setOpen(!open);
    }
  }, [canSelect, isCurrent, showExpandIcon, stateMachineEvents, item.product.code, open]);

  const customMsg = getCustomMsg(item);

  useEffect(() => {
    if (isCurrent && !alreadyDisplayed && customMsg) {
      onHtmlDialog(customMsg, item.id);
    }
  }, [isCurrent, customMsg, onHtmlDialog, alreadyDisplayed, item.id]);

  const productSellPrice = useMemo(() => {
    return item.sellPrice || item.product.sellPrice;
  }, [item]);

  const positionString = useMemo(() => {
    if (warehouseState.position && !isEmpty(warehouseState.position)) {
      const countMore = warehouseState.position.length > 1 ? ` + ${warehouseState.position.length - 1} další` : '';
      return warehouseState.position[0] + countMore;
    }
    return null;
  }, [warehouseState.position]);

  useEffect(() => {
    //it happened once, that product was not part of transaction correctly. Product was empty!!
    //probably because the product was not created properly during sync
    if (isNil(product.code) || isNil(product.productId)) {
      appLogger.error('Invalid product in transaction', { product });
    }
  }, [product, appLogger]);

  const [currency, setCurrency] = useState<string>('Kč');
  useEffect(() => {
    const userSubscription = userState$.subscribe((user) => {
      if (!user?.currency) {
        appLogger.warn('TEMP: User currency is not set, Using default Kč');
        return;
      }
      if (user) {
        setCurrency(user.currency);
      }
    });
    return () => {
      userSubscription.unsubscribe();
    };
  }, [appLogger]);

  const [meassureUnit, setMeassureUnit] = useState<string>('Ks');
  useEffect(() => {
    if (!product.measurementUnit) {
      appLogger.warn('TEMP: Product measure unit is not set, Using default Ks');
      return;
    }
    setMeassureUnit(product.measurementUnit);
  }, [appLogger, product]);

  useEffect(() => {
    if (fsm.event.type === 'SAVE_POSITIONS') {
      mutateWarehouseState(500);
    }
  }, [mutateWarehouseState, fsm.event.type]);
  const actionText = useMemo(() => {
    const format = (text: string) => `[${text}]`;
    if (showExpandIcon && !open && !canSelect) return format('Zobrazit podrobnosti');
    if (showExpandIcon && open && !canSelect) return format('Skrýt podrobnosti');
    if (canSelect) return format('Kliknutím vyber');
  }, [showExpandIcon, open, canSelect]);

  const showMiscArea = false;

  return (
    <>
      <ListItem
        data-test="productItem"
        key={item.productId}
        className={classNames(backgroundColorType, classes.root, { [classes.subtractItem]: itemToRemove })}
      >
        <ListItemButton onClick={handleClick} dense disableGutters sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', maxWidth: 'calc(100vw - 16px)' }}>
            <ListItemIcon sx={{ flexDirection: 'column', minWidth: 'auto', marginRight: 1, alignSelf: 'flex-start' }}>
              {withoutEan ? <TouchAppIcon data-test="fingerButton" /> : <ViewWeekIcon />}
              {customMsg && <MailOutline />}
            </ListItemIcon>

            <Box sx={{ flex: '1 1 40%', minWidth: 0, '&>*': { overflowWrap: 'break-word' } }}>
              <Typography variant={'h4'}>{product.name} </Typography>

              {trackBatchesAndExpirations &&
                item.batchesAndExpirations?.map((batch) => (
                  <Typography variant="body1" className={classes.batchName}>
                    {batch.batchName}
                    {batch.batchName && batch.expiration ? ' - ' : ''}
                    {batch.expiration}
                  </Typography>
                ))}

              {itemToRemove && (
                <Typography variant={'body2'} className={classNames({ [classes.subtractText]: itemToRemove })}>
                  Položka k odebrání
                </Typography>
              )}
              <Typography variant={'body1'}>{product.code}</Typography>
              {positionString && <Typography variant={'caption'}>{positionString}</Typography>}
            </Box>

            <Box className={classes.secondaryInfo}>
              <Box className={classes.quantity}>
                {showProductTotalAmount && <TransactionItemAmount amount={warehouseState?.amount} />}
                <Typography
                  variant="body1"
                  id="productsCount"
                  className={classNames({ [classes.subtractText]: itemToRemove })}
                >
                  {quantity} {meassureUnit}
                </Typography>
              </Box>
              {trackBatchesAndExpirations &&
                item.batchesAndExpirations?.map((batch) => (
                  <Typography variant="body1" className={classes.batchName}>
                    {batch.amount} {batch.expectedAmount ? `/ ${batch.expectedAmount}` : ''} {meassureUnit}
                  </Typography>
                ))}
              {showSellPrice && (
                <Typography variant={'body1'}>
                  {productSellPrice} {currency}
                </Typography>
              )}
              {actionText && (
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {actionText}
                </Typography>
              )}
            </Box>
          </Box>
          {showMiscArea && <Box sx={{ width: '100%' }}>{/*ToDo*/}</Box>}
        </ListItemButton>
      </ListItem>

      {isCurrent && (hasItemInsufficientAmount(item) || isOpen || isCountOverrides) && (
        <ListItem key="counter" disableGutters data-test="itemCounter" disablePadding>
          <ItemCounter
            transactionItem={item}
            onPlus={onPlus}
            onMinus={onMinus}
            onAmountChange={onAmountChange}
            onDelete={onDelete}
          />
        </ListItem>
      )}

      <Collapse in={open} timeout="auto" unmountOnExit>
        <ProductDetailTable
          allowHandlePosition={handlePosition !== HandlePosition.NEVER}
          customMsg={customMsg}
          product={product}
          onHtmlDialog={onHtmlDialog}
          warehouseState={warehouseState}
          onEditPositionClick={() => {
            stateMachineEvents.selectItemAndOpenCounter(item.product.code);
            stateMachineEvents.editPositions();
          }}
        />
      </Collapse>
      <Divider />
    </>
  );
};

export default TransactionItem;
