import { isNil } from 'ramda';
import { toast } from 'react-toastify';
import { assign, MachineOptions } from 'xstate';
import { TransactionStatus } from '../../../../model';
import {
  addBatchAndExpirationAmountAction,
  addBatchToItemAction,
  addCurrentProductToTransaction,
  addItemAmount,
  addItemSerialNumber,
  assignErrorMessage,
  discardBatchAction,
  discardItemSerialNumber,
  fixItemAmount,
  getBatchIndex,
  getItemCode,
  getItemIndex,
  notifyError,
  notifyNeedAction,
  notifyOk,
  processItemAddition,
  removeCurrentProduct,
  save,
  updatePositionWithChangeRequest,
} from '../actions';
import {
  EventEnterCode,
  EventEnterEan,
  EventFindProductResult,
  EventHtmlDialog,
  EventInterruption,
  EventTransactionDiscardSerialNumber,
  EventTransactionSaveNote,
  EventTypes,
  ScannerViewEvent,
} from '../events';
import {
  containsItemWithCode,
  containsItemWithEan,
  hasItemExcessiveAmount,
  hasItemInsufficientAmount,
  hasItemRequiredAmount,
  hasSerialNumberGuardDeprecated,
  hasSerialNumberMarkedGuard,
  isBatchAndExpirationActionValid,
  isSerialNumberActionValid,
  isTransactionComplete,
  isTransactionFulfilled,
  isTransactionNew,
  shouldNotifyInput,
  validateNoteRequirements,
  validatePositionRequirements,
} from '../guards';
import { getEANsForAmountInEan } from '../helpers';
import { openTransaction } from '../stateDefinitionOptions';
import { ScannerViewContext } from '../types';

export default {
  guards: {
    isTransactionNew: (context: ScannerViewContext) => isTransactionNew(context.transaction),
    isTransactionComplete: (context: ScannerViewContext) => isTransactionComplete(context.transaction),
    isTransactionFulfilled: (context: ScannerViewContext) => isTransactionFulfilled(context.transaction),
    isTransactionOpen: (context: ScannerViewContext) => openTransaction(context.processConfig),
    isCodeInTransaction: ({ transaction, currentCode }: ScannerViewContext) =>
      containsItemWithCode(transaction, currentCode),
    isEanInTransaction: ({ transaction, currentEan }: ScannerViewContext) =>
      containsItemWithEan(transaction, currentEan),

    hasNotBatch: ({ transaction, currentIndex }: ScannerViewContext, event: EventEnterEan) =>
      !transaction.items[currentIndex]?.batchesAndExpirations?.some((batch) => batch.id === event.code),

    needsMorePieces: ({ transaction, currentIndex }: ScannerViewContext) =>
      hasItemInsufficientAmount(transaction.items[currentIndex]),
    itemIsComplete: ({ transaction, currentIndex }: ScannerViewContext) =>
      hasItemRequiredAmount(transaction.items[currentIndex]),
    itemHasBadAmount: ({ transaction, currentIndex }: ScannerViewContext) =>
      hasItemExcessiveAmount(transaction.items[currentIndex]),
    handleNoteRequirements: (context: ScannerViewContext) => validateNoteRequirements(context),
    shouldHandlePosition: (context: ScannerViewContext) => validatePositionRequirements(context),

    hasSerialNumberActionError: (context: ScannerViewContext, event: EventEnterEan) =>
      !isSerialNumberActionValid(context, event.code),
    hasBatchAndExpirationActionError: (context: ScannerViewContext, event: EventEnterEan) =>
      !isBatchAndExpirationActionValid(context, event.code),
    canMarkSerialNumber: (
      { transaction, currentIndex, currentWarehouseState, ...rest }: ScannerViewContext,
      event: EventEnterEan
    ) => {
      return (
        hasSerialNumberGuardDeprecated(transaction, currentIndex, event.code, currentWarehouseState) &&
        !hasSerialNumberMarkedGuard(transaction, currentIndex, event.code)
      );
    },
  },
  actions: {
    notifyComplete: () => toast.success('Doklad kompletní', { autoClose: false }),
    notifyPurge: () => toast.dismiss(),
    assignLastModifiedDate: assign<ScannerViewContext, ScannerViewEvent>({
      transaction: ({ transaction }, event) => ({
        ...transaction,
        lastModifiedDate: new Date().toISOString(),
      }),
    }),

    assignStateAndUser: assign<ScannerViewContext, ScannerViewEvent>({
      transaction: ({ transaction }) => ({
        ...transaction,
        status: TransactionStatus.IN_PROGRESS,
        // ignored for now TODO - https://gitlab.commity.cz/mobilni-skladnik/mobilni-skladnik-application/-/issues/533
        //assignee: (event as EventStart).user,
      }),
    }),

    assignCurrentEanFromEvent: assign<ScannerViewContext, ScannerViewEvent>({
      currentEan: (_, event) => (event as EventEnterCode).code || '',
      currentCode: '',
    }),

    assignCurrentCodeFromEvent: assign<ScannerViewContext, ScannerViewEvent>({
      currentCode: (_, event) => (event as EventEnterCode).code || '',
      currentEan: '',
    }),

    assignCurrentProductCode: assign<ScannerViewContext>({
      currentCode: ({ transaction, currentEan }) => getItemCode(transaction, currentEan),
    }),
    findInTransactionOrBuildPossibleSearchValues: assign<ScannerViewContext, ScannerViewEvent>((context, event) => {
      const validOptions = getEANsForAmountInEan(context.amountInEanConfig, (event as EventEnterCode).code);
      //get first, ignore rest - possible bug source
      const existingItem = validOptions.find((item) => containsItemWithEan(context.transaction, item.ean));

      return existingItem
        ? {
            amountToAdd: existingItem.amount,
            currentEan: existingItem.ean,
          }
        : {
            validOptions,
            amountToAdd: undefined,
            currentEan: undefined,
          };
    }),

    handleSearchResult: assign<ScannerViewContext, EventFindProductResult>({
      amountToAdd: ({ transaction, ...other }, event) => event.data.amount,
      currentEan: ({ transaction, ...other }, event) => event.data.ean,
    }),
    clearValidEANs: assign<ScannerViewContext>({
      validOptions: undefined,
    }),

    //todo - one day, this should be the main source of error messages and error handling
    //todo - unused now
    assignErrorMessageFromEvent: assign<ScannerViewContext, ScannerViewEvent>({
      errorMessage: (_, event: any) => {
        if (event.data) {
          return `${event.data.name || event.data.code} - není v této objednávce`;
        }
        return '';
      },
    }),

    assignCurrentProductIndex: assign<ScannerViewContext>({
      currentIndex: ({ transaction, currentCode }) => getItemIndex(transaction, currentCode),
    }),

    assignCurrentBatchIndex: assign<ScannerViewContext, ScannerViewEvent>({
      currentBatchIndex: ({ transaction, currentIndex }, event) => {
        //@ts-expect-error - event.code does not exists on some ScannerViewEvent types
        const batchId = event.code ?? event.batchId;
        return getBatchIndex(transaction.items[currentIndex].batchesAndExpirations ?? [], batchId);
      },
    }),

    clearCurrentCode: assign<ScannerViewContext>({
      currentEan: undefined,
      currentCode: undefined,
      currentIndex: -1,
    }),

    processSelection: assign<ScannerViewContext>({
      transaction: ({ transaction, currentIndex, hasCountOverrides, amountToAdd }) =>
        processItemAddition(transaction, currentIndex, hasCountOverrides, amountToAdd),
    }),

    positionHandled: assign<ScannerViewContext>({
      positionContext: ({ positionContext, currentCode }, event) => ({
        ...positionContext,
        handledCodes: [...positionContext.handledCodes, currentCode],
      }),
    }),

    setStatusComplete: assign<ScannerViewContext>({
      transaction: ({ transaction }) => ({
        ...transaction,
        status: TransactionStatus.COMPLETE,
        completed: true,
      }),
    }),

    setNote: assign<ScannerViewContext, ScannerViewEvent>({
      noteContext: ({ noteContext }) => ({ ...noteContext, hasNoteEdited: true }),
      transaction: ({ transaction }, event) => ({
        ...transaction,
        note: (event as EventTransactionSaveNote).note,
      }),
    }),

    setSaveAndCompleteNoteDialogAction: assign<ScannerViewContext, ScannerViewEvent>({
      noteContext: ({ noteContext }) => ({
        ...noteContext,
        noteDialogAction: EventTypes.SAVE_AND_COMPLETE,
      }),
    }),

    setStatusInterrupted: assign<ScannerViewContext, ScannerViewEvent>({
      transaction: ({ transaction }, event) => ({
        ...transaction,
        status: (event as EventInterruption).interruptionType,
        note: (event as EventInterruption).note,
      }),
    }),

    fixItemAmount: assign<ScannerViewContext>({
      transaction: ({ transaction, currentIndex }) => fixItemAmount(transaction, currentIndex),
    }),

    addItemAmount: assign<ScannerViewContext, ScannerViewEvent>({
      transaction: ({ transaction, currentIndex }, event) => addItemAmount(transaction, currentIndex, event),
    }),
    addBatchAndExpirationAmount: assign<ScannerViewContext, ScannerViewEvent>({
      transaction: ({ transaction, currentIndex, currentBatchIndex }, event) => {
        if (isNil(currentBatchIndex) || currentBatchIndex === -1) {
          toast.error('Není vybrána žádná šarže');
          return transaction;
        }

        return addBatchAndExpirationAmountAction(transaction, currentIndex, currentBatchIndex, event);
      },
    }),
    assignErrorMessage: assign<ScannerViewContext, ScannerViewEvent>({
      currentCode: (_, event) => assignErrorMessage(event),
      currentEan: undefined,
    }),
    addCurrentProductToTransaction: assign<ScannerViewContext, EventFindProductResult>({
      transaction: ({ transaction }, event) => addCurrentProductToTransaction(transaction, event.data.product),
    }),
    removeCurrentProduct: assign<ScannerViewContext, ScannerViewEvent>({
      transaction: ({ transaction, currentIndex }) => removeCurrentProduct(transaction, currentIndex),
    }),
    assignCustomMsg: assign<ScannerViewContext, EventHtmlDialog>({
      customMsg: (_, event) => event.instruction,
    }),
    appendDisplayedWarningById: assign<ScannerViewContext, EventHtmlDialog>({
      displayedWarningById: (context, event) => {
        if (event.productId) {
          context.displayedWarningById.push(event.productId);
        }
        return context.displayedWarningById;
      },
    }),

    assignSerialNumberToItem: assign<ScannerViewContext, EventEnterEan>({
      transaction: ({ currentIndex, transaction }, event) => {
        return addItemSerialNumber(
          addItemAmount(transaction, currentIndex, {
            type: EventTypes.CHANGE_AMOUNT,
            amount: (transaction.items[currentIndex]?.serialNumbers ?? []).length + 1,
          }),
          currentIndex,
          event
        );
      },
    }),

    assignBatchToItem: assign<ScannerViewContext, EventEnterEan>({
      transaction: ({ currentIndex, transaction }, event) => {
        return addBatchToItemAction(transaction, currentIndex, event);
      },
    }),

    discardSerialNumberFromItem: assign<ScannerViewContext, EventTransactionDiscardSerialNumber>({
      transaction: ({ currentIndex, transaction }, event) =>
        discardItemSerialNumber(
          addItemAmount(transaction, currentIndex, {
            type: EventTypes.CHANGE_AMOUNT,
            amount: (transaction.items[currentIndex]?.serialNumbers ?? []).length - 1,
          }),
          currentIndex,
          event
        ),
    }),

    discardBatchFromItem: assign<ScannerViewContext, EventTransactionDiscardSerialNumber>({
      transaction: ({ currentIndex, transaction, currentBatchIndex }, event) => {
        return discardBatchAction(
          addItemAmount(transaction, currentIndex, {
            type: EventTypes.MINUS,
            amount: 1,
          }),
          currentIndex,
          currentBatchIndex
        );
      },
    }),

    assignCurrentItemWarehouseState: assign<ScannerViewContext>({
      currentWarehouseState: ({ positionContext }, event: any) => event.data,
    }),

    notifyOk: () => notifyOk(),
    notifyInputIfNotCompleteOrOverAmounted: ({ transaction, currentIndex }: ScannerViewContext) => {
      if (shouldNotifyInput(transaction, currentIndex)) {
        notifyNeedAction();
      }
    },
    notifyError: () => notifyError(),
    save: () => save(),
    updatePositionWithChangeRequest: () => updatePositionWithChangeRequest(),
  },
  services: {
    findProduct: async () => {
      throw new Error('Unknown service find product');
    },
    getWarehouseState: async () => {
      throw new Error('Unknown service has position');
    },
  },
} as Partial<MachineOptions<ScannerViewContext, ScannerViewEvent>>;
