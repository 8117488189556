import { identity, indexBy } from 'ramda';
import { assignCurrentCode } from './assignCurrentCode';
import { codeEntered } from './codeEntered';
import { complete } from './complete';
import { eanEntered } from './eanEntered';
import { editBatchesAndExpirations } from './editBatchesAndExpirations';
import { editPositions } from './editPositions';
import { editSerialNumbers } from './editSerialNumbers';
import { findProduct } from './findProduct';
import { findProductError } from './findProductError';
import { handlePositions } from './handlePositions';
import { htmlDialog } from './htmlDialog';
import { interrupted } from './interrupted';
import { itemBadAmount } from './itemBadAmount';
import { itemComplete } from './itemComplete';
import { itemCounter } from './itemCounter';
import { itemSelected } from './itemSelected';
import { itemToAdd } from './itemToAdd';
import { noteEdit } from './noteEdit';
import { preItemSelected } from './preItemSelected';
import { readOnlyView } from './readOnlyView';
import { readyToScan } from './readyToScan';
import { transactionBeforeComplete } from './transactionBeforeComplete';
import { unknownItem } from './unknownItem';
import { unknownItemInOrder } from './unknownItemInOrder';

export const stateFactories = {
  eanEntered,
  codeEntered,
  complete,
  transactionBeforeComplete,
  findProduct,
  interrupted,
  itemBadAmount,
  itemComplete,
  itemCounter,
  preItemSelected,
  itemSelected,
  itemToAdd,
  readOnlyView,
  readyToScan,
  noteEdit,
  handlePositions,
  editPositions,
  editSerialNumbers,
  editBatchesAndExpirations,
  unknownItem,
  unknownItemInOrder,
  findProductError,
  assignCurrentCode,
  htmlDialog,
  goBack: () => {},
};

export type StateFactories = typeof stateFactories;
export type StateNames = keyof StateFactories;
export const stateNames = indexBy(identity, Object.keys(stateFactories)) as {
  [key in StateNames]: StateNames;
};

export interface ScannerViewStateSchema {
  states: {
    [key in StateNames]: {};
  };
}
