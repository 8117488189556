import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Transaction, TransactionStatus } from '../../../model';

import { Theme } from '../../../theme';
import { setDisabledKeydown } from '../../store/scanner';
import { EventTypes, NoteContext } from '../stateMachine';
import { DialogText, DialogTypes, TransactionLeaveDialogResult } from './TransactionNoteDialog.types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  productInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: spacing(1),
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

type Props = StandardProps & {
  type: DialogTypes;
  originalNote?: Transaction['note'];
  actionType?: NoteContext['noteDialogAction'];
  onClose: () => void;
  onConfirm: (change: TransactionLeaveDialogResult) => void;
  onCancel?: () => void;
};

const TransactionNoteDialog: React.FC<Props> = ({
  type,
  actionType,
  originalNote = '',
  onConfirm,
  onCancel = () => {},
  onClose,
  ...others
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [note, setNote] = useState(originalNote);
  const texts: DialogText = useMemo(() => {
    switch (type) {
      case DialogTypes.EditNote:
        return {
          title: 'Poznámka',
          instruction: 'Zadejte poznámku pro zapsání do skladového systému',
          noteLabel: null,
          actionButton: 'Uložit',
          cancelButton: 'Zrušit',
        };

      case DialogTypes.Interruption:
        return {
          title: 'Přerušit zpracování',
          instruction: 'Přerušení značí, že proces není možné z nějakého důvodu dokončit',
          noteLabel: 'Důvod přerušení',
          actionButton: 'Přerušit',
          cancelButton: 'Zrušit',
        };

      case DialogTypes.Suspension:
      default:
        return {
          title: 'Pozastavit zpracování',
          instruction: 'Pozastavení slouží k dočasnému odložení činnosti na dokladu',
          noteLabel: 'Poznámka',
          actionButton: 'Pozastavit',
          cancelButton: 'Zrušit',
        };
    }
  }, [type]);

  useEffect(() => {
    dispatch(setDisabledKeydown(true));
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(setDisabledKeydown(false));
    onClose();
  }, [dispatch, onClose]);

  const handleCancel = useCallback(() => {
    onCancel();
    handleClose();
  }, [onCancel, handleClose]);

  const handleConfirm = useCallback(() => {
    const statusTypes = {
      [DialogTypes.Interruption]: TransactionStatus.INTERRUPTED as TransactionStatus.INTERRUPTED,
      [DialogTypes.Suspension]: TransactionStatus.SUSPENDED as TransactionStatus.SUSPENDED,
      [DialogTypes.EditNote]: TransactionStatus.SUSPENDED as TransactionStatus.SUSPENDED, //todo - is ignored in editNoteUsecase
    };

    onConfirm({
      status: statusTypes[type],
      saveAndComplete: actionType === EventTypes.SAVE_AND_COMPLETE,
      note,
    });
    handleClose();
  }, [onConfirm, handleClose, type, note, actionType]);

  return (
    <Dialog open fullScreen {...others} className={classNames(classes.root, others.className)} data-test="leaveModal">
      <DialogTitle>{texts.title}</DialogTitle>
      <IconButton onClick={handleCancel} className={classes.closeButton} size="large">
        <Close />
      </IconButton>
      <DialogContent>
        <DialogContentText>{texts.instruction}</DialogContentText>
        <TextField
          variant="standard"
          multiline
          margin="dense"
          id="name"
          label={texts.noteLabel}
          type="note"
          fullWidth
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" fullWidth variant="contained">
          {texts.cancelButton}
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          fullWidth
          variant="contained"
          data-test="leaveModalActionButton"
        >
          {texts.actionButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionNoteDialog;
