import { BatchAndExpiration, Product, TransactionStatus, User } from '../../../model';
import { FindProductResult } from './types';

export enum EventTypes {
  START = 'START',
  FIX_ITEM_AMOUNT = 'FIX_ITEM_AMOUNT',
  CLOSE_ITEM = 'CLOSE_ITEM',
  CLOSE_ERROR = 'CLOSE_ERROR',
  PLUS = 'PLUS',
  MINUS = 'MINUS',
  CHANGE_AMOUNT = 'CHANGE_AMOUNT',
  ENTER_CODE = 'ENTER_CODE',
  ENTER_EAN = 'ENTER_EAN',
  INTERRUPTION = 'INTERRUPTION',
  DELETE_ITEM = 'DELETE_ITEM',
  COMPLETE = 'COMPLETE',
  ASSIGN_CURRENT_CODE = 'ASSIGN_CURRENT_CODE',
  HTML_DIALOG = 'HTML_DIALOG',
  GO_BACK = 'GO_BACK',
  EDIT_NOTE = 'EDIT_NOTE',
  SAVE_NOTE = 'SAVE_NOTE',
  HANDLE_POSITIONS = 'HANDLE_POSITIONS',
  EDIT_POSITIONS = 'EDIT_POSITIONS',
  SAVE_POSITIONS = 'SAVE_POSITIONS',
  SAVE_AND_COMPLETE = 'SAVE_AND_COMPLETE',
  EDIT_SERIAL_NUMBERS = 'EDIT_SERIAL_NUMBERS',
  SAVE_SERIAL_NUMBERS = 'SAVE_SERIAL_NUMBERS',
  DISCARD_SERIAL_NUMBER = 'DISCARD_SERIAL_NUMBER',
  DISCARD_BATCH = 'DISCARD_BATCH',
  SAVE_BATCH = 'SAVE_BATCH',
  ENTER_BATCH_AND_EXPIRATION = 'ENTER_BATCH_AND_EXPIRATION',
  MINUS_BATCH = 'MINUS_BATCH',
  PLUS_BATCH = 'PLUS_BATCH',
}

export type EventStart = { type: EventTypes.START; user: User };
export type EventFixItemAmount = { type: EventTypes.FIX_ITEM_AMOUNT };
export type EventCloseItem = { type: EventTypes.CLOSE_ITEM };
export type EventCloseError = { type: EventTypes.CLOSE_ERROR };
export type EventItemPlus = { type: EventTypes.PLUS; amount: number };
export type EventItemMinus = { type: EventTypes.MINUS; amount: number };

export type EventItemChangeAmount = { type: EventTypes.CHANGE_AMOUNT; amount: number };

export type EventEnterCode = { type: EventTypes.ENTER_CODE; code: string };
export type EventEnterEan = { type: EventTypes.ENTER_EAN; code: string };

export type EventBatchPlus = { type: EventTypes.PLUS_BATCH; batchId: BatchAndExpiration['id']; amount: number };
export type EventBatchMinus = { type: EventTypes.MINUS_BATCH; batchId: BatchAndExpiration['id']; amount: number };

export type EventEnterBatchAndExpiration = {
  type: EventTypes.ENTER_BATCH_AND_EXPIRATION;
  batchId: BatchAndExpiration['id'];
  date?: string;
  amount?: number;
};

export type EventInterruption = {
  type: EventTypes.INTERRUPTION;
  interruptionType: TransactionStatus.INTERRUPTED | TransactionStatus.SUSPENDED;
  note: string;
};
export type EventDeleteItem = { type: EventTypes.DELETE_ITEM };
export type EventComplete = { type: EventTypes.COMPLETE };
export type EventAssignCurrentCode = { type: EventTypes.ASSIGN_CURRENT_CODE; code: string };
export type EventHtmlDialog = { type: EventTypes.HTML_DIALOG; instruction: string; productId?: number };
export type EventGoBack = { type: EventTypes.GO_BACK; target: string };

export type EventTransactionChangeNote = { type: EventTypes.EDIT_NOTE };
export type EventTransactionSaveNote = { type: EventTypes.SAVE_NOTE | EventTypes.SAVE_AND_COMPLETE; note: string };

export type EventTransactionUpdatePositions = { type: EventTypes.HANDLE_POSITIONS; productId?: Product['productId'] };
export type EventTransactionEditPositions = { type: EventTypes.EDIT_POSITIONS; productId?: Product['productId'] };
export type EventTransactionSavePositions = {
  type: EventTypes.SAVE_POSITIONS;
  newPosition: string;
  positionsToDelete: string[];
};

export type EventTransactionEditSerialNumbers = {
  type: EventTypes.EDIT_SERIAL_NUMBERS;
  productId?: Product['productId'];
};
export type EventTransactionSaveSerialNumbers = {
  type: EventTypes.SAVE_SERIAL_NUMBERS;
};
export type EventTransactionDiscardSerialNumber = {
  type: EventTypes.DISCARD_SERIAL_NUMBER;
  value: string;
};

export type EventTransactionSaveBatch = {
  type: EventTypes.SAVE_BATCH;
};
export type EventTransactionDiscardBatch = {
  type: EventTypes.DISCARD_BATCH;
  batchId: BatchAndExpiration['id'];
};

export type EventFindProductResult = {
  type: 'done.invoke.findProduct';
  data: FindProductResult;
};

export type ScannerViewEvent =
  | EventStart
  | EventFixItemAmount
  | EventCloseItem
  | EventCloseError
  | EventItemPlus
  | EventItemMinus
  | EventItemChangeAmount
  | EventTransactionChangeNote
  | EventTransactionSaveNote
  | EventTransactionUpdatePositions
  | EventTransactionEditPositions
  | EventTransactionSavePositions
  | EventEnterCode
  | EventEnterEan
  | EventInterruption
  | EventDeleteItem
  | EventComplete
  | EventAssignCurrentCode
  | EventHtmlDialog
  | EventGoBack
  | EventFindProductResult
  | EventTransactionEditSerialNumbers
  | EventTransactionSaveSerialNumbers
  | EventTransactionDiscardSerialNumber
  | EventTransactionSaveBatch
  | EventTransactionDiscardBatch
  | EventEnterBatchAndExpiration
  | EventBatchPlus
  | EventBatchMinus;
