import { createAction, createReducer, Draft } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { isNil, mergeDeepRight } from 'ramda';
import { ScannerMachineMode, ScannerMachineType } from '../../../receiver/scanner/scannerMachineOptions';

export enum AppSettingKeys {
  showProductTotalAmount = 'showProductTotalAmount',
  scannerMachineMode = 'scannerMachineMode',
  scannerMachineType = 'scannerMachineType',
  showMenuInTransactionDetail = 'showMenuInTransactionDetail',
  showNoteInList = 'showNoteInList',
}

export interface AppSettings {
  [AppSettingKeys.showProductTotalAmount]: boolean;
  [AppSettingKeys.showMenuInTransactionDetail]: boolean;
  [AppSettingKeys.showNoteInList]: boolean;
  [AppSettingKeys.scannerMachineMode]: ScannerMachineMode;
  [AppSettingKeys.scannerMachineType]: ScannerMachineType;
}

type OptionalSettings = {
  [K in keyof AppSettings]?: AppSettings[K];
};

export const APP_CONFIG_KEY = 'app_config';

export const setSettings = createAction<OptionalSettings>(`setSettings`);

const getCurrentConfig = () => {
  const currentConfigString = localStorage.getItem(APP_CONFIG_KEY);
  return isNil(currentConfigString) ? {} : JSON.parse(currentConfigString);
};

const convertLocalStorageKeys = () => {
  // TODO - NOTE - one time action to convert old keys to new keys. Should be deleted after few days
  [
    ['show-product-total-amount', AppSettingKeys.showProductTotalAmount],
    ['show-document-menu', AppSettingKeys.showMenuInTransactionDetail],
    ['scanner-machine-type', AppSettingKeys.scannerMachineMode],
  ].forEach(([oldKey, newKey]) => {
    const currentValue = localStorage.getItem(oldKey);
    if (!isNil(currentValue)) {
      const currentConfig = getCurrentConfig();
      if (newKey !== AppSettingKeys.scannerMachineMode) {
        // boolean
        currentConfig[newKey] = Boolean(currentValue);
      } else {
        currentConfig[newKey] = currentValue;
      }
      localStorage.setItem(APP_CONFIG_KEY, JSON.stringify(currentConfig));
      localStorage.removeItem(oldKey);
    }
  });
};
convertLocalStorageKeys();

const initialState: AppSettings = {
  showProductTotalAmount: false,
  showMenuInTransactionDetail: true,
  showNoteInList: false,
  scannerMachineMode: ScannerMachineMode.DEFAULT,
  scannerMachineType: ScannerMachineType.DEFAULT,
  ...getCurrentConfig(),
};

export type SettingsState = Draft<typeof initialState>;

export default createReducer(initialState, {
  [setSettings.type]: (state: SettingsState, action: ReturnType<typeof setSettings>) => {
    const newState = mergeDeepRight(state, action.payload);
    try {
      localStorage.setItem(APP_CONFIG_KEY, JSON.stringify(newState));
    } catch (e) {
      Sentry.captureMessage('Save app config failed!', { level: 'warning' });
    }
    return newState;
  },
});
