import { StateNode, ProcessConfig } from '../types';

export function readOnlyView(options: ProcessConfig): StateNode {
  return {
    always: [{ cond: 'isTransactionComplete', target: 'complete' }],
    on: {
      START: { target: 'readyToScan', actions: ['assignStateAndUser', 'save'] },
      ENTER_EAN: { target: 'eanEntered', actions: ['assignStateAndUser'] },
      ENTER_CODE: { target: 'codeEntered', actions: ['assignStateAndUser'] },
    },
  };
}
