import { isNil } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useDB } from 'react-pouchdb';
import { useLogger } from '../appLogger';
import { WarehouseState } from '../model';

export const useWarehouseState = (productId: WarehouseState['productId']) => {
  const db = useDB();
  const appLogger = useLogger();
  const [warehouseState, setWarehouseState] = useState<WarehouseState>({ productId, position: [] });

  const getWarehouseState = useCallback(
    (timeout = 0) => {
      const doTheAsyncThing = async () => {
        try {
          const result = await db.get(`warehouseState-${productId}`);
          if (isNil(result)) {
          } else {
            setWarehouseState(result);
          }
        } catch (err: any) {
          appLogger.warn(err, `No warehouseState for ProductId`, { productId });
        }
      };
      if (timeout > 0) {
        setTimeout(doTheAsyncThing, timeout);
        return;
      }
      doTheAsyncThing();
    },
    [appLogger, setWarehouseState, productId, db]
  );

  useEffect(() => {
    getWarehouseState();
  }, [getWarehouseState]);

  return { warehouseState, mutate: getWarehouseState };
};
