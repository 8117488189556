import { AlertColor } from '@mui/material/Alert/Alert';
import { ProcessConfig } from './app/scannerView/stateMachine';

export enum ObjectType {
  ADDRESS_BOOK = 'addressBook',
  PRODUCT = 'product',
  TRANSACTION = 'transaction',
  WAREHOUSE_STATE = 'warehouseState',
  CHANGE_REQUEST = 'changeRequest',
}

export type Product = {
  amount?: number;
  buyPrice?: string;
  category?: string;
  code: string;
  commonPrice?: string;
  customMsg?: string;
  depth?: string;
  description: string;
  ean: string[];
  height?: string;
  imageSource?: string;
  longDescription?: string;
  measurementUnit?: string;
  name: string;
  nameNormalized: string;
  productId: string;
  sellPrice?: string;
  trackSerialNumbers: boolean;
  trackBatches: boolean;
  trackExpirations: boolean;
  weight?: string;
  width?: string;
};

export interface WarehouseState {
  amount?: number;
  position: string[];
  productId: string;
  reservedAmount?: number;
  serialNumbers?: string[];
  batchesAndExpirations?: BatchAndExpiration[];
}

interface PouchEntity {
  _id: string;
  _rev: string;
}

export type ProductPouchEntity = Product & PouchEntity;

export type WarehouseStatePouchEntity = WarehouseState & PouchEntity;

export enum TransactionOrigin {
  APP = 'APP',
  EXTERNAL = 'EXTERNAL',
}

export enum TransactionType {
  RECEIPT = 'RECEIPT',
  ISSUE = 'ISSUE',
  TRANSFER = 'TRANSFER',
  DISPATCH = 'DISPATCH',
  UNKNOWN = 'UNKNOWN',
}

export enum TransactionStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  SUSPENDED = 'SUSPENDED',
  INTERRUPTED = 'INTERRUPTED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

export type DateString = string;

// todo - syncnout s BE - ideálně generovat
export type Transaction = {
  assignee?: User;
  canEdit?: boolean;
  clientInfo?: string;
  completed?: boolean;
  customMsg?: string;
  dateCreated?: DateString;
  dateUpdated?: DateString;
  deliveryService?: string;
  id: string; // client id
  items: TransactionItem[];
  lastModifiedDate: DateString;
  logs?: Log[];
  note?: string;
  origin: TransactionOrigin;
  payment?: string;
  priority?: number;
  processId: UseCaseDefinition['processId'];
  responseError?: string;
  status: TransactionStatus;
  transNumber: string;
  displayName: string;
  transactionType?: TransactionType;
};

export type TransactionPouchEntity = Transaction & {
  _id: string; // pouchdb id
  _rev: string;
};

export type Log = {
  type: string;
  timestamp: string;
};

export type User = {
  company: string;
  username: string;
  tenantId: string;
  currency?: string;
  changeRequestUrl?: string;
};

export enum TransactionItemAction {
  ADD = 'Add',
  REMOVE = 'Remove',
}

export type TransactionItem = {
  amount?: number;
  amountCompleted: number;
  serialNumbers?: string[]; // todo - fix types and stuff
  expectedSerialNumbers?: string[]; // todo - fix types and stuff
  batchesAndExpirations?: BatchAndExpiration[]; // todo - fix types and stuff
  expectedBatchesAndExpirations?: BatchAndExpiration[]; // todo - fix types and stuff
  customMsg?: string;
  id: number;
  order: number;
  product: Product;
  productId: Product['productId'];
  sellPrice?: number;
  action?: TransactionItemAction;
};

export type ErrorMessages = { [key in ErrorType]: string };
export type ErrorSeverity = { [key in ErrorType]: AlertColor };

export enum ErrorType {
  'COUNT_ERROR' = 'COUNT_ERROR',
  'PRODUCT_CODE_ERROR' = 'PRODUCT_CODE_ERROR',
  'PRODUCT_DOES_NOT_EXIST' = 'PRODUCT_DOES_NOT_EXIST',
}

type TransactionStateTexts = { [key in TransactionStatus]: string };
export type TransactionTypeTexts = { [key in TransactionType]: string };

export const transactionStateTexts: TransactionStateTexts = {
  [TransactionStatus.NEW]: 'Nová',
  [TransactionStatus.IN_PROGRESS]: 'Rozpracovaná',
  [TransactionStatus.COMPLETE]: 'Kompletní',
  [TransactionStatus.SUSPENDED]: 'Odložená',
  [TransactionStatus.INTERRUPTED]: 'Přerušená',
  [TransactionStatus.CANCELLED]: 'Zrušená',
  [TransactionStatus.ERROR]: 'Chyba',
};

export const transactionTypeTexts: TransactionTypeTexts = {
  [TransactionType.RECEIPT]: 'Příjemka',
  [TransactionType.ISSUE]: 'Výdejka',
  [TransactionType.TRANSFER]: 'Převodka',
  [TransactionType.DISPATCH]: 'Expedice',
  [TransactionType.UNKNOWN]: 'Neznámý',
};

export type Tile = {
  label: string;
  icon: string;
  color: string;
  fullWidth: boolean;
};

export enum TransactionTypeColors {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
  GREY = 'GREY',
  RED = 'RED',
  BROWN = 'BROWN',
  PURPLE = 'PURPLE',
}

export enum TransactionTypeIcons {
  RECEIPT = 'RECEIPT',
  ISSUE = 'ISSUE',
  TRANSFER = 'TRANSFER',
  DISPATCH = 'DISPATCH',
  INVENTORY = 'INVENTORY',
  SUBSTITUTION = 'SUBSTITUTION',
}

export type UseCaseDefinition = {
  processId: string;
  name: string;
  webhook: string;
  uiConfig: {
    icon: string;
    color: string;
  };
  processConfig: ProcessConfig;
  visible?: boolean;
  order?: number;
  requiredNote?: boolean;
};

export type UseCaseDefinitions = {
  [key: string]: UseCaseDefinition;
};

export interface BatchAndExpiration {
  id: string;
  batchName: string;
  expiration?: string;
  amount: number;
  expectedAmount?: number;
}
