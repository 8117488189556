import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isEmpty, without } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { useWarehouseState } from '../../../../hooks/useWarehouseState';
import { TransactionItem as TransactionItemType } from '../../../../model';
import { Theme } from '../../../../theme';
import { setDisabledKeydown } from '../../../store/scanner';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  formField: {
    margin: spacing(4, 0),
  },
  toBeDeleted: {
    color: palette.error.main,
  },
}));

type Props = StandardProps & {
  onClose?: () => void;
  onConfirm: (newPosition: string, positionsToDelete: string[]) => void;
  onCancel?: () => void;
  product: TransactionItemType;
};

const TransactionPositionDialog: React.FC<Props> = ({
  onConfirm,
  onCancel = () => {},
  onClose,
  product,
  ...others
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { warehouseState } = useWarehouseState(product.productId);

  const [newPositionValue, setNewPositionValue] = useState('');
  const [positionsToDelete, setPositionsToDelete] = useState<string[]>([]);

  const positionString = useMemo(() => {
    if (warehouseState.position && !isEmpty(warehouseState.position)) {
      const countMore = warehouseState.position.length > 1 ? ` + ${warehouseState.position.length - 1} další` : '';
      return warehouseState.position[0] + countMore;
    }
    return null;
  }, [warehouseState.position]);

  const markForDelete = useCallback(
    (positionName) => {
      if (positionsToDelete.includes(positionName)) {
        setPositionsToDelete(without(positionName, positionsToDelete));
      } else {
        setPositionsToDelete([...positionsToDelete, positionName]);
      }
    },
    [positionsToDelete]
  );

  const handleCancel = useCallback(() => {
    dispatch(setDisabledKeydown(false));
    onCancel();
  }, [onCancel, dispatch]);

  const handleSave = useCallback(() => {
    dispatch(setDisabledKeydown(false));
    onConfirm(newPositionValue, positionsToDelete);
  }, [dispatch, onConfirm, newPositionValue, positionsToDelete]);

  useEffect(() => {
    dispatch(setDisabledKeydown(true));
    return () => {
      dispatch(setDisabledKeydown(false));
    };
  }, [dispatch]);

  return (
    <Dialog open fullScreen {...others} className={classNames(classes.root)} data-test="positionModal">
      <DialogTitle>{product.product.name} - Upravit pozici</DialogTitle>
      <IconButton onClick={handleCancel} className={classes.closeButton} size="large">
        <Close />
      </IconButton>
      <DialogContent>
        <Box sx={{ flex: '1 1 40%', minWidth: 0, '&>*': { overflowWrap: 'break-word' } }} color={'text.primary'}>
          <Typography variant={'h4'}>{product.product.name} </Typography>
          <Typography variant={'body1'}>{product.product.code}</Typography>
          <Typography variant={'body1'}>{product.product.ean.join(', ')}</Typography>
          {positionString && <Typography variant={'caption'}>{positionString}</Typography>}
        </Box>
        <Divider />
        <TextField
          autoFocus
          variant="standard"
          multiline
          margin="dense"
          id="name"
          label={'Zadejte novou pozici'}
          type="note"
          fullWidth
          value={newPositionValue}
          onChange={(e) => setNewPositionValue(e.target.value)}
          className={classes.formField}
        />

        <DialogContentText color={'text.primary'} component={'div'}>
          <Typography variant={'h4'}>Existující pozice</Typography>
          <List>
            {warehouseState.position.map((item) => (
              <ListItem
                key={item}
                className={classNames({ [classes.toBeDeleted]: positionsToDelete.includes(item) })}
                secondaryAction={
                  <IconButton edge="end" aria-label="edit" onClick={() => markForDelete(item)}>
                    <DeleteIcon color={positionsToDelete.includes(item) ? 'error' : 'inherit'} />
                  </IconButton>
                }
              >
                {item}
              </ListItem>
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" fullWidth variant="contained">
          Přeskočit
        </Button>
        <Button onClick={handleSave} color="primary" fullWidth variant="contained">
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionPositionDialog;
