import { StateNode, ProcessConfig } from '../types';

export function codeEntered(options: ProcessConfig): StateNode {
  return {
    entry: 'assignCurrentCodeFromEvent',
    always: [
      {
        target: 'preItemSelected',
        cond: 'isCodeInTransaction',
        actions: 'assignCurrentProductIndex',
      },
      { target: 'findProduct' },
    ],
  };
}
