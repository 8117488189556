import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.min.css';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { FallbackRender } from '@sentry/react';
import classNames from 'classnames';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router5';
import { ToastContainer } from 'react-toastify';
import { AppConfig, AppConfigContext } from '../config';

import { matomoInstance } from '../matomo';
import theme from '../theme';
import LoadingSpinner from './common/LoadingSpinner';
import ErrorFallback from './errors/ErrorFallback';
import router from './router';
import { RouterComponent } from './router/RouterComponent';
import { store } from './store';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100vh',
    overflow: 'hidden',
  },

  app: {
    height: '100%',
  },
}));

interface AppProps extends StandardProps {
  config: AppConfig;
}

const myFallback: FallbackRender = (errorInfo) => <ErrorFallback {...errorInfo} />;

const App: React.FC<AppProps> = ({ config, ...others }) => {
  const classes = useStyles();

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Provider store={store}>
        <AppConfigContext.Provider value={config}>
          <ToastContainer autoClose={2500} />
          <RouterProvider router={router}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <div className={classes.app}>
                  <Suspense fallback={<LoadingSpinner title={'Načítání ...'} />}>
                    <Sentry.ErrorBoundary fallback={myFallback}>
                      <MatomoProvider value={matomoInstance(config)}>
                        <RouterComponent />
                      </MatomoProvider>
                    </Sentry.ErrorBoundary>
                  </Suspense>
                </div>
              </ThemeProvider>
            </StyledEngineProvider>
          </RouterProvider>
        </AppConfigContext.Provider>
      </Provider>
    </div>
  );
};

export default App;
