import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { User as SentryUser } from '@sentry/types/';
import { AppConfig } from './config';
import { getToken, getTokenPayload } from './service/authService';

interface SentryScopeUser extends SentryUser {
  company: string;
  username: string;
  tenantId: string;
}

const anonymousUser = {
  tenantId: 'anonymous',
  username: 'anonymous',
  company: 'anonymous',
};

export const isSentryEnabled = process.env.NODE_ENV !== 'development';

export const getUserForSentry = () => {
  const tokenPayload = getTokenPayload(getToken());
  const { company, tenantId }: SentryScopeUser = tokenPayload || anonymousUser;
  return { username: company, id: tenantId, tenantId };
};

export const init = (config: AppConfig) => {
  if (isSentryEnabled) {
    Sentry.init({
      dsn: config.REACT_APP_SENTRY_URL,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      release: process.env.REACT_APP_BUILD_VERSION,
      environment: process.env.REACT_APP_ENVIRONMENT,
      initialScope: {
        user: getUserForSentry(),
      },
    });
  }

  return config;
};

export const changeSentryUser = (user: SentryScopeUser = anonymousUser) => {
  Sentry.configureScope((scope) => scope.setUser(getUserForSentry()));
};
