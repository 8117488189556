import { equals, mergeAll } from 'ramda';
import { StateFactories, stateFactories, StateNames } from './state';
import { alwaysTrue, cannotOverrideCount, closeTransaction, openTransaction } from './stateDefinitionOptions';
import { ProcessConfig } from './types';

/**
 * this level determines which states will be available in state machine
 * State properties (events,...) are defined conditionally in state file
 */
const statesConditions = [
  {
    condition: closeTransaction,
    factory: stateFactories.readOnlyView,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.readyToScan,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.eanEntered,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.codeEntered,
  },
  {
    condition: openTransaction,
    factory: stateFactories.itemToAdd,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.findProduct,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.unknownItem,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.unknownItemInOrder,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.findProductError,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.itemSelected,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.preItemSelected,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.itemCounter,
  },
  {
    condition: cannotOverrideCount,
    factory: stateFactories.itemComplete,
  },
  {
    condition: cannotOverrideCount,
    factory: stateFactories.itemBadAmount,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.interrupted,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.complete,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.transactionBeforeComplete,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.assignCurrentCode,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.htmlDialog,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.noteEdit,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.handlePositions,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.editPositions,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.editSerialNumbers,
  },
  {
    condition: alwaysTrue,
    factory: stateFactories.editBatchesAndExpirations,
  },
];

export function getStatesByOptions(options: ProcessConfig): Partial<StateFactories> {
  const validStateFactoryObjects = statesConditions
    .filter(({ condition }) => condition(options))
    .map(({ factory }) => ({
      [factory.name]: factory,
    }));

  return mergeAll(validStateFactoryObjects);
}

export const stateReadOnlyView = equals<StateNames>('readOnlyView');
export const stateReadyToScan = equals<StateNames>('readyToScan');
export const stateUnknownItemInOrder = equals<StateNames>('unknownItemInOrder');
export const stateUnknownItem = equals<StateNames>('unknownItem');
export const stateFindProductError = equals<StateNames>('findProductError');
export const stateItemSelected = equals<StateNames>('itemSelected');
export const stateItemCounter = equals<StateNames>('itemCounter');
export const stateItemComplete = equals<StateNames>('itemComplete');
export const stateItemBadAmount = equals<StateNames>('itemBadAmount');
export const stateComplete = equals<StateNames>('complete');
export const stateHtmlDialog = equals<StateNames>('htmlDialog');
export const stateEditNote = equals<StateNames>('noteEdit');
export const stateHandlePositions = (stateName: StateNames) =>
  equals<StateNames>('handlePositions', stateName) || equals<StateNames>('editPositions', stateName);
export const stateHandleSerialNumbers = equals<StateNames>('editSerialNumbers');
export const stateHandleBatchesAndExpirations = equals<StateNames>('editBatchesAndExpirations');
