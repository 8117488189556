import { applySpec } from 'ramda';
import { Machine, StateNodeConfig } from 'xstate';
import { TransactionOrigin, TransactionStatus } from '../../../model';
import { EventGoBack, ScannerViewEvent } from './events';
import optionsData from './options';
import { ScannerViewStateSchema, stateNames } from './state';
import { getStatesByOptions } from './stateDefinition';
import { canOverrideCount, hasFixedCount, openTransaction, requireNote } from './stateDefinitionOptions';
import { ProcessConfig, ScannerViewContext } from './types';

function getStates(
  options: ProcessConfig
): StateNodeConfig<ScannerViewContext, ScannerViewStateSchema, ScannerViewEvent>['states'] {
  const states = getStatesByOptions(options);
  return applySpec(states)(options) as any;
}

function getGlobalEvents(options: ProcessConfig) {
  return {
    INTERRUPTION: {
      target: ['interrupted'],
    },
    ASSIGN_CURRENT_CODE: 'assignCurrentCode',
    HTML_DIALOG: 'htmlDialog',
    EDIT_NOTE: 'noteEdit',
    EDIT_POSITIONS: 'editPositions',
  };
}

export function getInitialState(stateDefinitionOptions: ProcessConfig) {
  return openTransaction(stateDefinitionOptions) ? stateNames.readyToScan : stateNames.readOnlyView;
}

export function createDefinitionForUseCase(useCaseOptions: ProcessConfig) {
  const states = getStates(useCaseOptions)!;
  states.goBack = {
    always: [
      ...Object.keys(states || {}).map((stateName) => {
        return {
          target: stateName,
          cond: (context: ScannerViewContext, event: EventGoBack, { state }: any) => {
            return state?.event?.target === stateName;
          },
        };
      }),
      { target: getInitialState(useCaseOptions) },
    ],
  } as any;
  return {
    id: 'scannerViewMachine',
    context: {
      transaction: {
        id: '',
        processId: '',
        items: [],
        status: TransactionStatus.NEW,
        lastModifiedDate: '',
        transNumber: '',
        displayName: '',
        origin: TransactionOrigin.APP,
      },
      errorMessage: '',
      currentCode: '',
      currentEan: '',
      currentIndex: -1,
      currentBatchIndex: -1,
      hasFixedCount: hasFixedCount(useCaseOptions),
      hasCountOverrides: canOverrideCount(useCaseOptions),
      displayedWarningById: [],
      noteContext: {
        hasNoteRequired: requireNote(useCaseOptions),
      },
      processConfig: useCaseOptions,
      positionContext: { options: {}, handledCodes: [] },
      amountInEanConfig: [],
      validOptions: [],
    },
    initial: getInitialState(useCaseOptions),
    states,
    on: getGlobalEvents(useCaseOptions),
  };
}

export function createMachineForUseCase(useCaseOptions: ProcessConfig) {
  return Machine<ScannerViewContext, ScannerViewStateSchema, ScannerViewEvent>(
    createDefinitionForUseCase(useCaseOptions),
    optionsData
  );
}
