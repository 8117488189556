import { TrackEventParams, TrackPageViewParams } from '@jonkoops/matomo-tracker-react/lib/types';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { CustomDimension } from '@jonkoops/matomo-tracker/lib/types';
import { useEffect, useState } from 'react';
import { getDeviceId, getTenantInfo, userState$ } from '../service/authService';

export enum ECustomDimension {
  TENANT_ID = 1,
}
const getMatomoUserId = () => {
  const tenantInfo = getTenantInfo();
  return tenantInfo ? `${tenantInfo.tenantId} - ${tenantInfo.company}` : 'ANONYMOUS';
};

const getDimensionTenantInfo = () => {
  const tenantInfo = getTenantInfo();
  return tenantInfo ? `${tenantInfo.tenantId} - ${tenantInfo.company} - ${getDeviceId()}` : 'UNPAIRED';
};

export const defaultCustomDimensions: CustomDimension[] = [
  { id: ECustomDimension.TENANT_ID, value: getDimensionTenantInfo() },
];

export const useAnalytics = () => {
  const { trackPageView, trackEvent, pushInstruction } = useMatomo();
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    const userSubscription = userState$.subscribe((user) => {
      if (user) {
        if (user.tenantId.startsWith('TestUser_')) {
          setEnabled(false);
        } else {
          pushInstruction('setUserId', getMatomoUserId());
          defaultCustomDimensions.length = 0;
          defaultCustomDimensions.push({ id: ECustomDimension.TENANT_ID, value: getDimensionTenantInfo() });
        }
      }
    });

    return () => {
      userSubscription.unsubscribe();
    };
  }, [pushInstruction]);

  const handleTrackEvent = (config: TrackEventParams) => {
    if (enabled) {
      trackEvent({
        customDimensions: defaultCustomDimensions,
        ...config,
      });
    }
  };

  const handleTrackPageView = (config: TrackPageViewParams = {}) => {
    if (enabled) {
      trackPageView({
        customDimensions: defaultCustomDimensions,
        ...config,
      });
    }
  };

  return {
    trackPageView: handleTrackPageView,
    trackEvent: handleTrackEvent,
  };
};
