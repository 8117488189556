import { ProcessConfig, StateNode } from '../types';

export function preItemSelected(options: ProcessConfig): StateNode {
  const always: any[] = [];

  // TODO - Extra todo notification
  // todo - all actions should perform. Right now, only first one will be performed
  // positions

  // if (options.handlePosition !== HandlePosition.NEVER) {
  //   always.push({
  //     target: 'handlePositions',
  //     cond: 'shouldHandlePosition',
  //   });
  // }

  // Serial numbers
  if (options.tracking) {
    always.push(
      {
        target: 'editSerialNumbers',
        cond: (context) => context.transaction.items[context.currentIndex].product.trackSerialNumbers,
      },
      {
        target: 'editBatchesAndExpirations',
        cond: (context) => context.transaction.items[context.currentIndex].product.trackBatches,
      },
      {
        target: 'editBatchesAndExpirations',
        cond: (context) => context.transaction.items[context.currentIndex].product.trackExpirations,
      }
    );
  }

  always.push({ target: 'itemSelected' });

  return {
    always: always,
  };
}
