import Delete from '@mui/icons-material/Delete';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { lt, lte } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import React from 'react';
import { Theme } from '../../theme';
import { formatAmount } from '../scannerView/transactionHelpers';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: spacing(0.5),
    gap: spacing(1),
  },
  countButton: {
    fontSize: '1.6rem',
    margin: spacing(0.5, 0),
    padding: spacing(0),
  },
  deleteButton: {
    fontSize: '1.6rem',
    margin: spacing(0.5, 0),
    padding: spacing(0),
  },
  dense: {
    fontSize: '1.2rem',
    padding: 0,
    margin: 0,
    minWidth: spacing(4),
    width: spacing(4),
    height: spacing(4),
  },
}));

type Props = StandardProps & {
  amount: number;
  expectedAmount?: number;
  unit?: string;
  onPlus: () => void;
  onMinus: () => void;
  onDelete?: () => void;
  isOpen?: boolean;
  isComplete?: boolean;
  fixedCount?: boolean;
  dense?: boolean;
  useTrash?: boolean;
};

const PlusMinusCounter: React.FC<Props> = ({
  amount,
  expectedAmount,
  unit,
  onPlus,
  onMinus,
  onDelete,
  fixedCount,
  isOpen,
  isComplete,
  dense,
  useTrash,
}) => {
  const classes = useStyles();

  const isMinusDisabled = isOpen ? lte : lt;

  const showMinusButton = !useTrash || (useTrash && !isMinusDisabled(amount, 1));
  const showDeleteButton = isOpen && useTrash && isMinusDisabled(amount, 1);
  const showPlusButton = !isComplete;

  return (
    <div className={classNames(classes.root)}>
      {showMinusButton && (
        <Button
          data-test={'substract-one-button'}
          disabled={isMinusDisabled(amount, 1)}
          variant="contained"
          className={classNames(classes.countButton, { [classes.dense]: dense })}
          color="primary"
          onClick={onMinus}
        >
          -
        </Button>
      )}
      {showDeleteButton && (
        <Button
          variant="contained"
          className={classNames(classes.deleteButton, { [classes.dense]: dense })}
          color="error"
          onClick={onDelete}
        >
          <Delete fontSize="medium" />
        </Button>
      )}

      <Typography variant={dense ? 'h6' : 'h2'} component={'p'}>
        {formatAmount(amount)} {fixedCount && isNotNil(expectedAmount) ? `/ ${expectedAmount}` : ''} {unit ?? 'ks'}
      </Typography>
      {showPlusButton && (
        <Button
          data-test="Pricteni"
          variant="contained"
          size="small"
          className={classNames(classes.countButton, { [classes.dense]: dense })}
          color="primary"
          onClick={onPlus}
        >
          +
        </Button>
      )}
    </div>
  );
};

export default PlusMinusCounter;
