import { IconButton, Snackbar, SnackbarContent, Typography } from '@mui/material';
import { CheckCircleOutline, Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

import { Theme } from '../../theme';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {},
  snackBar: { bottom: spacing(6) },

  closeButton: {
    padding: spacing(0.5),
  },

  snackbarContent: {
    backgroundColor: palette.success.light,
    color: palette.success.contrastText,
    '& > *': { width: '100%' },
  },

  icon: {
    margin: spacing(0.5),
  },

  snackbarContentBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type Props = StandardProps & {
  open?: boolean;
  handleClose?: (event: any) => void;
  message: string;
};

const SuccessMessage: React.FC<Props> = ({ open = true, message, handleClose, ...others }) => {
  const classes = useStyles({});

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Snackbar open={open} className={classes.snackBar}>
        <SnackbarContent
          className={classes.snackbarContent}
          message={
            <div className={classes.snackbarContentBlock}>
              <CheckCircleOutline className={classes.icon} />
              <Typography variant="h6">{message}</Typography>
              {handleClose ? (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.closeButton}
                  onClick={handleClose}
                  size="large"
                >
                  <Close />
                </IconButton>
              ) : (
                /* Empty tag to center message  */
                <div></div>
              )}
            </div>
          }
        />
      </Snackbar>
    </div>
  );
};

export default SuccessMessage;
