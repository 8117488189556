import { StateNode, ProcessConfig } from '../types';

// todo - rename to: transactionComplete
export function complete(options: ProcessConfig): StateNode {
  return {
    entry: [
      'setStatusComplete',
      'assignLastModifiedDate',
      `notifyComplete`,
      'save',
      ...(options.tracking ? ['optimisticallyUpdateSerialNumbers'] : []),
    ],
    type: 'final' as const,
  };
}
