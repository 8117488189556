import { propEq, T } from 'ramda';

export const alwaysTrue = T;
export const canOverrideCount = propEq('countOverrides', true);
export const cannotOverrideCount = propEq('countOverrides', false);
export const closeTransaction = propEq('openList', false);
export const hasFixedCount = propEq('fixedCount', true);
export const openTransaction = propEq('openList', true);
export const requireNote = propEq('requireNote', true);
export const showSellPrice = propEq('showSellPrice', true);
