import { StateNode } from '../types';

export function noteEdit(): StateNode {
  return {
    on: {
      SAVE_NOTE: {
        actions: ['clearCurrentCode', 'setNote', 'assignLastModifiedDate', 'save'],
        target: 'readyToScan', //todo - this could lead to skipping the transition between readOnlyView -> readyToScan
      },
      SAVE_AND_COMPLETE: {
        actions: ['clearCurrentCode', 'setNote', 'assignLastModifiedDate', 'save'],
        target: 'transactionBeforeComplete',
      },
      GO_BACK: 'goBack',
    },
  };
}
