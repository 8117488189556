import { createAction, createReducer } from '@reduxjs/toolkit';

const name = 'scanner';

function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

export const setDisabledKeydown = createAction(`${name}/setDisabledKeydown`, withPayloadType<boolean>());

const initialState = {
  disabledKeydown: false,
};

export default createReducer(initialState, {
  [setDisabledKeydown.type]: (state, action) => {
    return {
      ...state,
      disabledKeydown: action.payload,
    };
  },
});
