import React, { useContext, useMemo } from 'react';
import {
  ExtendedAndModifiedStateDefinitionOptionsToBeRefactoredSomeDayLater,
  HandlePosition,
  ProcessConfig,
} from '../app/scannerView/stateMachine';

export const UseCaseContext = React.createContext<ExtendedAndModifiedStateDefinitionOptionsToBeRefactoredSomeDayLater>(
  {} as unknown as ExtendedAndModifiedStateDefinitionOptionsToBeRefactoredSomeDayLater
);

export const UseCaseProvider = ({
  useCaseDefinition,
  children,
}: {
  useCaseDefinition: ProcessConfig;
  children: any;
}) => {
  if (!useCaseDefinition) {
    throw new Error('No property: useCaseDefinition');
  }
  const value: ExtendedAndModifiedStateDefinitionOptionsToBeRefactoredSomeDayLater = useMemo(
    () => ({
      numericCodesOnly: true,
      // @ts-expect-error - DefaultValue in case it is not provided yet by the backend
      handlePosition: HandlePosition.NEVER, //todo get from Dativery
      // @ts-expect-error - DefaultValue in case it is not provided yet by the backend
      handleAmountInEan: false,
      ...useCaseDefinition,
      isOpen: useCaseDefinition.openList,
      isFixedCount: useCaseDefinition.fixedCount,
      isCountOverrides: useCaseDefinition.countOverrides,
      canDelete: true, // TODO - is this really needed?
    }),
    [useCaseDefinition]
  );
  return <UseCaseContext.Provider value={value}>{children}</UseCaseContext.Provider>;
};

export const UseCaseConsumer = UseCaseContext.Consumer;

export const useProcessConfig = () => useContext(UseCaseContext);
