import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Theme } from '../../theme';
import { setDisabledKeydown } from '../store/scanner';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  productInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: spacing(1),
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

type Props = StandardProps & {
  title: string;
  instruction?: string;
  actionButton: string;
  cancelButton?: string;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmDialog: React.FC<Props> = ({
  title,
  instruction,
  actionButton,
  cancelButton = 'Zrušit',
  onConfirm,
  onClose,
  children,
  ...others
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  useEffect(() => {
    dispatch(setDisabledKeydown(true));
    return () => {
      dispatch(setDisabledKeydown(false));
    };
  }, [dispatch]);

  return (
    <Dialog open fullScreen {...others} className={classNames(classes.root, others.className)}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton onClick={onClose} className={classes.closeButton} size="large">
        <Close />
      </IconButton>
      <DialogContent>
        {instruction && <DialogContentText color="textPrimary">{instruction}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" fullWidth variant="contained">
          {cancelButton}
        </Button>
        <Button onClick={handleConfirm} color="primary" fullWidth variant="contained">
          {actionButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
