import { AppBar, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import logo from '../../../images/logo.svg';
import { User } from '../../../model';
import { Theme } from '../../../theme';
import { HEADER_HEIGHT } from '../../common/AppHeader';
import { SortDefinition, SortType } from '../sorting';
import { getEnvironmentType } from '../../common/helpers';
import SortingMenu from './SortMenu';
import UserMenu from './UserMenu';

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: HEADER_HEIGHT,
    padding: spacing(1),
  },
  logo: {
    maxHeight: '65%',
    padding: spacing(1),
  },
  filler: {
    flexGrow: 1,
  },
  search: {
    width: 200,
    '& .MuiInputBase-root': {
      color: palette.secondary.contrastText,
      paddingLeft: spacing(),
    },
    '& .MuiInput-underline:before': {
      borderColor: palette.secondary.contrastText,
    },
  },
  sortingMenu: {
    margin: spacing(-1.5),
  },
  devMark: {
    color: palette.error.main,
    paddingTop: '4px',
    position: 'absolute',
    fontSize: '0.8rem',
    top: 6,
    left: 54,
  },
}));

type Props = StandardProps & {
  user: User;
  title?: string;
  sort: SortType;
  sortDefinitions: SortDefinition[];
  searchText: string;
  onSortChange: (sortType: SortType) => void;
  onLogout: () => void;
  setSearchText: (value: string) => void;
  handleFilter: (processId: string[]) => void;
};

const ListHeader: React.FC<Props> = ({
  user,
  title = 'Mobilní skladník',
  onLogout,
  sort,
  sortDefinitions,
  onSortChange,
  searchText,
  setSearchText,
  handleFilter,
  ...others
}) => {
  const classes = useStyles({});
  const [isSearch, setSearch] = useState<boolean>(false);

  const handleSearch = () => {
    setSearch((prev) => !prev);
  };

  const handleClearSearch = () => {
    setSearch((prev) => !prev);
    setSearchText('');
  };

  return (
    <AppBar position="absolute" color="secondary" classes={{ root: classNames(classes.root, others.className) }}>
      {!isSearch && (
        <>
          <img src={logo} alt={'Mobilní skladník'} className={classes.logo} />

          <span className={classes.devMark}>{getEnvironmentType}</span>
        </>
      )}
      {isSearch ? (
        <IconButton color="inherit" onClick={handleClearSearch} size="large">
          <ClearIcon />
        </IconButton>
      ) : (
        <IconButton data-test="searchIcon" color="inherit" onClick={handleSearch} size="large">
          <SearchIcon />
        </IconButton>
      )}
      {isSearch && (
        <TextField
          variant="standard"
          data-test={'searchTextField'}
          autoFocus
          className={classes.search}
          id="standard-basic"
          placeholder="Hledání"
          value={searchText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.currentTarget.value)}
        />
      )}
      <div className={classes.filler} />
      <SortingMenu
        data-test={'UserMenu'}
        handleFilter={handleFilter}
        sort={sort}
        sortDefinitions={sortDefinitions}
        onSortChange={onSortChange}
        className={classes.sortingMenu}
      />
      <UserMenu data-test={'userMenu'} user={user} onLogout={onLogout} />
    </AppBar>
  );
};

export default ListHeader;
