import { Button, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isEmpty } from 'ramda';
import React, { useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Product, WarehouseState } from '../../../model';
import { Theme } from '../../../theme';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {},
  productImage: {
    maxWidth: '80%',
    margin: `${spacing(1)} auto`,
    display: 'flex',
  },

  caption: {
    fontSize: '0.625rem',
  },
}));

type Props = StandardProps & {
  allowHandlePosition: boolean;
  product: Product;
  customMsg: string;
  onHtmlDialog: (instruction: string) => void;
  warehouseState: WarehouseState;
  onEditPositionClick?: (code: Product['productId']) => void;
};

const ProductDetailTable: React.FC<Props> = ({
  product,
  customMsg,
  onHtmlDialog,
  warehouseState,
  onEditPositionClick,
  allowHandlePosition = false,
  ...others
}) => {
  const classes = useStyles();
  const showImage = product.imageSource && product.imageSource.length > 1;
  const showDescription = product?.description.length > 1;

  const handleHtmlDialog = useCallback(() => {
    onHtmlDialog(customMsg!);
  }, [onHtmlDialog, customMsg]);

  return (
    <List {...others} className={classNames(classes.root, others.className)} dense disablePadding>
      {(allowHandlePosition || (warehouseState.position && !isEmpty(warehouseState.position))) && (
        <ListItem
          dense
          key="location"
          secondaryAction={
            <>
              {onEditPositionClick && allowHandlePosition && (
                <IconButton edge="end" aria-label="edit" onClick={() => onEditPositionClick(product.productId)}>
                  <EditIcon />
                </IconButton>
              )}
            </>
          }
        >
          <ListItemText
            primary="POZICE VE SKLADU"
            secondary={warehouseState.position.map((position, index) => (
              <span key={position}>
                {index !== 0 && <br />}
                {position}
              </span>
            ))}
          />
        </ListItem>
      )}
      {product.category && (
        <ListItem dense key="category">
          <ListItemText>
            <Typography className={classes.caption}>SKUPINA</Typography>
          </ListItemText>
          <ListItemText>
            <Typography variant="subtitle2">{product.category}</Typography>
          </ListItemText>
        </ListItem>
      )}

      {showImage && <img src={product.imageSource} alt={product.name} className={classes.productImage} />}

      {showDescription && (
        <ListItem dense key="description">
          <ListItemText primary="POPIS" secondary={product.description} />
        </ListItem>
      )}
      {customMsg && (
        <ListItem dense key="show-note">
          <ListItemText>
            <Button variant="text" onClick={handleHtmlDialog}>
              Zobrazit poznámku
            </Button>
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
};

export default ProductDetailTable;
