import { TransactionStatus } from '../../../model';
import { NoteContext } from '../stateMachine';

export type StateMachineEventTBD = any; // todo - move somewhere else

export enum DialogTypes {
  Interruption = 'interruption',
  Suspension = 'suspension',
  EditNote = 'editNote',
}

export interface DialogDTO {
  type: DialogTypes;
  closeAction: () => any;
  confirmEvent: StateMachineEventTBD;
  cancelAction?: () => StateMachineEventTBD;
  stateMachineSaveEvent?: NoteContext['noteDialogAction'];
}

export interface DialogText {
  title: string;
  instruction: string;
  noteLabel: string | null;
  actionButton: string;
  cancelButton: string;
}

export type TransactionLeaveDialogResult = {
  status: TransactionStatus.INTERRUPTED | TransactionStatus.SUSPENDED;
  note: string;
  saveAndComplete?: boolean;
};
