import { EMPTY_ACTION } from '../actions';
import { ProcessConfig, StateNode, TrackingWarehouseOperation } from '../types';

export function editSerialNumbers(options: ProcessConfig): StateNode {
  const handleScanAction = {
    [TrackingWarehouseOperation.ADD]: [
      {
        cond: 'hasSerialNumberActionError',
        target: 'editSerialNumbers',
        actions: ['notifyError'],
      },
      options.openList
        ? EMPTY_ACTION
        : {
            cond: 'itemIsComplete',
            target: 'editSerialNumbers',
            actions: ['notifyError'],
          },
      {
        target: 'editSerialNumbers',
        actions: ['assignSerialNumberToItem', 'notifyNeedAction'],
      },
    ],

    [TrackingWarehouseOperation.REMOVE]: [
      {
        cond: 'hasSerialNumberActionError',
        target: 'editSerialNumbers',
        actions: ['notifyError'],
      },
      options.openList
        ? EMPTY_ACTION
        : {
            cond: 'itemIsComplete',
            target: 'editSerialNumbers',
            actions: ['notifyError'],
          },
      {
        target: 'editSerialNumbers',
        actions: ['assignSerialNumberToItem', 'notifyNeedAction'],
      },
    ],

    [TrackingWarehouseOperation.NONE]: [
      {
        cond: 'canMarkSerialNumber',
        target: 'editSerialNumbers',
        actions: ['assignSerialNumberToItem', 'notifyNeedAction'],
      },
      options.openList
        ? EMPTY_ACTION
        : {
            cond: 'itemIsComplete',
            target: 'editSerialNumbers',
            actions: ['notifyError'],
          },
      {
        target: 'editSerialNumbers',
        actions: ['notifyError'],
      },
    ],
  };

  return {
    invoke: {
      src: 'getWarehouseState',
      onDone: { actions: ['assignCurrentItemWarehouseState'] },
      onError: { target: 'preItemSelected' },
    },
    on: {
      ENTER_EAN: handleScanAction[options.trackingWarehouseOperation],
      DISCARD_SERIAL_NUMBER: {
        actions: ['discardSerialNumberFromItem', 'notifyNeedAction'],
      },
      DELETE_ITEM: [
        {
          target: 'itemCounter',
          actions: ['removeCurrentProduct', 'save'],
        },
      ],
      SAVE_SERIAL_NUMBERS: {
        target: 'itemCounter',
        actions: ['notifyOk', 'assignLastModifiedDate', 'save'],
      },
    },
  };
}
