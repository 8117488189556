import { v4 as uuidv4 } from 'uuid';
import { ObjectType, UseCaseDefinition } from '../model';
import { getDeviceId, getTenantInfo } from './authService';

export enum ObjectTypeAction {
  ADD = 'add',
  REMOVE = 'remove',
  REPLACE = 'replace',
}

export type ChangeRequestField = {
  type: ObjectType;
  itemId?: string;
  fields?: { name: string; action: ObjectTypeAction; value: any; currentValue?: any }[];
};
//todo - this one need more abstraction to work with any Object type
export async function createNewChangeRequest(
  db: PouchDB.Database,
  data: ChangeRequestField,
  useCaseWebhook: UseCaseDefinition['webhook']
) {
  const changeId = uuidv4();
  const tenant = getTenantInfo();

  return await db.put({
    _id: `${ObjectType.CHANGE_REQUEST}-${changeId}`,
    timestamp: new Date(),
    opId: changeId,
    itemId: data.itemId,
    changeObjectType: data.type,
    objectType: ObjectType.CHANGE_REQUEST,
    finished: false,
    //todo - I need better way to get notifyUrl. This is little bit hacky, but it works!
    notifyUrl: tenant.changeRequestUrl ?? getNotifyUrlWithoutParams(useCaseWebhook),
    meta: {
      warehouseman: getTenantInfo().username,
      deviceId: getDeviceId(),
      deviceName: 'Unknown',
    },
    fields: data.fields,
  });
}

export const getNotifyUrlWithoutParams = (useCaseWebhook: UseCaseDefinition['webhook']) => {
  const lastSlashIndex = useCaseWebhook.lastIndexOf('/');
  const newUrl = useCaseWebhook.substring(0, lastSlashIndex + 1);

  return newUrl + 'changeRequest';
};
