import { AppBar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import logo from '../../images/logo.svg';
import { Theme } from '../../theme';

export const HEADER_HEIGHT = 56;

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    height: HEADER_HEIGHT,
  },
  logo: {
    maxHeight: '65%',
    padding: spacing(1),
  },
}));

type Props = StandardProps & {};

const AppHeader: React.FC<Props> = ({ ...others }) => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="secondary" classes={{ root: classNames(classes.root, others.className) }}>
      <img src={logo} alt={'Mobilní skladník'} className={classes.logo} />
    </AppBar>
  );
};

export default AppHeader;
