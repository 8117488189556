import { ErrorOutline } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { getCustomMsg } from '../../helpers/transactionItem';
import { useWarehouseState } from '../../hooks/useWarehouseState';
import { ErrorType, TransactionItem } from '../../model';

import { Theme } from '../../theme';
import { errorInstructions, errorMessages } from '../common/helpers';
import ProductDetailTable from '../scannerView/productDetail/ProductDetailTable';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    border: '8px solid',
    borderColor: palette.error.light,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${palette.error.light}0F`,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: spacing(1),
    color: palette.error.main,
    fontWeight: 'bold',
  },

  errorIcon: {
    marginLeft: spacing(0.5),
  },

  productName: {
    padding: spacing(1.5, 0),
  },
  productDetails: {
    marginTop: spacing(1.5),
  },
}));

type Props = StandardProps & {
  onFixAmountClick: (event: any) => void;
  item: TransactionItem;
  errorType: ErrorType;
  onHtmlDialog: (instruction: string) => void;
};

const ProductErrorBig: React.FC<Props> = ({ item, errorType, onFixAmountClick, onHtmlDialog, ...others }) => {
  const classes = useStyles();
  const product = item.product;
  const { warehouseState } = useWarehouseState(product.productId);

  const isCountError = useMemo(() => errorType === ErrorType.COUNT_ERROR, [errorType]);
  return (
    <Dialog open fullScreen {...others} className={classNames(classes.root, others.className)}>
      <DialogContent>
        <div className={classes.header}>
          <ErrorOutline color="error" className={classes.errorIcon} />
          <Typography variant="h4">{errorMessages[errorType]}</Typography>
        </div>
        {product?.name && (
          <Typography className={classes.productName} variant="h4" align="center">
            {product.name}
          </Typography>
        )}
        {isCountError && item && (
          <Typography variant="h2" align="center" gutterBottom>
            {item.amountCompleted} / {item.amount} ks
          </Typography>
        )}
        <Typography variant="h4">{errorInstructions[errorType]}</Typography>

        <DialogActions>
          <Button fullWidth color="primary" variant="contained" onClick={onFixAmountClick}>
            Opraveno
          </Button>
        </DialogActions>
        {item && product && (
          <ProductDetailTable
            allowHandlePosition={false}
            customMsg={getCustomMsg(item)}
            product={product}
            className={classes.productDetails}
            onHtmlDialog={onHtmlDialog}
            warehouseState={warehouseState}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProductErrorBig;
