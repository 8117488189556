import AccountCircle from '@mui/icons-material/AccountCircle';
import { Badge, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { User } from '../../../model';
import { requireLogin } from '../../../service/authService';
import { Theme } from '../../../theme';
import router from '../../router';
import { RootState } from '../../store';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    display: 'flex',
  },
  username: {
    color: palette.primary.dark,
    padding: spacing(0.5, 2),
  },
}));

type Props = StandardProps & {
  user: User;
  onLogout: () => void;
};

const UserMenu: React.FC<Props> = ({ user, onLogout, ...others }) => {
  const classes = useStyles({});

  const onSettingsClick = useCallback(() => {
    router.navigate('app.settings');
  }, []);

  const onReloadClick = useCallback(() => {
    //force app reload
    window.location.reload();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleUserClick = useCallback((e) => setAnchorEl(e.currentTarget), [setAnchorEl]);

  const handleLogoutClick = useCallback(() => {
    setAnchorEl(null);
    onLogout();
  }, [onLogout]);

  const serviceWorkerUpdated = useSelector((store: RootState) => store.serviceWorker.serviceWorkerUpdated);

  return (
    <div className={classes.root} data-test="userMenu">
      <IconButton aria-controls="fade-menu" aria-haspopup="true" onClick={handleUserClick} color="inherit" size="large">
        <Badge overlap="rectangular" invisible={!serviceWorkerUpdated} color="primary" variant="dot">
          <AccountCircle />
        </Badge>
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <Typography variant="subtitle1" className={classes.username} component="li">
          {user.username && `${user.username} / `}
          {user.company}
        </Typography>
        <MenuItem disabled>Version: {process.env.REACT_APP_BUILD_VERSION}</MenuItem>
        {serviceWorkerUpdated && (
          <MenuItem onClick={onReloadClick} className={classes.username}>
            Stáhnout novou verzi
          </MenuItem>
        )}
        <MenuItem onClick={onReloadClick}>Restartovat aplikaci</MenuItem>
        <MenuItem onClick={onSettingsClick} data-test="settings">
          Nastavení
        </MenuItem>

        {requireLogin() && (
          <>
            <Divider />
            <MenuItem onClick={handleLogoutClick} data-test="logout">
              Odhlásit
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default UserMenu;
