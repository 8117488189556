import { createTheme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    zebra: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      light: '#53bad8',
      main: '#29A9CF',
      dark: '#1e94b4',
      contrastText: '#fff',
    },
    secondary: {
      light: '#484c4d',
      main: '#1b2021',
      dark: '#121617',
      contrastText: '#fff',
    },
    text: {
      primary: '#333333',
      secondary: '#A2A2A2',
    },
    background: {
      default: '#eff1f5',
    },
    success: {
      light: '#7eba48',
      main: '#5EA91B',
      dark: '#417612',
    },
    error: {
      light: '#db5b57',
      main: '#D2322D',
      dark: '#93231f',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      zebra: 321,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  typography: {
    h1: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.4rem',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
      lineHeight: 1,
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '1rem',
    },

    subtitle2: {
      fontSize: '0.95rem',
      lineHeight: 1,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          borderWidth: 2,
          textTransform: 'none',
          fontWeight: 600,
          '&:hover': {
            borderWidth: 2,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // hide browser default increase / decrease buttons for number inputs
        root: {
          '& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '& input[type=number]': {
            '-moz-appearance': 'textfield',
          },
        },
      },
    },
  },
});
export default theme;

export type Theme = typeof theme;

export const greenTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      light: '#7eba48',
      main: '#5EA91B',
      dark: '#417612',
    },
  },
});

export const yellowTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      light: '#f2b63d',
      main: '#EFA40D',
      dark: '#a77209',
    },
  },
});

export const blueTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      light: '#33bbbc',
      main: '#00ABAC',
      dark: '#007778',
    },
  },
});

export const orangeTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      light: '#fc8735',
      main: '#FC6903',
      dark: '#b04902',
    },
  },
});

export const redTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      light: '#db5b57',
      main: '#D2322D',
      dark: '#93231f',
    },
  },
});

export const greyTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      light: '#484c4d',
      main: '#1b2021',
      dark: '#121617',
    },
  },
});

export const brownTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      light: '#b8a07e',
      main: '#A68C5A',
      dark: '#7c5f3a',
    },
  },
});

export const purpleTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      light: '#a17bb9',
      main: '#8A63A7',
      dark: '#5f3f6e',
    },
  },
});
