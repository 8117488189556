import { CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

import { Theme } from '../../theme';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dense: { height: 'auto' },
  title: {
    marginTop: spacing(2),
  },
}));

type Props = StandardProps & {
  title: string;
  dense?: boolean;
};

const LoadingSpinner: React.FC<Props> = ({ title, dense, ...others }) => {
  const classes = useStyles();
  return (
    <div {...others} className={classNames(classes.root, others.className, { [classes.dense]: dense })}>
      <CircularProgress />
      <Typography className={classes.title} variant="h1">
        {title}
      </Typography>
    </div>
  );
};

export default LoadingSpinner;
