import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import router from './app/router';
import { store } from './app/store';
import { setAppUpdated } from './app/store/serviceWorker';
import { AppConfig, loadAppConfig } from './config';
import './index.css';
import { init as sentryInit } from './sentry';
import { extendToken, isPairedAndAuthenticated } from './service/authService';
import * as serviceWorker from './serviceWorker';

async function bootstrapApp(config: AppConfig) {
  if (isPairedAndAuthenticated()) {
    await extendToken(config.REACT_APP_API_URL);
    router.start(window.location.pathname || '/transaction');
  } else {
    router.start('/login');
  }

  const root = createRoot(document.getElementById('root')!);
  root.render(<App config={config} />);

  serviceWorker.checkVersion(config);
  setInterval(() => {
    serviceWorker.checkVersion(config);
  }, 600000);
}

loadAppConfig().then(sentryInit).then(bootstrapApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    const registrationWaiting = registration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e) => {
        store.dispatch(setAppUpdated());
      });
    }
  },
});
