import { AlertColor } from '@mui/material/Alert/Alert';
import React from 'react';
import { ErrorType } from '../../model';
import { errorMessages, severityLevel } from '../common/helpers';
import ErrorMessage, { ErrorMessageProps } from './ErrorMessage';

type Props = Omit<ErrorMessageProps, 'message'> & {
  code?: string;
  ean?: string;
  errorType: ErrorType;
  severity?: AlertColor;
};

const ErrorMessageByType: React.FC<Props> = ({ code, ean, errorType, severity, ...others }) => {
  let message: string = `${errorMessages[errorType]}\n`;

  if (code) {
    message += `Code: ${code}\n`;
  }
  if (ean) {
    message += `EAN: ${ean}\n`;
  }

  return <ErrorMessage {...others} message={message} severity={severity || severityLevel[errorType]} />;
};

export default ErrorMessageByType;
