import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { UseCaseDefinition } from '../../model';
import { getDocumentTheme } from './helpers';

type Props = StandardProps & {
  useCaseDefinition: UseCaseDefinition;
  children?: React.ReactNode;
};

const TransactionThemeProvider: React.FC<Props> = ({ useCaseDefinition, ...others }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={getDocumentTheme(useCaseDefinition)}>{others.children}</ThemeProvider>
  </StyledEngineProvider>
);

export default TransactionThemeProvider;
