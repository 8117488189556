/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { createLogger, ERROR, INFO, TRACE, WARN, DEBUG } from 'browser-bunyan';
import { isNil } from 'ramda';
import { isEmptyArray } from 'ramda-adjunct';
import { useMemo } from 'react';
import { isSentryEnabled } from './sentry';

export const extractExtra = (msg) => {
  const extraString = msg.match(/\{.*\}$/);
  if (!isNil(extraString) && !isEmptyArray(extraString)) {
    return JSON.parse(extraString[0]);
  }
  return {};
};

export const formatMessage = (msg) => {
  const extra = extractExtra(msg);
  const formattedMessage = msg.replace(/\{.*\}$/, '').trimEnd();
  return { extra, message: formattedMessage };
};

export const useLogger = () => {
  const appLogger = useMemo(() => {
    if (isSentryEnabled) {
      return createLogger({
        name: 'ms-fe',
        streams: [
          {
            level: DEBUG,
            stream: {
              write(data: any) {
                if (data.level === DEBUG) {
                  console.log('DEBUG:', data.msg);
                }
              },
            },
          },
          {
            level: INFO,
            stream: {
              write(data: any) {
                if (data.level <= WARN) {
                  const { message, extra } = formatMessage(data.msg);
                  Sentry.captureMessage(message, {
                    extra: {
                      ...extra,
                      ...data,
                    },
                    level: data.level === WARN ? 'warning' : data.levelName,
                  });
                }
              },
            },
          },
          {
            level: ERROR,
            stream: {
              write(data: any) {
                if (data.level >= ERROR) {
                  const { message, extra } = formatMessage(data.msg);
                  if (data.err instanceof Error) {
                    Sentry.captureException(
                      { ...data.err, message: message },
                      {
                        extra: {
                          ...extra,
                          ...data,
                        },
                        level: data.levelName,
                      }
                    );
                  } else {
                    Sentry.captureMessage(message, {
                      ...data,
                      extra: {
                        ...extra,
                        ...data,
                      },
                      level: data.levelName,
                    });
                  }
                }
              },
            },
          },
        ],
      });
    } else {
      return createLogger({
        name: 'ms-fe',
        streams: [
          {
            level: TRACE,
            stream: {
              write(data: any) {
                if (data.level <= INFO) {
                  console.log('DEVEL:', data.msg);
                } else if (data.level === WARN) {
                  console.warn('DEVEL:', data.msg);
                } else {
                  console.error('DEVEL:', data.msg, data);
                }
              },
            },
          },
        ],
      });
    }
  }, []);

  return appLogger;
};
