import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Theme } from '../../theme';
import AppHeader from '../common/AppHeader';
import { getEnvironmentType } from '../common/helpers';
import { isPairedDevice, getTenantId } from '../../service/authService';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    boxShadow: 'none',
  },
  shape: {
    flex: '0 1 25%',
    background: palette.secondary.main,
    clipPath: 'polygon(0 0, 100% 0%, 100% 30%, 0% 100%)',
    marginBottom: spacing(1),
    color: palette.text.secondary,
    textAlign: 'center',
  },
  environment: {
    display: 'flex',
    justifyContent: 'center',
    color: palette.text.secondary,
    paddingBottom: spacing(0.5),
    opacity: 0.5,
    fontSize: 12,
  },
  code: {
    color: palette.error.main,
  },
  content: {
    flex: 1,
    margin: spacing(0, 5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  helperText: {
    textAlign: 'center',
  },
  textInfo: {
    padding: spacing(2),
    textAlign: 'center',
    color: palette.text.secondary,
  },
  infoAlert: {
    justifyContent: 'center',
    textAlign: 'center',
    color: palette.text.secondary,
    padding: spacing(2),
  },
  link: {
    color: palette.primary.main,
  },
}));

type Props = StandardProps & {
  errorMessage?: string;
  onSubmit: (value: string) => void;
};

const LoginPage: React.FC<Props> = ({ errorMessage, onSubmit, ...others }) => {
  const showInfoAlert = Boolean(errorMessage) && !isPairedDevice();
  const classes = useStyles();
  const [value, setValue] = useState<string>('');
  const handleChange = useCallback(({ target }) => setValue(target.value), [setValue]);
  const handleSubmit = useCallback(
    ({ key }) => {
      if (!!value && key === 'Enter') {
        onSubmit(value);
      }
    },
    [onSubmit, value]
  );

  const tenantId = getTenantId();

  const textFieldOptions = isPairedDevice()
    ? {
        label: 'Přihlašovací kód',
        placeholder: 'Zadejte přihlašovací kód',
      }
    : {
        label: 'Párovací kód',
        placeholder: 'Zadejte párovací kód',
      };

  return (
    <div className={classNames(classes.root, others.className)} data-test="loginPage">
      <AppHeader className={classes.header} />
      <div className={classes.shape}>{tenantId}</div>
      {!isPairedDevice() && (
        <div className={classes.textInfo}>Pro spárování zařízení naskenujte, nebo opište párovací kód z Dativery</div>
      )}
      <div className={classes.content}>
        <TextField
          {...textFieldOptions}
          autoFocus
          id="loginInput"
          fullWidth
          variant="outlined"
          helperText={errorMessage}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          error={Boolean(errorMessage)}
          value={value}
          onChange={handleChange}
          onKeyPress={handleSubmit}
        />
        {showInfoAlert && (
          <div className={classes.infoAlert}>
            Párování zařízení je časově omezené. Pokud chyba přetrvá, prosím vytvořte nový požadavek pro párování.
          </div>
        )}
      </div>

      {!isPairedDevice() && (
        <div className={classes.textInfo}>
          Nemáte párovací kód nebo chcete aplikaci vyzkoušet? Navštivte&nbsp;
          <a href="https://mobilni-skladnik.cz" className={classes.link}>
            Web mobilního skladníka
          </a>
          &nbsp;pro více informací
        </div>
      )}

      <div className={classes.environment}>
        {getEnvironmentType && <span className={classes.code}>{getEnvironmentType}:&nbsp;</span>}
        {process.env.REACT_APP_BUILD_VERSION}
      </div>
    </div>
  );
};

export default LoginPage;
