//todo - move somewhere else
import { isEmpty } from 'ramda';
import { AmountInEanConfig } from '../../../hooks/useCaseDefinition';
import { EanSearchValue } from './types';

export const getEANsForAmountInEan = (useAmountInEan: AmountInEanConfig[], searchValue: string): EanSearchValue[] => {
  if (
    !isEmpty(useAmountInEan) &&
    useAmountInEan.some(
      (config) =>
        searchValue.startsWith(config.prefix) &&
        searchValue.length >= config.length + config.ignoredDigits + config.decimal + config.integer
    )
  ) {
    const validOptions: AmountInEanConfig[] = useAmountInEan.filter(
      (config) =>
        searchValue.startsWith(config.prefix) &&
        searchValue.length >= config.length + config.ignoredDigits + config.decimal + config.integer
    );
    const keys: EanSearchValue[] = validOptions.map((config) => ({
      ean: searchValue.substring(0, config.length),
      amount:
        parseInt(
          searchValue.substring(
            config.length + config.ignoredDigits,
            config.length + config.ignoredDigits + config.integer + config.decimal
          )
        ) / Math.pow(10, config.decimal),
    }));
    keys.push({ ean: searchValue }); // add original value as fallback
    return keys;
  }

  return [{ ean: searchValue }];
};
