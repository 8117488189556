import makeStyles from '@mui/styles/makeStyles';
import { useMachine } from '@xstate/react';
import { isEmpty, map, mergeAll, pathOr, pipe, prop, without } from 'ramda';
import { isNilOrEmpty, isNotEmpty, isNotNil } from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDB } from 'react-pouchdb';
import { useRouter } from 'react-router5';
import { useLogger } from '../../appLogger';
import normalizeText from '../../helpers/normalizeText';
import { useProcessConfig } from '../../hooks/useCase';
import { useAmountInEanConfig, useUseCaseDefinition } from '../../hooks/useCaseDefinition';
import { XStateProvider } from '../../hooks/xState';
import {
  ErrorType,
  ObjectType,
  ProductPouchEntity,
  Transaction,
  TransactionOrigin,
  TransactionPouchEntity,
  TransactionStatus,
  User,
  WarehouseState,
  WarehouseStatePouchEntity,
} from '../../model';
import Scanner from '../../receiver/scanner/Scanner';
import { ScannerMachineType } from '../../receiver/scanner/scannerMachineOptions';
import { ChangeRequestField, createNewChangeRequest, ObjectTypeAction } from '../../service/changerequestService';
import { useDbFind } from '../../service/databaseService';
import ErrorMessage from '../alerts/ErrorMessage';
import ErrorMessageByType from '../alerts/ErrorMessageByType';
import SuccessMessage from '../alerts/SuccessMessage';
import HtmlDialog from '../dialogs/HtmlDialog';
import ProductErrorBig from '../errors/ProductErrorBig';
import { useSettingsState } from '../store/settings/selectors';
import TransactionBatchesAndExpirationsDialog from './dialogs/transactionBatchesAndExpirations/TransactionBatchesAndExpirations';
import TransactionPositionDialog from './dialogs/transactionPositionDialog/TransactionPositionDialog';
import TransactionSerialNumbersDialog from './dialogs/transactionSerialNumbersDialog/TransactionSerialNumbersDialog';
import { shouldNotifyInput } from './guards';
import {
  createMachineForUseCase,
  EanSearchValue,
  EventTransactionSavePositions,
  FindProductResult,
  getInitialState,
  ScannerViewContext,
  State,
  stateComplete,
  stateEditNote,
  stateFindProductError,
  stateHandleBatchesAndExpirations,
  stateHandlePositions,
  stateHandleSerialNumbers,
  stateHtmlDialog,
  stateItemBadAmount,
  stateItemComplete,
  stateReadOnlyView,
  stateUnknownItem,
  stateUnknownItemInOrder,
  TrackingWarehouseOperation,
} from './stateMachine';
import TransactionActionContainer from './transactionActionContainer/TransactionActionContainer';
import TransactionDetail from './transactionDetail/TransactionDetail';
import TransactionHeader from './transactionHeader/TransactionHeader';
import TransactionNoteDialog from './transactionLeaveDialog/TransactionNoteDialog';
import { DialogDTO, DialogTypes } from './transactionLeaveDialog/TransactionNoteDialog.types';
import { useStateMachineEvents } from './useStateMachineEvents';

const useStyles = makeStyles(() => ({
  middleContainer: {
    position: 'relative',
    height: 'calc(100% - 42px)',
  },
  scannerInput: {
    //Invisible input field
    position: 'absolute',
  },
}));

type Props = StandardProps & {
  transaction: TransactionPouchEntity;
  user: User;
  saveTransaction: (transaction: Transaction) => Promise<any>;
  navigateToList: () => void;
};

const getFirstDocumentOrFalse = pathOr<any>(false, ['docs', 0]);

const ScannerView: React.FC<Props> = ({ transaction, user, navigateToList, saveTransaction }) => {
  const audioOK = useRef<HTMLAudioElement>(null);
  const audioError = useRef<HTMLAudioElement>(null);
  const audioNeedAction = useRef<HTMLAudioElement>(null);

  const [dialog, setDialog] = useState<DialogDTO | null>(null);
  const [currentItemIdCached, setCurrentItemIdCached] = useState<number | undefined>(undefined);
  const [allowMessage, setAllowMessage] = useState<boolean>(true); //allow error or confirm messages to be displayed

  const router = useRouter();
  const appLogger = useLogger();
  const db: PouchDB.Database = useDB();
  const dbFind = useDbFind();
  const { scannerMachineType } = useSettingsState();

  const useCaseDefinition = useUseCaseDefinition(transaction.processId);
  const useCaseOptions = useProcessConfig();

  const amountInEanConfig = useAmountInEanConfig();

  // todo - machine is generated on every re-render. Main reason is `transaction` maybe there is faster way how to handle it
  // https://gitlab.commity.cz/mobilni-skladnik/mobilni-skladnik-application/-/issues/430

  const stateMachineForUseCase = useMemo(() => createMachineForUseCase(useCaseOptions), [useCaseOptions]);

  const [fsm, send, service] = useMachine(stateMachineForUseCase, {
    context: {
      transaction: transaction,
      errorMessage: '',
      currentCode: '',
      currentEan: '',
      currentIndex: -1,
      currentBatchIndex: -1,
      customMsg: '',
      displayedWarningById: [],
      amountInEanConfig,
    },
    actions: {
      // we need use html element in page, because create new Audio element is slow.
      notifyOk: () => {
        if (audioOK.current) {
          audioOK.current.play();
        }
      },
      notifyError: () => {
        window.navigator.vibrate && window.navigator.vibrate([80, 80, 80]);
        if (audioError.current) {
          audioError.current.play();
        }
      },
      notifyNeedAction: () => {
        if (audioNeedAction.current) {
          audioNeedAction.current.play();
        }
        window.navigator.vibrate && window.navigator.vibrate(80);
      },
      notifyInputIfNotCompleteOrOverAmounted: ({ transaction, currentIndex }, event) => {
        if (shouldNotifyInput(transaction, currentIndex)) {
          if (audioNeedAction.current) {
            audioNeedAction.current.play();
          }
          window.navigator.vibrate && window.navigator.vibrate(80);
        }
      },
      save: ({ transaction }: ScannerViewContext) => {
        saveTransaction(transaction);
      },

      optimisticallyUpdateSerialNumbers: async (context: ScannerViewContext) => {
        if (context.processConfig.trackingWarehouseOperation === TrackingWarehouseOperation.NONE) {
          return;
        }

        const itemsWithSerialNumberTracking = mergeAll(
          context.transaction.items
            .filter((item) => item.product.trackSerialNumbers)
            .map((item) => ({ [item.productId]: item }))
        );
        const warehouseStates = pipe(
          prop('rows'),
          map((item: { doc: WarehouseStatePouchEntity }) => ({ [item.doc.productId]: item.doc })),
          mergeAll
        )(
          await db.allDocs<ProductPouchEntity>({
            keys: Object.keys(itemsWithSerialNumberTracking).map((item) => `${ObjectType.WAREHOUSE_STATE}-${item}`),
            include_docs: true,
          })
        );

        if (context.processConfig.trackingWarehouseOperation === TrackingWarehouseOperation.ADD) {
          for (const item in itemsWithSerialNumberTracking) {
            await db.put({
              ...warehouseStates[item],
              serialNumbers: [
                ...(warehouseStates[item].serialNumbers ?? []),
                ...(itemsWithSerialNumberTracking[item].serialNumbers ?? []),
              ],
            });
          }
        }
        if (context.processConfig.trackingWarehouseOperation === TrackingWarehouseOperation.REMOVE) {
          for (const item in itemsWithSerialNumberTracking) {
            const serialNumbers = without(
              itemsWithSerialNumberTracking[item].serialNumbers!,
              warehouseStates[item].serialNumbers!
            );
            await db.put({
              ...warehouseStates[item],
              serialNumbers,
            });
          }
        }
      },

      updatePositionWithChangeRequest: async (context: ScannerViewContext, event) => {
        const newPosition: string = (event as EventTransactionSavePositions).newPosition;
        const positionsToDelete: string[] = (event as EventTransactionSavePositions).positionsToDelete;
        const productId = context.transaction.items[context.currentIndex].productId;

        try {
          const doc: WarehouseState = await db.get(`warehouseState-${productId}`);

          const newPositions = without(positionsToDelete, doc.position);

          const changeRequestFields: ChangeRequestField['fields'] = positionsToDelete.map((position) => ({
            name: 'position',
            action: ObjectTypeAction.REMOVE,
            value: position.trim(),
            currentValue: doc.position,
          }));

          if (doc && doc.position && !doc.position.includes(newPosition) && isNotEmpty(newPosition)) {
            newPositions.push(newPosition);
            changeRequestFields.push({
              name: 'position',
              action: ObjectTypeAction.ADD,
              value: newPosition.trim(),
              currentValue: doc.position,
            });
          }

          if (doc) {
            await db.put({
              ...doc,
              position: newPositions,
            });

            await createNewChangeRequest(
              db,
              {
                type: ObjectType.WAREHOUSE_STATE,
                itemId: doc.productId,
                fields: changeRequestFields,
              },
              useCaseDefinition.webhook
            );
          }
        } catch (e: any) {
          appLogger.error(e);
          //todo - toast error
        }
      },
      close: navigateToList,
    },
    services: {
      getWarehouseState: async (context, event) => {
        if (isNotNil(context.currentCode) && context.currentWarehouseState?.productId === context.currentCode) {
          return context.currentWarehouseState;
        }
        try {
          const productId = context.transaction.items[context.currentIndex].productId;
          const doc: WarehouseState = await db.get(`warehouseState-${productId}`);
          if (doc) {
            return doc;
          }
        } catch (e: any) {
          appLogger.error(e);
          throw e;
        }
      },
      findProduct: async ({
        currentEan,
        currentCode,
        validOptions,
        ...rest
      }: ScannerViewContext): Promise<FindProductResult | undefined> => {
        // This part is simplified. As long as Code is part of EANs this will work.
        // If Code is not added to EAN (done by Dativery) this will not work.
        // Possible solution, always add code to ean_code_view ;)
        const searchValue = { ean: currentEan || currentCode };
        const valuesToSearch = isNilOrEmpty(validOptions) ? [searchValue] : validOptions;
        try {
          const response = await db.query<ProductPouchEntity>('views/ean_code_view', {
            keys: valuesToSearch.map((item) => normalizeText(item.ean)),
            include_docs: true,
          });

          if (isEmpty(response.rows)) {
            return;
          }

          //get first result and merge other information we have
          return {
            product: response.rows[0].doc as ProductPouchEntity,
            ...(valuesToSearch.find((row) => row.ean === response.rows[0].key) as EanSearchValue),
          };
        } catch (error: any) {
          appLogger.error(error);
          throw error;
        }
      },
    },
    devTools: true,
  });

  const context = fsm.context;
  const state = fsm.value as State;
  const stateMachineEvents = useStateMachineEvents(send);
  const historyValue = (fsm?.history?.historyValue?.current || getInitialState(useCaseOptions)) as string;

  const isReadOnly = stateReadOnlyView(state);
  const isComplete = stateComplete(state);

  const handleLeaveDialogClose = useCallback(() => {
    setDialog(null);
    setAllowMessage(true);
    setCurrentItemIdCached(undefined); //returning from editNote redirect to `readyToScan` state, but keeps the item selected
  }, [setDialog, setAllowMessage, setCurrentItemIdCached]);

  //Todo - handle Interrupt and Suspend click - https://gitlab.commity.cz/mobilni-skladnik/mobilni-skladnik-application/-/issues/426
  const handleInterruptClick = useCallback(() => {
    setAllowMessage(false);
    setDialog({
      type: DialogTypes.Interruption,
      confirmEvent: stateMachineEvents.interruption,
      closeAction: handleLeaveDialogClose,
    });
  }, [setDialog, stateMachineEvents.interruption, handleLeaveDialogClose]);

  //Todo - handle Interrupt and Suspend click - https://gitlab.commity.cz/mobilni-skladnik/mobilni-skladnik-application/-/issues/426
  const handleSuspendClick = useCallback(() => {
    setAllowMessage(false);
    setDialog({
      type: DialogTypes.Suspension,
      confirmEvent: stateMachineEvents.interruption,
      closeAction: handleLeaveDialogClose,
    });
  }, [setDialog, stateMachineEvents.interruption, handleLeaveDialogClose]);

  const deleteTransaction = useCallback(async () => {
    const docs = await dbFind({
      selector: {
        _id: transaction._id,
      },
    });

    const doc = getFirstDocumentOrFalse(docs);

    await db.remove(doc);
    router.navigate('app.transaction');
  }, [db, dbFind, router, transaction._id]);

  const handleBackClick = useCallback(() => {
    navigateToList();
  }, [navigateToList]);

  const handleCloseHtmlDialog = useCallback(() => {
    stateMachineEvents.goBack(historyValue);
  }, [historyValue, stateMachineEvents]);

  const currentItem = useMemo(() => {
    if (context.currentIndex >= 0) {
      const item = context.transaction.items[context.currentIndex];
      // if the document is "free", all items may be deleted
      if (item) {
        setCurrentItemIdCached(item.id);
        return item;
      }
    } else {
      // we don't want to set currentItemIdCached to undefined - we need cache last valid value
      // this value is used for sorting, and we need let last changed item as current...
    }

    return undefined;
  }, [context.currentIndex, context.transaction.items]);

  useEffect(() => {
    // todo - local development only - not needed for production
    const subscription = service.subscribe((state) => {
      appLogger.trace('State machine: ' + state.value + '.' + state.event.type);
    });

    return subscription.unsubscribe;
  }, [service, appLogger]);

  useEffect(() => {
    if (context.transaction.customMsg) {
      stateMachineEvents.htmlDialog(context.transaction.customMsg);
    }
  }, [context.transaction.customMsg, stateMachineEvents]);

  useEffect(() => {
    //Todo - handle Interrupt and Suspend click - https://gitlab.commity.cz/mobilni-skladnik/mobilni-skladnik-application/-/issues/426
    if (stateEditNote(state)) {
      setAllowMessage(false);
      setDialog({
        type: DialogTypes.EditNote,
        confirmEvent: stateMachineEvents.saveNote,
        closeAction: handleLeaveDialogClose,
        cancelAction: () => stateMachineEvents.goBack(historyValue),
        stateMachineSaveEvent: context.noteContext?.noteDialogAction,
      });
    }
  }, [historyValue, context.noteContext, stateMachineEvents, handleLeaveDialogClose, state]);

  const canDelete = context.transaction.origin === TransactionOrigin.APP;

  const error = useMemo(() => context.transaction.responseError, [context.transaction.responseError]);

  const classes = useStyles({});

  return (
    <XStateProvider fsm={fsm} send={send}>
      <Scanner onReceive={stateMachineEvents.enterEan}>
        {scannerMachineType === ScannerMachineType.INPUT && (
          <input
            ref={(input) => {
              //this hack is to hide the device keyboard
              input?.setAttribute('readonly', 'readonly');
              setTimeout(() => {
                input?.focus();
                input?.removeAttribute('readonly');
              });
            }}
            readOnly
            data-disable-touch-keyboard
            className={classes.scannerInput}
          />
        )}
        <audio autoPlay={false} ref={audioOK} src="/sounds/ok.mp3" />
        <audio autoPlay={false} ref={audioError} src="/sounds/error.mp3" />
        <audio autoPlay={false} ref={audioNeedAction} src="/sounds/need_action.mp3" />
        <div className={classes.middleContainer}>
          <TransactionHeader
            isReadOnly={isReadOnly}
            isComplete={isComplete}
            title={
              '#' + context.transaction?.displayName
                ? context.transaction?.displayName
                : context.transaction?.transNumber
            }
            processId={context.transaction.processId}
            customMsg={context.transaction.customMsg}
            error={context.transaction.status === TransactionStatus.ERROR || Boolean(context.transaction.responseError)}
            canDelete={canDelete}
            onBackClick={handleBackClick}
            onOptionsInterruptClick={handleInterruptClick}
            onOptionsSuspendClick={handleSuspendClick}
            onEditNoteClick={stateMachineEvents.editNote}
            deleteTransaction={deleteTransaction}
            onHtmlDialog={stateMachineEvents.htmlDialog}
          />

          <TransactionDetail
            error={error}
            transaction={context.transaction}
            currentItemId={currentItemIdCached}
            title={context.transaction.id}
            onPlus={stateMachineEvents.plus}
            onMinus={stateMachineEvents.minus}
            onAmountChange={stateMachineEvents.changeAmount}
            onDelete={stateMachineEvents.deleteCurrentItem}
            onHtmlDialog={stateMachineEvents.htmlDialog}
          />
          {stateUnknownItemInOrder(state) && (
            <ErrorMessageByType
              errorType={ErrorType.PRODUCT_CODE_ERROR}
              handleClose={stateMachineEvents.closeError}
              code={context.currentCode}
              ean={context.currentEan}
              open={allowMessage}
            />
          )}

          {stateFindProductError(state) && (
            <ErrorMessage
              message="Při hledání produktu nastala neočekávaná chyba"
              handleClose={stateMachineEvents.closeError}
              open={allowMessage}
            />
          )}
          {stateUnknownItem(state) && (
            <ErrorMessageByType
              errorType={ErrorType.PRODUCT_DOES_NOT_EXIST}
              handleClose={stateMachineEvents.closeError}
              code={context.currentCode}
              ean={context.currentEan}
              open={allowMessage}
            />
          )}
          {stateItemComplete(state) && (
            <SuccessMessage
              message={'Položka ' + currentItem?.product.name + ' kompletní'}
              handleClose={stateMachineEvents.closeItem}
              open={allowMessage}
            />
          )}
        </div>
        <TransactionActionContainer
          setAllowMessage={setAllowMessage}
          state={state}
          handleBackClick={handleBackClick}
          eventComplete={stateMachineEvents.complete}
          eventEnterCode={stateMachineEvents.enterCode}
          eventStart={stateMachineEvents.start}
          user={user}
        />

        {/* modal dialogs */}

        {stateItemBadAmount(state) && currentItem && (
          <ProductErrorBig
            item={currentItem}
            errorType={ErrorType.COUNT_ERROR}
            onFixAmountClick={stateMachineEvents.fixItemAmount}
            onHtmlDialog={stateMachineEvents.htmlDialog}
          />
        )}
        {stateHtmlDialog(state) && (
          <HtmlDialog
            open={true}
            instruction={context.customMsg}
            title="Upozornění"
            cancelButton="Zavřít"
            onClose={handleCloseHtmlDialog}
          />
        )}

        {stateEditNote(state) && dialog && (
          <TransactionNoteDialog
            type={dialog.type}
            actionType={dialog.stateMachineSaveEvent}
            onConfirm={dialog.confirmEvent}
            onCancel={dialog.cancelAction}
            onClose={dialog.closeAction}
            originalNote={context.transaction.note}
          />
        )}

        {stateHandlePositions(state) && currentItem && (
          <TransactionPositionDialog
            product={currentItem}
            onCancel={() => stateMachineEvents.goBack(historyValue)}
            onConfirm={stateMachineEvents.savePositions}
          />
        )}

        {stateHandleSerialNumbers(state) && currentItem && (
          <TransactionSerialNumbersDialog context={context} transactionItem={currentItem} />
        )}

        {stateHandleBatchesAndExpirations(state) && currentItem && (
          <TransactionBatchesAndExpirationsDialog context={context} transactionItem={currentItem} />
        )}
      </Scanner>
    </XStateProvider>
  );
};

export default ScannerView;
