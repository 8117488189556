import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { filter, pathEq, pipe, prop, sortBy, values } from 'ramda';
import React, { useContext } from 'react';
import { AppConfigContext } from '../../../hooks/useCaseDefinition';
import { UseCaseDefinition } from '../../../model';
import { getDocumentColor, getDocumentIcon } from '../../common/helpers';
import TileMenuItem from '../../tileMenu/Tile/TileMenuItem';

export const FOOTER_HEIGHT = 50;
export const FOOTER_HEIGHT_XS = 65;

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    width: '100%',
    rowGap: 2,
    columnGap: 2,
  },

  gridItem: {
    flex: '1 1 calc(50% - 2px)',
    height: `${FOOTER_HEIGHT}px`,
    [breakpoints.down('xs')]: {
      height: `${FOOTER_HEIGHT_XS}px`,
    },
  },
}));

type Props = StandardProps & {
  onGenerateTransactionClick: (useCaseDefinition: UseCaseDefinition) => void;
};

const ListFooter: React.FC<Props> = ({ onGenerateTransactionClick, ...others }) => {
  const classes = useStyles({});
  const { visibleUseCaseDefinitions } = useContext(AppConfigContext);
  const openDefinitions = pipe<any, UseCaseDefinition[], UseCaseDefinition[], UseCaseDefinition[]>(
    values,
    sortBy(prop('order')),
    filter(pathEq(['processConfig', 'openList'], true))
  )(visibleUseCaseDefinitions);

  if (openDefinitions.length < 1) {
    return null;
  }

  return (
    <Grid container className={classNames(classes.root, others.className)}>
      {openDefinitions.map((definition, index) => (
        <Grid item xs key={index} className={classes.gridItem}>
          <TileMenuItem
            tile={{
              label: definition.name,
              icon: getDocumentIcon(definition),
              color: getDocumentColor(definition),
              fullWidth: false,
            }}
            onClick={() => onGenerateTransactionClick(definition)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ListFooter;
