import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLogger } from '../../appLogger';
import { AppSettingKeys, setSettings } from '../store/settings/appSettings';
import { useSettingsState } from '../store/settings/selectors';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 120,
  },
}));

export interface ValueDefinition<T> {
  value: T;
  text: string;
}

export interface SettingSelectProps {
  valuesDefinition: ValueDefinition<ToFix>[];
  settingsKey: AppSettingKeys;
}

export const SettingSelect: React.FC<SettingSelectProps> = ({ valuesDefinition, settingsKey, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appLogger = useLogger();

  const appSettingValue = useSettingsState()[settingsKey];
  const [valueSelect, setValueSelect] = React.useState(appSettingValue);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = event?.target?.value;
      setValueSelect(value as typeof appSettingValue);
      dispatch(setSettings({ [settingsKey]: value }));
    },
    [settingsKey, dispatch]
  );

  if (typeof valueSelect === 'boolean') {
    appLogger.debug("Incompatible `settingsKey` Type. Can't be Boolean");
    return null;
  }

  return (
    <Select variant="standard" value={valueSelect} onChange={handleChange} className={classes.root} {...props}>
      {valuesDefinition.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
