import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import Highlight from 'react-highlighter';
import { useProcessConfig } from '../../../hooks/useCase';
import { Product } from '../../../model';
import { Theme } from '../../../theme';

const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) => ({
  matchingOptionPart: {
    fontWeight: 600,
    color: palette.primary.main,
  },
  optionValues: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: spacing(0),
      fontSize: '0.8rem',
    },
  },
  options: {
    width: '100%',
    flexDirection: 'column',

    [breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      lineHeight: 'initial',
    },
  },
  errorMessagePaper: {
    padding: spacing(1, 0),
    marginTop: spacing(0.5),
    boxSizing: 'border-box',
    maxHeight: 45, // a single option height
  },
  errorMessage: {
    margin: 'auto',
    padding: spacing(1),
    fontStyle: 'italic',
    fontWeight: 600,
  },
  autocomplete: {
    width: '95%',
    marginLeft: spacing(1),
    [breakpoints.down('sm')]: {
      width: '100%',
      margin: spacing(1, 0, 0),
    },
  },
  select: {
    height: '40px',
  },
  formControl: {
    width: '130px',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

type Props = StandardProps & {
  onChange: (event: object, value: string | void | null | Product) => void;
  onInputChange?: (event: object, value: string, reason: string) => void;
  searchVariant: SearchVariant;
  error?: boolean;
  options: Product[];
  handleChangeSearchByText?: (SyntheticEvent, number) => void;
  inputValue: string | undefined;
  isLoading: boolean;
  setSelectedCode: (code: string) => void;
};

export enum SearchVariant {
  Ean = 'Ean',
  Name = 'Name',
}

const ProductCodeInput: React.FC<Props> = ({
  options,
  inputValue,
  handleChangeSearchByText,
  searchVariant,
  onChange,
  error,
  onInputChange,
  isLoading,
  setSelectedCode,
  ...others
}) => {
  const classes = useStyles();
  const [focus, setFocus] = useState(false);

  const { numericCodesOnly } = useProcessConfig();

  const getOptionLabelByEan = (option: Product) => {
    return option.code + option.ean?.join(' ');
  };
  const getOptionLabelByName = (option: Product) => {
    return option.code + option.name + option.ean?.join(' ');
  };
  const getOptionLabelByCode = (option: string | Product) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.code;
  };
  const filterOptionsRaw = createFilterOptions({
    stringify: searchVariant === SearchVariant.Name ? getOptionLabelByName : getOptionLabelByEan,
  });

  const filterOptions = (options: Product[], state: FilterOptionsState<any>) => {
    const result = filterOptionsRaw(options, state);
    if (result.length === 1) {
      setSelectedCode(result[0].code);
    }
    return result;
  };

  return (
    <Box>
      <Tabs value={searchVariant} onChange={handleChangeSearchByText} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="EAN" value={SearchVariant.Ean} />
        <Tab label="Název" value={SearchVariant.Name} />
      </Tabs>

      <Autocomplete
        sx={{ mt: 1 }}
        className={classes.autocomplete}
        loading={isLoading}
        inputValue={inputValue}
        options={options}
        freeSolo={true} // enables user input other than the options
        autoHighlight={true}
        onChange={onChange}
        onInputChange={onInputChange}
        blurOnSelect={'touch'} // hide keyboard on mobile after selection
        clearText={'Vymazat'}
        openText={'Zobrazit produkty'}
        closeText={'Sbalit produkty'}
        size={'small'}
        forcePopupIcon={true} // necessary in freeSolo mode
        popupIcon={<ArrowDropDownIcon color={error ? 'disabled' : 'inherit'} />}
        renderInput={(params) => (
          <TextField
            data-test={'HledatProdukt'}
            type={searchVariant || !numericCodesOnly ? 'text' : 'number'}
            label={searchVariant === SearchVariant.Name ? 'Název produktu' : 'EAN/Kód produktu'}
            error={error}
            variant="standard"
            autoFocus
            {...params}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            inputProps={{
              ...params.inputProps,
              onKeyDown: (e) => {
                if (isLoading && e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                }
              },
            }}
          />
        )}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabelByCode}
        renderOption={(props, option, { inputValue }) => (
          <Box component="li" sx={{ p: 0.5, flexDirection: 'column' }} {...props}>
            <Typography sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
              {searchVariant ? (
                <Highlight
                  ignoreDiacritics
                  search={inputValue}
                  matchElement="span"
                  matchClass={classes.matchingOptionPart}
                >
                  {option.name}
                </Highlight>
              ) : (
                option.name
              )}
            </Typography>
            <Box className={classes.optionValues}>
              <Highlight
                ignoreDiacritics
                search={inputValue}
                matchElement="span"
                matchClass={classes.matchingOptionPart}
              >
                {'Kód: ' + option.code}
              </Highlight>
              <Highlight
                ignoreDiacritics
                search={inputValue}
                matchElement="span"
                matchClass={classes.matchingOptionPart}
              >
                {'EAN: ' + option.ean.join(', ')}
              </Highlight>
            </Box>
          </Box>
        )}
      />
      {error && (
        <Paper className={classes.errorMessagePaper} elevation={focus ? 1 : 0}>
          <Typography className={classes.errorMessage} variant="body2" color="error">
            Žádné produkty s tímto kódem
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default ProductCodeInput;
