import { add, append, assoc, indexOf, length, lensPath, lensProp, max, over, path, pathOr, remove } from 'ramda';
import { calculateIncreaseQuantity } from '../../../helpers/transactionItem';
import { BatchAndExpiration, Product, Transaction, TransactionItem } from '../../../model';
import { EventEnterEan, EventTransactionDiscardSerialNumber, EventTypes, ScannerViewEvent } from './events';
import { hasBatch, hasCode, hasEan } from './guards';
import { ScannerViewContext } from './types';

export const EMPTY_ACTION = {
  actions: [],
  cond: () => false,
  target: '',
};

export function getItemIndex(transaction: Transaction, code: string) {
  return transaction.items.findIndex(hasCode(code));
}

export function getBatchIndex(batches: BatchAndExpiration[], batchId: BatchAndExpiration['id']) {
  return batches.findIndex((item) => hasBatch(batchId)(item));
}

export function getItemCode(transaction: Transaction, ean: string) {
  const item = transaction.items.find(hasEan(ean));
  return pathOr('', ['product', 'code'], item);
}

export function getCurrentItem(transaction: Transaction, currentIndex: number) {
  return path<TransactionItem>(['items', currentIndex], transaction);
}

export function processItemAddition(
  transaction: Transaction,
  currentIndex: number,
  hasCountOverrides: boolean,
  amount?: ScannerViewContext['amountToAdd']
) {
  const currentItemAmountCompletedLens = lensPath(['items', currentIndex, 'amountCompleted']);
  const increaseQuantity = calculateIncreaseQuantity(
    path(['items', currentIndex], transaction)!,
    hasCountOverrides,
    amount
  );

  return over(currentItemAmountCompletedLens, add(increaseQuantity), transaction);
}

export function fixItemAmount(transaction: Transaction, currentIndex: number) {
  const currentItemLens = lensPath(['items', currentIndex]);

  return over(currentItemLens, (item) => assoc('amountCompleted', item.amount, item), transaction);
}

export function addItemAmount(transaction: Transaction, currentIndex: number, event: ScannerViewEvent) {
  //@ts-expect-error - event.code does not exists on some ScannerViewEvent types
  const newAmount = event.hasOwnProperty('amount') ? event.amount : 1;

  const currentItemLens = lensPath(['items', currentIndex, 'amountCompleted']);
  if (event.type === EventTypes.PLUS || event.type === EventTypes.PLUS_BATCH || event.type === EventTypes.ENTER_EAN) {
    return over(currentItemLens, (amount) => Math.round((amount + newAmount) * 100) / 100, transaction);
  } else if (event.type === EventTypes.MINUS || event.type === EventTypes.MINUS_BATCH) {
    return over(currentItemLens, (amount) => max(Math.round((amount - newAmount) * 100) / 100, 0), transaction);
  } else if (event.type === EventTypes.CHANGE_AMOUNT) {
    return over(currentItemLens, (amount) => max(newAmount, 0), transaction);
  }

  return transaction;
}

export function addBatchToItemAction(transaction: Transaction, currentIndex: number, event: EventEnterEan) {
  const currentItemLens = lensPath(['items', currentIndex, 'batchesAndExpirations']);

  return over(
    currentItemLens,
    (batchesAndExpirations: BatchAndExpiration[] = []) => [
      ...batchesAndExpirations,
      { id: event.code, amount: 0, batchName: event.code },
    ],
    transaction
  );
}

export function addBatchAndExpirationAmountAction(
  transaction: Transaction,
  currentIndex: number,
  currentBatchIndex: number,
  event: ScannerViewEvent
) {
  //@ts-expect-error - event.code does not exists on some ScannerViewEvent types
  const newAmount = event.hasOwnProperty('amount') ? event.amount : 1;
  const currentItemLens = lensPath(['items', currentIndex, 'batchesAndExpirations', currentBatchIndex, 'amount']);
  const updatedTransaction = addItemAmount(transaction, currentIndex, event);

  if (event.type === EventTypes.PLUS_BATCH || event.type === EventTypes.ENTER_EAN) {
    return over(currentItemLens, (amount = 0) => Math.round((amount + newAmount) * 100) / 100, updatedTransaction);
  } else if (event.type === EventTypes.MINUS_BATCH) {
    return over(
      currentItemLens,
      (amount = 0) => max(Math.round((amount - newAmount) * 100) / 100, 0),
      updatedTransaction
    );
  } else if (event.type === EventTypes.CHANGE_AMOUNT) {
    return over(currentItemLens, () => max(newAmount, 0), updatedTransaction);
  }

  return updatedTransaction;
}

export function addItemSerialNumber(transaction: Transaction, currentIndex: number, event: EventEnterEan) {
  const currentItemLens = lensPath(['items', currentIndex, 'serialNumbers']);
  return over(currentItemLens, (serialNumbers = []) => [...serialNumbers, event.code], transaction);
}

// removing recently added serial number into transaction.
export function discardItemSerialNumber(
  transaction: Transaction,
  currentIndex: number,
  event: EventTransactionDiscardSerialNumber
) {
  const currentItemLens = lensPath(['items', currentIndex, 'serialNumbers']);
  return over(
    currentItemLens,
    (serialNumbers = []) => {
      //remove one occurrence of code
      const index = indexOf(event.value, serialNumbers);
      return index === -1 ? serialNumbers : remove(index, 1, serialNumbers);
    },
    transaction
  );
}

export function discardBatchAction(transaction: Transaction, currentIndex: number, currentBatchIndex: number) {
  const currentItemLens = lensPath(['items', currentIndex, 'batchesAndExpirations']);
  return over(
    currentItemLens,
    (batchesAndExpirations = []) => {
      return currentBatchIndex === -1 ? batchesAndExpirations : remove(currentBatchIndex, 1, batchesAndExpirations);
    },
    transaction
  );
}

export function assignErrorMessage(event: any) {
  if (event.data) {
    return event.data.name || event.data.code || '';
  }
  return '';
}

export function addCurrentProductToTransaction(transaction: Transaction, product: Product) {
  //todo - validate first add - it should add more then one, but not more then scanned value
  const numberOfItems = length(transaction.items) + 1;

  return over(
    lensProp('items'),
    append<TransactionItem>({
      id: numberOfItems,
      order: numberOfItems,
      productId: product.productId,
      product: product,
      amountCompleted: 0,
    }),
    transaction
  );
}

export function removeCurrentProduct(transaction: Transaction, currentIndex: ScannerViewContext['currentIndex']) {
  return over(lensProp('items'), remove(currentIndex, 1), transaction);
}

/**
 * default implementacion, in React component we use inline audio element, because it has faster start
 */

export function notifyOk() {
  const audio = new Audio('/sounds/ok.mp3');
  audio.play();
}

export function notifyNeedAction() {
  const audio = new Audio('/sounds/need_action.mp3');
  audio.play();
  window.navigator.vibrate(80);
}

export function notifyError() {
  const audio = new Audio('/sounds/error.mp3');
  audio.play();
  window.navigator.vibrate([80, 80, 80]);
}

export function save() {}
export function updatePositionWithChangeRequest() {}
