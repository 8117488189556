import { ProcessConfig, StateNode } from '../types';

export function editPositions(options: ProcessConfig): StateNode {
  return {
    exit: 'positionHandled',
    invoke: {
      src: 'getWarehouseState',
      onError: { target: 'itemCounter' },
    },
    on: {
      SAVE_POSITIONS: {
        actions: ['updatePositionWithChangeRequest'],
        target: 'itemCounter',
      },
      GO_BACK: 'itemCounter',
    },
  };
}
