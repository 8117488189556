import { StateNode, ProcessConfig } from '../types';

export function unknownItem(options: ProcessConfig): StateNode {
  return {
    on: {
      ENTER_EAN: 'eanEntered',
      ENTER_CODE: 'codeEntered',
      CLOSE_ERROR: 'readyToScan',
    },
  };
}
