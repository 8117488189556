import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderRadius: 0,
    width: '100%',
  },
}));

type Props = StandardProps;

const ActionContainer: React.FC<Props> = ({ children, ...others }) => {
  const classes = useStyles();

  return <div className={classNames(classes.root, others.className)}>{children}</div>;
};

export default ActionContainer;
