import { ProcessConfig, StateNode } from '../types';

export function eanEntered(options: ProcessConfig): StateNode {
  return {
    entry: options.handleAmountInEan ? 'findInTransactionOrBuildPossibleSearchValues' : 'assignCurrentEanFromEvent',
    always: [
      {
        target: 'preItemSelected',
        cond: 'isEanInTransaction',
        actions: ['assignCurrentProductCode', 'assignCurrentProductIndex'],
      },
      { target: 'findProduct' },
    ],
  };
}
