import PouchDB from 'pouchdb';
import { getToken } from './authService';

export function createLocalDb(name: string) {
  return new PouchDB(name);
}

export function createRemoteDb(connectionUri: string) {
  return new PouchDB(connectionUri, {
    fetch: function (url, opts) {
      if (process.env.NODE_ENV !== 'production') {
        // development, staging
        // see https://gitlab.commity.cz/mobilni-skladnik/mobilni-skladnik-application/-/issues/515
        // @ts-ignore
        delete opts.credentials;
      }
      // @ts-ignore
      opts.headers.set('Authorization', `Bearer ${getToken()}`);
      return PouchDB.fetch(url, opts);
    },
  });
}

export async function startTransactionReplication(
  sourceDB: PouchDB.Database,
  targetDB: PouchDB.Database,
  appLogger: any
) {
  const transactionIds = (await sourceDB.query('views/transactions_view')).rows.map((row) => row.id as string);
  return PouchDB.replicate(sourceDB, targetDB, {
    doc_ids: ['_design/views', ...transactionIds],
  })
    .on('complete', () => appLogger.trace('Transaction replication complete'))
    .on('change', () => appLogger.trace('Transaction replication replicate - change'))
    .on('active', () => appLogger.trace('Transaction replication active'))
    .on('denied', () => appLogger.warn('Transaction replication denied'))
    .on('error', (err) => appLogger.error(err, 'Transaction replication error: ' + err))
    .on('paused', () => appLogger.trace('Transaction replication paused'));
}

export function startSynchronization(db: PouchDB.Database, localDb: PouchDB.Database, appLogger: any) {
  const syncHandler = PouchDB.sync(db, localDb, {
    live: true,
    retry: true,
    batch_size: 1000,
    batches_limit: 5, // this limit could be problem
  })
    //.on('change', (...args) => appLogger.trace('Synchronization - change', args))
    .on('active', () => appLogger.trace('Sync active'))
    .on('denied', () => appLogger.warn('Sync denied'))
    .on('error', (err) => {
      appLogger.error(err, 'Sync error: ' + err);
      syncHandler.cancel();
      setTimeout(startSynchronization, 3000);
    })
    .on('paused', () => appLogger.trace('Sync paused'));
  return syncHandler;
}

export async function init(localDbName: string, remoteDbUrl: string, appLogger: any) {
  const localDB = createLocalDb(localDbName);
  const remoteDB = createRemoteDb(remoteDbUrl);

  let replicationStatus = {};
  try {
    appLogger.trace('Starting replication');
    console.time('Replication');
    replicationStatus = await startTransactionReplication(remoteDB, localDB, appLogger);
    appLogger.debug(replicationStatus);
    console.timeEnd('Replication');
  } catch (e: any) {
    if (navigator.onLine) {
      throw new Error(e);
    } else {
      appLogger.error(`Replication did not start due to offline modes: ${e}`, e);
    }
  }

  const syncHandler = startSynchronization(remoteDB, localDB, appLogger).catch((e) =>
    appLogger.error(e, 'Synchronization error: ' + e.message)
  );

  localDB.on('destroyed', () => {
    appLogger.trace('Local database destroyed');
  });

  return {
    localDB,
    remoteDB,
    replicationStatus,
    syncHandler: syncHandler as typeof syncHandler & { cancel: () => void },
  };
}

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type DBInfo = ThenArg<ReturnType<typeof init>>;

export async function deleteDatabase(db: PouchDB.Database) {
  //todo - delete all databases in device - not very friendly
  return db.destroy();
}
