import {
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Sort from '@mui/icons-material/Sort';
import { pipe, values } from 'ramda';
import React, { useCallback, useContext } from 'react';
import { AppConfigContext } from '../../../hooks/useCaseDefinition';
import { UseCaseDefinition } from '../../../model';
import { Theme } from '../../../theme';
import { SortDefinition, SortType } from '../sorting';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    display: 'flex',
  },
  username: {
    color: palette.primary.dark,
    padding: spacing(0.5, 2),
  },
  filter: {
    padding: '6px 16px',
  },
  listItem: {
    padding: 0,
    maxHeight: '48px',
  },
  listItemIcon: {
    marginRight: '-25px',
  },
}));

type Props = StandardProps & {
  sort: SortType;
  sortDefinitions: SortDefinition[];
  onSortChange: (sortType: SortType) => void;
  handleFilter: (processId: string[]) => void;
};

const SortingMenu: React.FC<Props> = ({ sort, onSortChange, sortDefinitions, handleFilter, ...others }) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [checked, setChecked] = React.useState<string[]>([]);
  const { visibleUseCaseDefinitions } = useContext(AppConfigContext);
  const definitions = pipe<any, UseCaseDefinition[]>(values)(visibleUseCaseDefinitions);

  const open = Boolean(anchorEl);

  const handleUserClick = useCallback((e: ToFix) => setAnchorEl(e.currentTarget), [setAnchorEl]);
  const onSortClick = useCallback(
    (type: ToFix) => {
      setAnchorEl(null);
      onSortChange(type);
    },
    [setAnchorEl, onSortChange]
  );

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    handleFilter([...newChecked]);
  };
  return (
    <div className={classes.root} {...others}>
      <IconButton
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleUserClick}
        color="inherit"
        data-test="documentFilterButton"
        size="large"
      >
        <Sort />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} data-test="documentFilterMenu">
        {sortDefinitions.map(({ type, title }) => (
          <MenuItem
            data-test={'Razeni'}
            key={title}
            selected={type === sort}
            onClick={() => {
              onSortClick(type);
            }}
          >
            {title}
          </MenuItem>
        ))}
        <Divider />
        <div className={classes.filter} data-test="filterOpen">
          <Typography> Filtrování: </Typography>
          <List>
            {definitions.map((definition) => {
              return (
                <ListItem
                  data-test={'Filtrovani'}
                  key={definition.processId}
                  button
                  onClick={handleToggle(definition.processId)}
                  className={classes.listItem}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <Checkbox edge="start" checked={checked.indexOf(definition.processId) !== -1} />
                  </ListItemIcon>
                  <ListItemText id={definition.processId} primary={definition.name} data-test="filterName" />
                </ListItem>
              );
            })}
          </List>
        </div>
      </Menu>
    </div>
  );
};

export default React.memo(SortingMenu);
