import { SvgIconProps } from '@mui/material';
import React, { useCallback, useState } from 'react';
import EnterProductCodeButton from './EnterProductCodeButton';
import ProductCodeModal from './ProductCodeModal';

type ButtonProps = {
  buttonText?: string;
  buttonTitle?: string;
  iconProps?: SvgIconProps;
  className?: string;
};

type Props = StandardProps & {
  onConfirm: (event: any) => void;
  onClick: () => void;
  onClose: () => void;
  buttonProps?: ButtonProps;
  modalClassName?: string;
};

const EnterProductCodeButtonWithModal: React.FC<Props> = ({
  onConfirm,
  onClick,
  onClose,
  buttonProps,
  modalClassName,
}) => {
  const [openCodeModal, setOpenCodeModal] = useState(false);
  const handleClick = useCallback(() => {
    onClick();
    setOpenCodeModal(true);
  }, [onClick, setOpenCodeModal]);
  const handleClose = useCallback(() => {
    onClose();
    setOpenCodeModal(false);
  }, [onClose, setOpenCodeModal]);

  return (
    <>
      <EnterProductCodeButton onClick={handleClick} {...buttonProps} />
      <ProductCodeModal open={openCodeModal} onConfirm={onConfirm} onClose={handleClose} className={modalClassName} />
    </>
  );
};

export default EnterProductCodeButtonWithModal;
