import { ExtractStateValue, StateNodeConfig, StateSchema } from 'xstate';

import { AmountInEanConfig } from '../../../hooks/useCaseDefinition';
import { ProductPouchEntity, Transaction, WarehouseStatePouchEntity } from '../../../model';
import { EventTypes, ScannerViewEvent } from './events';
import { ScannerViewStateSchema } from './state';

export type State = ExtractStateValue<ScannerViewStateSchema>;

export interface ScannerViewContext {
  amountInEanConfig: AmountInEanConfig[];
  amountToAdd?: number;
  currentBatchIndex: number;
  currentCode: string;
  currentEan: string;
  currentIndex: number;
  currentWarehouseState?: WarehouseStatePouchEntity;
  customMsg?: string;
  displayedWarningById: number[];
  errorMessage: string;
  hasCountOverrides: boolean;
  hasFixedCount: boolean;
  noteContext?: NoteContext;
  positionContext: PositionContext;
  processConfig: ProcessConfig;
  transaction: Transaction;
  validOptions: EanSearchValue[];
}

export interface PositionContext {
  options: any;
  handledCodes: string[];
  currentWarehouseState?: WarehouseStatePouchEntity;
}
export interface NoteContext {
  hasNoteEdited?: boolean;
  hasNoteRequired?: boolean;
  noteDialogAction?: EventTypes.SAVE_NOTE | EventTypes.SAVE_AND_COMPLETE;
}

export type StateNode = StateNodeConfig<ScannerViewContext, StateSchema<ScannerViewContext>, ScannerViewEvent>;

export enum TrackingWarehouseOperation {
  ADD = 'add',
  REMOVE = 'remove',
  NONE = 'none',
}

export type ProcessConfig = {
  openList: boolean;
  fixedCount: boolean;
  countOverrides: boolean;
  numericCodesOnly?: boolean;
  requireNote: boolean;
  showSellPrice: boolean;
  handlePosition: HandlePosition;
  handleAmountInEan: boolean;
  tracking: boolean;
  trackingOpenDocument: boolean;
  trackingOpenWarehouse: boolean;
  trackingWarehouseOperation:
    | TrackingWarehouseOperation.ADD
    | TrackingWarehouseOperation.REMOVE
    | TrackingWarehouseOperation.NONE;
  trackingExisting: 'error' | 'warn' | 'ignore';
  trackingMissing: 'error' | 'warn' | 'ignore';
};

export type ExtendedAndModifiedStateDefinitionOptionsToBeRefactoredSomeDayLater = {
  isOpen: ProcessConfig['openList'];
  isFixedCount: ProcessConfig['fixedCount'];
  isCountOverrides: ProcessConfig['countOverrides'];
  canDelete: Boolean;
} & ProcessConfig;

export enum HandlePosition {
  NEVER = 'never',
  ALWAYS = 'always',
  IF_EMPTY = 'ifEmpty',
  ON_REQUEST = 'onRequest',
}

//Todo move into type file
export interface EanSearchValue {
  ean: string;
  amount?: number;
}

export interface FindProductResult extends EanSearchValue {
  product: ProductPouchEntity;
}
