import { StateNode, ProcessConfig } from '../types';
import { openTransaction } from '../stateDefinitionOptions';

export function readyToScan(options: ProcessConfig): StateNode {
  //assigning, not saving -> will not be saved unless any event with save will follow
  const always: any = [
    { cond: 'isTransactionNew', actions: 'assignStateAndUser' },
    { cond: 'isTransactionComplete', target: 'complete' },
  ];

  if (!openTransaction(options)) {
    always.push({ cond: 'isTransactionFulfilled', target: 'transactionBeforeComplete' });
  }

  return {
    exit: ['clearCurrentCode'],
    always,
    on: {
      ENTER_EAN: { target: 'eanEntered' },
      ENTER_CODE: { target: 'codeEntered' },
      COMPLETE: { target: 'transactionBeforeComplete' },
    },
  };
}
