import fingerprintJS from '@fingerprintjs/fingerprintjs';
import PouchDB from 'pouchdb';
import { isNil } from 'ramda';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { User } from '../model';
import { changeSentryUser } from '../sentry';
import { deleteDatabase } from './pouchService';

export type TokenPayload = User & {
  exp: number;
  iat?: number;
  requireLogin: boolean;
};

const storageKey = 'jwtToken';
export const storageDeviceID = 'deviceId';
export const storageRequireLogin = 'requireLogin';
export const storageTenantId = 'tenantId';
export const storageTenantInfo = 'tenantInfo';

export const getToken = () => {
  return localStorage.getItem(storageKey);
};

export const getDeviceId = () => {
  return localStorage.getItem(storageDeviceID);
};

export const getTenantId = () => {
  return localStorage.getItem(storageTenantId);
};

export const getTenantInfo = (): TokenPayload => {
  const tenantInfo = localStorage.getItem(storageTenantInfo);
  return tenantInfo ? JSON.parse(tenantInfo) : null;
};

export const isPairedDevice = () => {
  return !!localStorage.getItem(storageDeviceID);
};

export const requireLogin = () => {
  if (!isNil(localStorage.getItem(storageRequireLogin))) {
    return localStorage.getItem(storageRequireLogin) === 'true';
  }
  return true;
};

const clearToken = () => {
  // is this good enough for requireLogin = true?
  // https://gitlab.commity.cz/mobilni-skladnik/mobilni-skladnik-application/-/issues/435
  localStorage.removeItem(storageKey);
  userSubject.next(undefined);
  changeSentryUser();
};

function b64DecodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

export const getTokenPayload = (token: string | null): TokenPayload | undefined => {
  let data;

  if (token) {
    const payload = token.split('.');

    if (payload.length === 3) {
      data = JSON.parse(b64DecodeUnicode(payload[1]));
    }
  }

  return data;
};
export const isTokenExpired = () => !isTokenNotExpired();

const isTokenNotExpired = (data = getInitialUser()) => {
  if (data) {
    const now = Math.floor(Date.now() / 1000);
    return now < data.exp;
  }

  return false;
};

export function isPairedAndAuthenticated(jwtUser = getInitialUser()) {
  if (!(jwtUser && isPairedDevice())) {
    return false;
  } else if (jwtUser.requireLogin) {
    return isTokenNotExpired(jwtUser);
  } else {
    return true;
  }
}

function getInitialUser() {
  const token = getToken();
  if (!token) {
    return undefined;
  }

  return getTokenPayload(token);
}

export const userSubject = new BehaviorSubject<User | undefined>(getInitialUser());
export const userState$ = userSubject.pipe(distinctUntilChanged());

const setToken = (jwtToken: string) => {
  localStorage.setItem(storageKey, jwtToken);
  localStorage.setItem(storageTenantInfo, JSON.stringify(getTokenPayload(jwtToken)));
  userSubject.next(getTokenPayload(jwtToken));
};

function isSuccessful(response: Response) {
  return response.status >= 200 && response.status < 300;
}
export const extendToken = authenticate;

export async function authenticate(REACT_APP_API_URL: string, pin?: string) {
  const requestData = { pin, deviceId: getDeviceId() };

  const response = await fetch(`${REACT_APP_API_URL}/app/auth/token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Request-Headers': 'X-Auth-Token',
    },
    body: JSON.stringify(requestData),
  }).then((response) => {
    if (!isSuccessful(response)) {
      throw response;
    }
    return response;
  });

  const authToken = response.headers.get('x-auth-token');
  if (authToken) {
    setToken(authToken);
    changeSentryUser(getTokenPayload(authToken));
  }
}

export function logOut() {
  clearToken();
}

export async function pair(REACT_APP_API_URL: string, pairToken: string) {
  let deviceId = getDeviceId() || uuidv4();
  const fpPromise = fingerprintJS.load();
  const fp = await fpPromise;
  const result = await fp.get();

  const fingerprint = result.visitorId;

  const requestData = { pairToken, deviceId, fingerprint };

  const response = await fetch(`${REACT_APP_API_URL}/v1/device/pair`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Request-Headers': 'X-Auth-Token',
    },
    body: JSON.stringify(requestData),
  }).then((response) => {
    if (!isSuccessful(response)) {
      throw response;
    }

    return response.json();
  });
  localStorage.setItem(storageRequireLogin, response.requireLogin);
  localStorage.setItem(storageTenantId, response.tenantId);
  localStorage.setItem(storageDeviceID, deviceId);
}

export function disconnectDevice(db: PouchDB.Database) {
  localStorage.clear();
  return deleteDatabase(db);
}
