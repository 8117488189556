import createRouter, { Route, RouteNode, Router, State } from 'router5';
import browserPlugin from 'router5-plugin-browser';
import { isPairedAndAuthenticated } from '../../service/authService';

const canActivate = (router: Router) => (toState: State, fromState: State) => {
  return isPairedAndAuthenticated();
};

const routes: Route[] | RouteNode = [
  { name: 'login', path: '/login' },
  {
    name: 'app',
    path: '/',
    canActivate,
    forwardTo: 'app.transaction.list',

    children: [
      { name: 'settings', path: 'settings' },
      {
        name: 'transaction',
        path: 'transaction',
        children: [
          { name: 'list', path: '/' },
          { name: 'detail', path: '/:id' },
        ],
      },
    ],
  },
];

// TEMP prevent back button
window.onpopstate = function (event: PopStateEvent) {
  if (event.state.name !== 'app.transaction.list') {
    event.stopImmediatePropagation();
    event.preventDefault();
  }
};

const router = createRouter(routes);
router.usePlugin(browserPlugin());

export default router;
