import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { ReactElement, useCallback, useState } from 'react';
import { Theme } from '../../theme';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  productInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: spacing(1),
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

export type CustomDialogProps = StandardProps & {
  title: string;
  instruction?: ReactElement[];
  actionButton?: string;
  cancelButton?: string;
  showButton?: boolean;
  open?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
};

const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  instruction,
  actionButton,
  cancelButton,
  onConfirm,
  onClose,
  children,
  open = false,
  ...others
}) => {
  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(open);

  // const handleShow = useCallback(() => {
  //   setShowDialog(true);
  // }, [setShowDialog]);

  const handleClose = useCallback(() => {
    onClose && onClose();
    setShowDialog(false);
  }, [setShowDialog, onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm && onConfirm();
    setShowDialog(false);
  }, [onConfirm, setShowDialog]);

  //const childrenWithProps = isValidElement(children) ? cloneElement(children, { onClick: handleShow }) : children;

  return (
    <>
      <Dialog open={showDialog} fullScreen {...others} className={classNames(classes.root, others.className)}>
        <DialogTitle>{title}</DialogTitle>
        <IconButton onClick={handleClose} className={classes.closeButton} size="large">
          <Close />
        </IconButton>
        <DialogContent>
          {instruction && <DialogContentText component="span">{instruction}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          {cancelButton && (
            <Button onClick={handleClose} color="secondary" fullWidth variant="contained">
              {cancelButton}
            </Button>
          )}
          {actionButton && (
            <Button onClick={handleConfirm} color="primary" fullWidth variant="contained">
              {actionButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/*childrenWithProps - I am not sure, what it should do */}
    </>
  );
};

export default CustomDialog;
