import { propOr } from 'ramda';

export enum ScannerMachineMode {
  DEFAULT = 'DEFAULT',
  ZEBRA = 'ZEBRA',
}

export enum ScannerMachineType {
  DEFAULT = 'DEFAULT',
  INPUT = 'INPUT',
}

export type CodeMap = { [key: string]: string };

export type Options = Omit<typeof defaultOptions, 'startChar' | 'endChar'> & {
  startChar?: string;
  endChar?: string;
};

export const lineTerminator = 'term';

const codeMapControls: CodeMap = {
  Alt: '',
  Control: '',
  Enter: lineTerminator,
  Tab: lineTerminator,
  Shift: '',
  Unidentified: '',
  '\n': lineTerminator,
  '\t': lineTerminator,
};

const codeMapCS: CodeMap = {
  '=': '-',
  '+': '1',
  ě: '2',
  š: '3',
  č: '4',
  ř: '5',
  ž: '6',
  ý: '7',
  á: '8',
  í: '9',
  é: '0',
  '"': ':',
};

const codeMapEN: CodeMap = {
  '/': '-',
  '!': '1',
  '@': '2',
  '#': '3',
  $: '4',
  '%': '5',
  '^': '6',
  '&': '7',
  '*': '8',
  '(': '9',
  ')': '0',
};

export const defaultOptions = {
  startChar: undefined,
  endChar: undefined,
  controlSequenceCount: 0,
  controlSequenceTimeoutInMs: 100,
  avgTimeByChar: 100,
  minLength: 3,
  maxKeyLength: 1,
  codeMap: {
    ...codeMapCS,
    ...codeMapEN,
    ...codeMapControls,
  },
};

export const zebraOptions = {
  ...defaultOptions,
  startChar: '-',
  endChar: '-',
  controlSequenceCount: 3,
};

const scannerMachineOptions = {
  [ScannerMachineMode.ZEBRA]: zebraOptions,
};

export const getScannerMachineOption = (name: string): Options | undefined =>
  propOr(undefined, name, scannerMachineOptions);
