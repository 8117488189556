import {
  ArrowBack,
  AssignmentTurnedIn as CompleteIcon,
  MailOutline as MailIcon,
  MoreVert as MenuIcon,
  SpeakerNotes as NotesIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { AppBar, Badge, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useModalState } from '../../../hooks/modalState';
import { useUseCaseDefinition } from '../../../hooks/useCaseDefinition';
import { Transaction, TransactionType, transactionTypeTexts } from '../../../model';
import { Theme } from '../../../theme';
import TransactionThemeProvider from '../../common/TransactionThemeProvider';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import { useSettingsState } from '../../store/settings/selectors';

const useStyles = makeStyles(({ spacing, breakpoints, typography }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'white',
    padding: spacing(0.5),
  },
  texts: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  titleNumber: {
    [breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  titleNumberDefault: {
    [breakpoints.down('xs')]: {
      fontSize: typography.h5,
    },
  },
}));

type Props = StandardProps & {
  processId: Transaction['processId'];
  customMsg: Transaction['customMsg'];
  error?: boolean;
  isReadOnly: boolean;
  isComplete: boolean;
  title?: string;
  canDelete: boolean;
  onBackClick: (event: any) => void;
  onOptionsInterruptClick: (event: any) => void;
  onOptionsSuspendClick: (event: any) => void;
  onEditNoteClick: () => void;
  deleteTransaction: () => void;
  onHtmlDialog: (instruction: string) => void;
};

const TransactionHeader: React.FC<Props> = ({
  processId,
  customMsg,
  title = '',
  error,
  isReadOnly,
  isComplete,
  canDelete,
  onBackClick,
  onOptionsInterruptClick,
  onOptionsSuspendClick,
  onEditNoteClick,
  deleteTransaction,
  onHtmlDialog,
  ...others
}) => {
  const classes = useStyles({});

  const { showMenuInTransactionDetail } = useSettingsState();
  const useCaseDefinition = useUseCaseDefinition(processId);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const handleHtmlDialog = useCallback(() => {
    onHtmlDialog(customMsg!);
  }, [onHtmlDialog, customMsg]);

  const handleEditNoteClick = useCallback(() => {
    onEditNoteClick();
  }, [onEditNoteClick]);

  const { open: deleteModalOpen, onConfirm, onClose, setOpen: setDeleteModalOpen } = useModalState(deleteTransaction);

  const handleDeleteClick = useCallback(() => {
    setDeleteModalOpen(true);
  }, [setDeleteModalOpen]);

  return (
    <>
      <TransactionThemeProvider useCaseDefinition={useCaseDefinition}>
        <AppBar
          data-test="transactionHeader"
          position="static"
          classes={{ root: classNames(classes.root, others.className) }}
        >
          <IconButton
            data-test={'Zpet'}
            onClick={onBackClick}
            color={'inherit'}
            title="Zpět"
            //data-test="backToTransactions"
            aria-label="back"
            size="large"
          >
            <ArrowBack />
          </IconButton>
          <div className={classes.texts}>
            <Typography variant="subtitle2" data-test="transactionType">
              {useCaseDefinition.name || transactionTypeTexts[TransactionType.UNKNOWN]}
            </Typography>
            <Typography
              data-test="transactionCode"
              variant="h5"
              className={title.length < 10 ? classes.titleNumber : classes.titleNumberDefault}
            >
              {title}
            </Typography>
          </div>

          {customMsg && (
            <IconButton onClick={handleHtmlDialog} color="inherit" component="span" size="large">
              <MailIcon />
            </IconButton>
          )}

          {error && (
            <Tooltip title="Doklad má chybový stav" enterTouchDelay={0} leaveTouchDelay={2500}>
              <WarningIcon color="action" />
            </Tooltip>
          )}

          <Tooltip title="Editovat poznámku " enterTouchDelay={0} leaveTouchDelay={2500}>
            <IconButton onClick={handleEditNoteClick} color={'inherit'} size="large">
              <Badge
                overlap="rectangular"
                color="error"
                variant="dot"
                invisible={!useCaseDefinition.processConfig.requireNote}
              >
                <NotesIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          {/*canDelete is there to avoid empty menu. When more items is in the menu, it could be removed */}
          {!isReadOnly && showMenuInTransactionDetail && canDelete && (
            <>
              <IconButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
                color={'inherit'}
                title="Možnosti"
                aria-label="options"
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
                {canDelete && <MenuItem onClick={handleDeleteClick}>Smazat doklad</MenuItem>}
              </Menu>
            </>
          )}
          {isComplete && (
            <IconButton onClick={onBackClick} color={'inherit'} title="Uzavřít" size="large">
              <CompleteIcon />
            </IconButton>
          )}
        </AppBar>
      </TransactionThemeProvider>
      {deleteModalOpen && (
        <ConfirmDialog
          title="Opravdu chcete smazat doklad?"
          instruction="Tato operace je nevratná a veškerá data budou ztracena"
          actionButton="Smazat doklad"
          cancelButton="Zrušit"
          onConfirm={onConfirm}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default TransactionHeader;
