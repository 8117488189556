import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Tag';
import { Button, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { propOr } from 'ramda';
import { isNonEmptyString } from 'ramda-adjunct';
import React, { useCallback, useContext, useState } from 'react';
import { calculateIncreaseQuantity } from '../../../helpers/transactionItem';
import { useModalState } from '../../../hooks/modalState';
import { useProcessConfig } from '../../../hooks/useCase';
import { TransactionItem } from '../../../model';
import { ScannerContext } from '../../../receiver/scanner/Scanner';
import { Theme } from '../../../theme';
import PlusMinusCounter from '../../common/PlusMinusCounter';
import ConfirmDialog from '../../dialogs/ConfirmDialog';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: spacing(1),
    padding: spacing(0, 1),
    [breakpoints.down('xs')]: {
      padding: spacing(0, 0.5),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    paddingTop: spacing(0.5),
    '& > *': {
      flex: 1,
    },
  },
}));

type Props = StandardProps & {
  transactionItem: TransactionItem;
  onPlus: (amount: number) => void;
  onMinus: (amount: number) => void;
  onAmountChange: (amount: number) => void;
  onDelete: (code: string) => void;
};

const ItemCounter: React.FC<Props> = ({ transactionItem, onPlus, onMinus, onAmountChange, onDelete, ...others }) => {
  const classes = useStyles();
  const [amountToChange, setAmountToChange] = useState<string>('');
  const { onInput } = useContext(ScannerContext);
  const { isOpen, isCountOverrides, tracking } = useProcessConfig();

  const onPlusClick = () => onPlus(calculateIncreaseQuantity(transactionItem, isCountOverrides, 1));
  const onMinusClick = () => onMinus(1);
  const onAllAmountConfirm = () => onAmountChange(propOr(0, 'amount', transactionItem));

  const onDeleteFunction = useCallback(() => {
    onDelete(transactionItem.product.code);
  }, [onDelete, transactionItem.product.code]);

  const { open: deleteModalOpen, onConfirm, onClose, setOpen: setDeleteModalOpen } = useModalState(onDeleteFunction);

  const onDeleteConfirm = useCallback(() => {
    setDeleteModalOpen(true);
  }, [setDeleteModalOpen]);

  const onAmountConfirm = useCallback(() => {
    if (isNonEmptyString(amountToChange)) {
      const float = parseFloat(amountToChange);
      if (float > 0) {
        onAmountChange(float);
      }
    } else {
      onAmountChange(0);
    }
  }, [amountToChange, onAmountChange]);

  const {
    open: amountModalOpen,
    onConfirm: confirmNewAmount,
    onClose: closeNewAmountModal,
    setOpen: setAmountModalOpen,
  } = useModalState(onAmountConfirm);

  const handleOpenAmountDialog = useCallback(() => {
    setAmountModalOpen(true);
  }, [setAmountModalOpen]);

  const canSetAmount = !(
    (transactionItem.product.trackSerialNumbers ||
      transactionItem.product.trackBatches ||
      transactionItem.product.trackExpirations) &&
    tracking
  );

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <PlusMinusCounter
        amount={transactionItem.amountCompleted}
        unit={transactionItem.product.measurementUnit}
        isOpen={isOpen}
        onPlus={onPlusClick}
        onMinus={onMinusClick}
      />
      <div className={classes.actions}>
        {isOpen && (
          <Button
            data-test="removeItemButton"
            variant="contained"
            color="error"
            size="large"
            onClick={onDeleteConfirm}
            startIcon={<Delete fontSize="large" />}
          >
            Odebrat
          </Button>
        )}
        {!isOpen && canSetAmount && (
          <Button
            data-test="insertProductsButton"
            variant="contained"
            color="primary"
            size="large"
            onClick={onAllAmountConfirm}
          >
            Vložit vše
          </Button>
        )}
        {canSetAmount && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleOpenAmountDialog}
            startIcon={<Add fontSize="large" />}
          >
            Množství
          </Button>
        )}
      </div>

      {deleteModalOpen && (
        <ConfirmDialog
          title={`Opravdu chcete odebrat produkt "${transactionItem.product.name}" z dokladu?`}
          actionButton="Odebrat produkt"
          onConfirm={onConfirm}
          onClose={onClose}
        />
      )}
      {amountModalOpen && (
        <ConfirmDialog
          title={`Zadejte požadované množství`}
          actionButton="Vložit množství"
          onConfirm={confirmNewAmount}
          onClose={closeNewAmountModal}
        >
          <TextField
            autoFocus
            data-test={'AmountOfPieces'}
            color="primary"
            type="number"
            fullWidth
            value={amountToChange}
            onInput={(event) => {
              onInput(event.nativeEvent as KeyboardEvent);
            }}
            error={isNonEmptyString(amountToChange) && parseFloat(amountToChange) <= 0}
            onChange={(e) => {
              setAmountToChange(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{transactionItem.product.measurementUnit ?? 'ks'}</InputAdornment>
              ),
            }}
            helperText="Vložte kladné číslo"
          />
        </ConfirmDialog>
      )}
    </div>
  );
};

export default ItemCounter;
