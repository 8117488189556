import { isNotNil } from 'ramda-adjunct';
import { openTransaction } from '../stateDefinitionOptions';
import { ProcessConfig, ScannerViewContext, StateNode } from '../types';

const isFound = (_: ScannerViewContext, event) => isNotNil(event.data);

export function findProduct(options: ProcessConfig): StateNode {
  return openTransaction(options)
    ? {
        invoke: {
          src: 'findProduct',
          onDone: [
            {
              cond: isFound,
              target: 'itemToAdd',
              actions: ['handleSearchResult', 'clearValidEANs'],
            },
            { target: 'unknownItem', actions: 'notifyError' },
          ],
          onError: { target: 'findProductError', actions: ['notifyError'] },
        },
      }
    : {
        always: {
          target: 'unknownItemInOrder',
          actions: 'notifyError',
        },
      };
}
