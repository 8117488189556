import { ButtonBase, ImageListItemBar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { Tile } from '../../../model';
import { Theme } from '../../../theme';

type Props = StandardProps & {
  tile: Tile;
  onClick: (event: any) => void;
};

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    justifyContent: 'flex-end',
  },

  tileButton: {},

  iconImage: {
    margin: `auto ${spacing(1)}`,
    height: 25,
  },

  label: {
    textAlign: 'left',
    margin: 'auto',
    top: '0',
    color: palette.common.white,
    background: 'none',
    '& .MuiImageListItemBar-title': {
      maxWidth: '80%',
    },
  },
}));

const TileMenuItem: React.FC<Props> = ({ tile, onClick, ...others }) => {
  const classes = useStyles({});

  return (
    <ButtonBase
      {...others}
      className={classNames(classes.root, others.className)}
      onClick={onClick}
      style={{ background: tile.color }}
    >
      <ImageListItemBar
        className={classes.label}
        title={tile.label}
        actionIcon={<img className={classes.iconImage} src={tile.icon} alt="Document type illustration" />}
      />
    </ButtonBase>
  );
};

export default TileMenuItem;
