import DOMPurify from 'dompurify';
import { isString } from 'ramda-adjunct';
import React, { ReactElement } from 'react';
import ReactHtmlParser from 'react-html-parser';
import CustomDialog, { CustomDialogProps } from './CustomDialog';

export type HtmlDialogProps = Omit<CustomDialogProps, 'instruction'> & {
  instruction?: string | ReactElement[];
};

const HtmlDialog: React.FC<HtmlDialogProps> = (props) => {
  return (
    <CustomDialog
      {...props}
      instruction={
        isString(props.instruction) ? ReactHtmlParser(DOMPurify.sanitize(props.instruction)) : props.instruction
      }
    />
  );
};

export default HtmlDialog;
