import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  startButton: {
    borderRadius: 0,
  },
}));

type Props = StandardProps & {
  title: string;
  onClick: (event: any) => void;
};

const ActionButton: React.FC<Props> = ({ title, onClick, ...others }) => {
  const classes = useStyles();

  return (
    <div {...others} className={others.className}>
      <Button
        className={classes.startButton}
        fullWidth
        color="primary"
        variant="contained"
        size="large"
        onClick={onClick}
      >
        {title}
      </Button>
    </div>
  );
};

export default ActionButton;
