import { assoc, filter, keys, propEq } from 'ramda';
import { isArray, reduceIndexed } from 'ramda-adjunct';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppCustomError, UseCaseError } from '../app/errors/errors';
import { UseCaseDefinition, UseCaseDefinitions } from '../model';
import { getToken } from '../service/authService';

export type AmountInEanConfig = {
  decimal: number;
  integer: number;
  length: number;
  prefix: string;
  ignoredDigits: number;
};

export type AppConfigContextType = {
  useCaseDefinitions: UseCaseDefinitions;
  visibleUseCaseDefinitions: UseCaseDefinitions;
  amountInEanConfig: AmountInEanConfig[];
};

const transformDefinitionArrayToDefinitionObject = (definitionArray: any[]) =>
  reduceIndexed(
    (acc, item: UseCaseDefinition, index) =>
      assoc(
        item.processId,
        {
          ...item,
          order: index,
        },
        acc
      ),
    {},
    definitionArray
  );

const filterVisibleUseCaseDefinitions = (definitionArray: any[]) => filter(propEq('visible', true))(definitionArray);

export const AppConfigContext = React.createContext<AppConfigContextType>(undefined as unknown as AppConfigContextType);

export const AppConfigProvider = ({ apiUrl, children }: any) => {
  const [appConfig, setAppConfig] = useState<AppConfigContextType>(undefined as unknown as AppConfigContextType);
  const [error, setError] = useState<string | undefined>(undefined);
  const getUseCaseDefinitions = useCallback(async (): Promise<UseCaseDefinitions[]> => {
    try {
      const response = await fetch(`${apiUrl}/app/auth/use-case-definition`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();
      if (data && isArray(data)) {
        return data;
      } else {
        setError('UseCase response definition error');
      }
    } catch (error) {
      setError('UseCase request error');
    }
    return [];
  }, [apiUrl]);

  const getAmountInEanConfig = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/app/auth/amount-in-ean-config`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response.status !== 200 || !response.ok) {
        setError(
          'Definice pro načtení množství z naskenovaného kódu není k dispozici. Zkontrolujte nastavení v Dativery a zkuste to znovu'
        );
      }
      const data = await response.json();
      if (data) {
        return data;
      } else {
        return [];
      }
    } catch (error) {
      setError('Amount in EAN request error');
    }
    return [];
  }, [apiUrl]);

  useEffect(() => {
    const doTheAsyncThing = async () => {
      const [useCaseDefinition, amountInEanConfig] = await Promise.allSettled([
        getUseCaseDefinitions(),
        getAmountInEanConfig(),
      ]);
      if (useCaseDefinition.status !== 'rejected' && amountInEanConfig.status !== 'rejected') {
        setAppConfig({
          useCaseDefinitions: transformDefinitionArrayToDefinitionObject(useCaseDefinition.value),
          visibleUseCaseDefinitions: transformDefinitionArrayToDefinitionObject(
            filterVisibleUseCaseDefinitions(useCaseDefinition.value)
          ),
          amountInEanConfig: amountInEanConfig.value,
        });
      }
    };
    //todo - check for error
    doTheAsyncThing();
  }, [getUseCaseDefinitions, getAmountInEanConfig]);

  if (error) {
    throw new AppCustomError('Error: AppConfig error: ', error);
  }

  if (appConfig && keys(appConfig).length === 0) {
    throw new UseCaseError('No property: appConfig');
  }

  return appConfig && <AppConfigContext.Provider value={appConfig}>{children}</AppConfigContext.Provider>;
};

export const useUseCaseDefinition = (processId: string): UseCaseDefinition => {
  const { useCaseDefinitions } = useContext(AppConfigContext);
  return useCaseDefinitions[processId] || {};
};

export const useAmountInEanConfig = () => {
  const { amountInEanConfig } = useContext(AppConfigContext);
  return amountInEanConfig;
};
