import { Switch } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppSettingKeys, setSettings } from '../store/settings/appSettings';
import { useSettingsState } from '../store/settings/selectors';

export interface SettingSwitchProps {
  settingsKey: AppSettingKeys;
}

const SettingSwitch: React.FC<SettingSwitchProps> = ({ settingsKey, ...props }) => {
  const dispatch = useDispatch();

  const appSettingValue = useSettingsState()[settingsKey] as boolean;
  const [valueSwitch, setValueSwitch] = React.useState(appSettingValue);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      setValueSwitch(value);
      dispatch(setSettings({ [settingsKey]: value }));
    },
    [settingsKey, dispatch]
  );

  return <Switch checked={valueSwitch} onChange={handleChange} inputProps={{ 'aria-label': 'primary checkbox' }} />;
};

export default SettingSwitch;
