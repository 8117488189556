import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isNonEmptyArray, isNonEmptyString } from 'ramda-adjunct';
import React, { useContext, useMemo, useState } from 'react';
import { useDB } from 'react-pouchdb';
import { useRouter } from 'react-router5';
import { AppConfigContext } from '../../hooks/useCaseDefinition';
import { Transaction, UseCaseDefinition, User } from '../../model';
import { createNewTransaction } from '../../service/transactionService';
import { useLocalStorage } from '../hooks/useLocalStorage';
import ListFooter from './ListFooter/ListFooter';
import ListHeader from './ListHeader/ListHeader';
import { getSortingFunction, sortDefinitions, SortType } from './sorting';
import TransactionsList from './transactionsList/TransactionsList';

type Props = StandardProps & {
  title?: string;
  user: User;
  onTransactionClick: (transaction: Transaction) => void;
  onLogout: () => void;
  transactions: Transaction[];
};

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ListView: React.FC<Props> = ({ user, title, onLogout, onTransactionClick, transactions, ...others }) => {
  const db = useDB();
  const router = useRouter();
  const { visibleUseCaseDefinitions } = useContext(AppConfigContext);
  const [sortType, setSortType] = useLocalStorage<SortType>('LIST_SORT', SortType.BY_STATE_AND_TIME);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredProcessId, setProcessId] = useState<string[]>([]);

  const filteredTransactions: Transaction[] = useMemo(() => {
    const visibleUseCaseDefinitionKeys = Object.keys(visibleUseCaseDefinitions);
    //filter visible only
    let filtered: Transaction[] = transactions.filter((transaction) =>
      visibleUseCaseDefinitionKeys.includes(transaction.processId)
    );

    //filter by filter
    if (isNonEmptyArray(filteredProcessId)) {
      filtered = filtered.filter((item) => filteredProcessId.includes(item.processId!));
    }

    // filter by search text
    if (isNonEmptyString(searchText)) {
      filtered = filtered.filter((transaction) =>
        transaction.displayName?.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return filtered;
  }, [transactions, filteredProcessId, searchText, visibleUseCaseDefinitions]);

  const sortedTransactions: Transaction[] = useMemo(() => {
    const transactionSort = getSortingFunction(sortType);
    return transactionSort(filteredTransactions);
  }, [filteredTransactions, sortType]);

  const classes = useStyles({});

  const onGenerateTransactionClick = async (useCaseDefinition: UseCaseDefinition) => {
    const transactionId = await createNewTransaction(db, user, useCaseDefinition);
    router.navigate('app.transaction.detail', { id: encodeURIComponent(transactionId) });
  };

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <ListHeader
        title={title}
        sort={sortType}
        sortDefinitions={sortDefinitions}
        onSortChange={setSortType}
        user={user}
        onLogout={onLogout}
        searchText={searchText}
        setSearchText={setSearchText}
        handleFilter={setProcessId}
      />
      <TransactionsList searchText={searchText} onItemClick={onTransactionClick} items={sortedTransactions} />
      <ListFooter onGenerateTransactionClick={onGenerateTransactionClick} />
    </div>
  );
};

export default ListView;
