import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useProcessConfig } from '../../../hooks/useCase';
import { User } from '../../../model';
import ActionButton from '../../common/ActionButton';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import ActionContainer from '../actionContainer/ActionContainer';
import EnterProductCodeButtonWithModal from '../productCodeModal/EnterProductCodeButtonWithModal';
import { State, stateComplete, stateReadOnlyView } from '../stateMachine';

const useStyles = makeStyles(() => ({
  buttonComplete: {
    marginLeft: '5px',
  },
}));

type Props = StandardProps & {
  user: User;
  state: State;
  eventComplete: (event: any) => void;
  eventEnterCode: (event: any) => void;
  eventStart: (event: any) => void;
  handleBackClick: (event: any) => void;
  setAllowMessage: (value: boolean) => void;
};

const TransactionActionContainer: React.FC<Props> = ({
  user,
  state,
  eventComplete,
  eventEnterCode,
  eventStart,
  handleBackClick,
  setAllowMessage,
  ...others
}) => {
  const classes = useStyles({});
  const { isOpen, isCountOverrides } = useProcessConfig();
  const [showDialog, setShowDialog] = useState(false);

  const handleLeaveDialogClose = () => setShowDialog(false);

  const handleEventComplete = (event: any) => {
    if (!isOpen && isCountOverrides) {
      setShowDialog(true);
      return;
    }
    eventComplete(event);
  };

  return (
    <ActionContainer>
      {/* TODO conditions for events should be based on statemachine events (state.nextEvents), not state */}
      {stateReadOnlyView(state) && <ActionButton data-test="start" title="Start" onClick={() => eventStart(user)} />}
      {stateComplete(state) && <ActionButton title="Zavřít doklad" onClick={handleBackClick} />}
      {!(stateReadOnlyView(state) || stateComplete(state)) && (
        <Grid container spacing={0}>
          <Grid item xs>
            <EnterProductCodeButtonWithModal
              onConfirm={eventEnterCode}
              onClick={() => setAllowMessage(false)}
              onClose={() => setAllowMessage(true)}
            />
          </Grid>
          {(isOpen || isCountOverrides) && (
            <Grid item xs className={classes.buttonComplete}>
              <ActionButton title="Dokončit" onClick={handleEventComplete} data-test="dokonceni" />
            </Grid>
          )}
        </Grid>
      )}
      {showDialog && (
        <ConfirmDialog
          title="Opravdu chcete dokončit doklad?"
          instruction="Po potvrzení nelze dokument znovu editovat a bude odstraněn ze seznamu dokumentů"
          actionButton="ANO"
          cancelButton="NE"
          onConfirm={() => eventComplete({})}
          onClose={handleLeaveDialogClose}
        />
      )}
    </ActionContainer>
  );
};

export default TransactionActionContainer;
