import { ErrorOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { Theme } from '../../theme';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  root: {
    borderTop: '2px solid',
    borderColor: palette.error.main,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${palette.error.light}0F`,
    minHeight: '208px',
  },
  errorIcon: {
    fontSize: '6rem',
    margin: 'auto',
  },
  header: {
    textAlign: 'center',
    marginTop: spacing(1),
    marginBottom: spacing(1),
    color: palette.error.main,
    fontWeight: 'bold',
  },

  actionButton: {
    margin: `auto 0 ${spacing(1)}`,
  },
}));

type ErrorFallbackProps = {
  error: Error & {
    customMessage?: string;
    actionButtonText?: string;
    url?: string;
    resetBoundary?: boolean;
  };
  resetError(): void;
};

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error: { customMessage = 'Něco se nepovedlo', actionButtonText = 'Přejít na úvod', url = '/', resetBoundary },
  resetError,
}) => {
  const classes = useStyles();
  const handleClick = useCallback(() => {
    if (resetBoundary) {
      resetError();
    } else {
      window.location.replace(url);
    }
  }, [url, resetBoundary, resetError]);

  return (
    <div className={classes.root}>
      <ErrorOutline className={classes.errorIcon} color="error" />

      <Typography className={classes.header} variant="h5">
        {customMessage}
      </Typography>
      <Button className={classes.actionButton} size="large" variant="contained" onClick={handleClick} color="primary">
        {actionButtonText}
      </Button>
    </div>
  );
};

export default ErrorFallback;
