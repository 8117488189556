import { Box, Divider, ListItem, ListItemIcon, Theme, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import AlertIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { formatDistanceToNowStrict } from 'date-fns';
import { cs } from 'date-fns/locale';
import { isNil } from 'ramda';
import { isEmptyString } from 'ramda-adjunct';
import React, { useCallback, useMemo, useState } from 'react';
import { Transaction } from '../../../model';
import { AppSettingKeys, AppSettings } from '../../store/settings/appSettings';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    padding: spacing(0.5, 0.5, 0.5, 1.5),
  },
  suppressed: {
    backgroundColor: grey[300],
  },
  active: {
    backgroundColor: green[50],
  },
  docTypeIndicator: {
    width: 3,
    height: '100%',
    borderColor: (props) => props.palette.primary.main,
    backgroundColor: (props) => props.palette.primary.main,
  },
  listIcon: {
    flexDirection: 'column',
    minWidth: 'auto',
    marginRight: spacing(1),
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rowContent: {
    flexGrow: 1,
    display: 'flex',
    '&>*': {
      flex: '1 0 auto',
    },
  },
  transactionType: {
    color: (props) => props.palette.primary.main,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  assignee: {
    color: (props) => props.palette.text.primary,
  },
  arrowIcon: {
    color: (props: Theme) => props.palette.text.secondary,
    marginLeft: spacing(1),
  },
  primaryInfo: {},
  secondaryInfo: {
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: spacing(0.5),
    [breakpoints.down(breakpoints.values.zebra)]: {
      maxWidth: 120,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  miscArea: {
    marginTop: spacing(0.5),
  },
}));

type TransactionListPureProps = StandardProps & {
  onClick: (event: any) => void;
  transaction: Transaction;
  error?: boolean;
  errorMsg?: Transaction['responseError'];
  transactionType: string;
  status: string;
  suppressed: boolean;
  active: boolean;
  useCaseTheme: Theme;
  showNote: AppSettings[AppSettingKeys.showNoteInList];
};

export const TransactionsListItemPure: React.FC<TransactionListPureProps> = ({
  transaction,
  transactionType,
  onClick,
  error,
  errorMsg,
  suppressed,
  active,
  useCaseTheme,
  status,
  showNote,
  ...others
}) => {
  const handleClickMemoized = useCallback(() => onClick(transaction), [onClick, transaction]);
  const classes = useStyles(useCaseTheme);
  const { assignee, clientInfo, transNumber, displayName } = transaction;

  const [date, setDate] = useState(''); // todo - refactor and remove E2E tests

  const dateFormatted = useMemo(() => {
    const lastModifiedDate =
      isNil(transaction.lastModifiedDate) || isEmptyString(transaction.lastModifiedDate)
        ? new Date().toISOString()
        : transaction.lastModifiedDate;
    setDate(lastModifiedDate);
    return formatDistanceToNowStrict(new Date(lastModifiedDate), { addSuffix: true, locale: cs });
  }, [transaction.lastModifiedDate]);

  const hasIcon = useMemo(() => error, [error]);
  const showMiscArea = useMemo(() => Boolean(errorMsg) || showNote, [errorMsg, showNote]);

  return (
    <ListItem
      className={classNames(classes.root, {
        [classes.suppressed]: suppressed,
        [classes.active]: active,
      })}
      onClick={handleClickMemoized}
      divider
      {...others}
    >
      <Divider orientation="vertical" absolute className={classes.docTypeIndicator} />

      {hasIcon && <ListItemIcon className={classes.listIcon}>{error && <AlertIcon color="error" />}</ListItemIcon>}
      <Box className={classes.content}>
        <Box className={classes.rowContent}>
          <Box className={classes.primaryInfo}>
            <Typography data-test="transactionType" className={classes.transactionType}>
              {transactionType}
            </Typography>

            <Typography variant={'h4'} data-test="transactionCode" data-test-value={transNumber}>
              {displayName ? displayName : transNumber}
            </Typography>

            {clientInfo && (
              <Typography variant={'subtitle2'} color="textSecondary">
                {clientInfo}
              </Typography>
            )}
          </Box>

          <Box className={classes.secondaryInfo}>
            <Typography data-test="transactionType" className={classes.transactionType}>
              {status}
            </Typography>
            {assignee?.username && <Typography className={classes.assignee}>{assignee.username}</Typography>}
            <Typography data-test="lastModified" data-test-value={date}>
              {dateFormatted}
            </Typography>
          </Box>
        </Box>
        {showMiscArea && (
          <Box className={classes.miscArea}>
            {errorMsg && (
              <Typography color="error" variant="subtitle2">
                {errorMsg.substring(0, 42)}
              </Typography>
            )}
            {showNote && transaction.note && (
              <Typography variant={'body2'}>Pozn: {transaction.note.substring(0, 42)}</Typography>
            )}
          </Box>
        )}
      </Box>
    </ListItem>
  );
};
