import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import serviceWorker from './serviceWorker';
import settings from './settings/appSettings';
import scanner from './scanner';

export const store = configureStore({
  reducer: combineReducers({
    serviceWorker,
    settings,
    scanner,
  }),
  middleware: getDefaultMiddleware(),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
