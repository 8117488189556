import { StateNode, ProcessConfig } from '../types';

/**
 * handle required events before saving transaction ordered by priority
 */
// todo - add complete confirm dialog
export function transactionBeforeComplete(options: ProcessConfig): StateNode {
  return {
    always: [
      { target: 'noteEdit', cond: 'handleNoteRequirements', actions: 'setSaveAndCompleteNoteDialogAction' },
      { target: 'complete' },
    ],
  };
}
