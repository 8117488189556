import React, { useCallback, useContext } from 'react';
import { includes } from 'ramda';

export const XStateContext = React.createContext<any>({});

export const XStateProvider = ({ fsm, send, children }: any) => {
  const canDo = useCallback(
    (event: string) => {
      return includes(event, fsm.nextEvents);
    },
    [fsm.nextEvents]
  );

  const value = {
    fsm: fsm,
    send: send,
    canDo,
  };

  return <XStateContext.Provider value={value}>{children}</XStateContext.Provider>;
};

export const XStateConsumer = XStateContext.Consumer;

export const useXState = () => useContext(XStateContext);
