import React from 'react';

export interface AppConfig {
  REACT_APP_API_URL: string;
  REACT_APP_SENTRY_URL: string;
  REACT_APP_ENVIRONMENT: 'local' | 'staging' | 'production';
  REACT_APP_MATOMO_URL_BASE: string;
  REACT_APP_MATOMO_SITE_ID: number;
}

function makeAbsoluteUrl(config: AppConfig) {
  if (config.REACT_APP_API_URL.startsWith('/')) {
    config.REACT_APP_API_URL = window.location.origin + config.REACT_APP_API_URL;
  }

  return config;
}

export function loadAppConfig(): Promise<AppConfig> {
  return fetch('/config.json')
    .then((response) => response.json())
    .then(makeAbsoluteUrl);
}

export const AppConfigContext = React.createContext<AppConfig>({} as AppConfig);
