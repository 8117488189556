import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useSettingsState } from '../../app/store/settings/selectors';
import { getScannerMachineOption, ScannerMachineType } from './scannerMachineOptions';
import { fastMachineFactory, State } from './stateMachine-fast';

type Props = {
  onReceive: (code: string) => void;
  children?: React.ReactNode;
};

type ScannerContextType = {
  onInput: (event: KeyboardEvent) => void;
};

export const ScannerContext = React.createContext<ScannerContextType>({} as ScannerContextType);

const Scanner: React.FC<Props> = ({ children, onReceive }) => {
  const { scannerMachineMode, scannerMachineType } = useSettingsState();

  const receiver = useMemo(
    () => fastMachineFactory(onReceive, getScannerMachineOption(scannerMachineMode)),
    [onReceive, scannerMachineMode]
  );
  const disabledKeydown = useSelector((store: RootState) => store.scanner.disabledKeydown);

  const readKeyboard = useCallback(
    (event: KeyboardEvent | InputEvent) => {
      const key = (event as KeyboardEvent).key || (event as InputEvent).data || '';
      if (receiver.state.value !== State.waitingForChars && receiver.state.value !== State.receivingWithoutStartSeq) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
      }

      receiver.send({ type: 'KEYPRESS', key });
    },
    [receiver]
  );

  const readInput = useCallback(
    (event) => {
      const data = event.data || '';
      [...data].forEach((key: string) => receiver.send({ type: 'KEYPRESS', key }));
    },
    [receiver]
  );

  const focusScannerInput = useCallback(() => {
    // @ts-ignore - element vs input
    document.querySelector('[data-disable-touch-keyboard]')?.focus();
  }, []);

  useEffect(() => {
    const scannerInput = document.querySelector('[data-disable-touch-keyboard]');

    if (!disabledKeydown) {
      if (scannerMachineType === ScannerMachineType.DEFAULT) {
        document.addEventListener('keydown', readKeyboard);
      } else {
        scannerInput?.addEventListener('blur', focusScannerInput);
        scannerInput?.addEventListener('textInput', readInput);
      }
    }
    return () => {
      document.removeEventListener('keydown', readKeyboard);
      scannerInput?.removeEventListener('blur', focusScannerInput);
      scannerInput?.removeEventListener('textInput', readInput);
    };
  }, [disabledKeydown, readKeyboard, receiver, scannerMachineType, focusScannerInput, readInput]);

  return (
    <ScannerContext.Provider
      value={{
        onInput: readKeyboard,
      }}
    >
      {children}
    </ScannerContext.Provider>
  );
};

export default Scanner;
