import { Button, SvgIconProps } from '@mui/material';
import Keyboard from '@mui/icons-material/Keyboard';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
    whiteSpace: 'nowrap',
  },
}));

type Props = StandardProps & {
  onClick: (event: any) => void;
  buttonText?: string;
  buttonTitle?: string;
  iconProps?: SvgIconProps;
};

const EnterProductCodeButton: React.FC<Props> = ({
  onClick,
  buttonText = 'Hledat',
  buttonTitle = 'Zadat kód manuálně',
  iconProps,
  ...others
}) => {
  const classes = useStyles();
  return (
    <Button
      data-test="ZadatKod"
      onClick={onClick}
      color="primary"
      variant="contained"
      size="large"
      fullWidth
      className={classNames(classes.root, others.className)}
      title={buttonTitle}
      startIcon={<Keyboard fontSize="large" {...iconProps} />}
    >
      {buttonText}
    </Button>
  );
};

export default EnterProductCodeButton;
