import { createAction, createReducer } from '@reduxjs/toolkit';

const name = 'serviceWorker';

export const setAppUpdated = createAction(`${name}/update`);

const initialState = {
  serviceWorkerUpdated: false,
};

export default createReducer(initialState, {
  [setAppUpdated.type]: (state) => ({
    ...state,
    serviceWorkerUpdated: true,
  }),
});
