import { isEmpty } from 'ramda';
import { Product, WarehouseState } from '../../model';
import { HandlePosition, ProcessConfig } from './stateMachine';

export const formatAmount = (amount: string | number = 0): string => {
  return parseFloat(parseFloat(amount.toString()).toFixed(6)).toString();
};

export function productHasDetailInfo(product: Product, warehouseState: WarehouseState, processConfig: ProcessConfig) {
  return (
    (product.description && product.description.length > 1) ||
    (product.imageSource && product.imageSource.length > 1) ||
    (warehouseState.position && !isEmpty(warehouseState.position)) ||
    processConfig.handlePosition !== HandlePosition.NEVER
  );
}
