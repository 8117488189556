import React, { useContext } from 'react';
import { useRoute } from 'react-router5';
import { AppConfigContext } from '../../config';
import { useAnalytics } from '../../hooks/analytics';
import { AppConfigProvider } from '../../hooks/useCaseDefinition';
import LoginView from '../loginView/LoginView';
import { AppRouterComponent } from './AppRouterComponent';

export function RouterComponent() {
  const { route, router } = useRoute();
  const { REACT_APP_API_URL } = useContext(AppConfigContext);
  const { trackPageView } = useAnalytics();

  // tracking all user movement
  trackPageView();

  if (route.name.startsWith('app')) {
    return (
      <AppConfigProvider apiUrl={REACT_APP_API_URL}>
        <AppRouterComponent />
      </AppConfigProvider>
    );
  } else {
    return <LoginView onLogin={() => router.navigate('app')} />;
  }
}
