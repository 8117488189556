import { TransactionItem } from '../model';

// pokud je množství např 6,26ks, tak poslední přičtení nesmí přidat 1ks ale jen 0,26ks
// pokud chybí amount, tak to znamená že je to doklad volný a vždy se přičtě 1
export const calculateIncreaseQuantity = (
  { amount, amountCompleted }: TransactionItem,
  hasCountOverrides: boolean,
  amountToChange: number = 1
) => {
  if (hasCountOverrides) {
    return amountToChange;
  }
  if (amount) {
    const missingQuantity = amount - amountCompleted;
    // pokud není fixní počet, může přijít missingQuantity 0
    if (missingQuantity !== 0 || !hasCountOverrides) {
      return missingQuantity >= 0 && missingQuantity < amountToChange ? missingQuantity : amountToChange;
    }
  }
  return amountToChange;
};

export const getCustomMsg = (item: TransactionItem) => {
  let customMsg = item.customMsg || '';
  if (item.product.customMsg) {
    customMsg = item.customMsg ? `${customMsg}<hr>${item.product.customMsg}` : item.product.customMsg;
  }
  return customMsg;
};
