import { Box, List, Typography } from '@mui/material';
import AlertIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { ascend, descend, pathOr, propEq, sortWith } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { Transaction } from '../../../model';
import { Theme } from '../../../theme';
import { hasItemRequiredAmount } from '../guards';
import TransactionItem from './TransactionItem';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  list: {
    textAlign: 'center',
    paddingBottom: spacing(8),
  },
  legend: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(0, 1),
    [breakpoints.down('xs')]: {
      padding: spacing(0.5),
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(1),
  },
  checkButton: {
    display: 'inline-block',
    width: '140px',
    height: '140px',
    color: '#FFFFFF',
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: spacing(6),
    margin: spacing(1, 0),
    '&>*': {
      margin: spacing(0, 0.5),
    },
  },
}));

type Props = StandardProps & {
  currentItemId?: number;
  error?: Transaction['responseError'];
  onAmountChange: (event: any) => void;
  onDelete: (code: string) => void;
  onHtmlDialog: (instruction: string, productId?: number) => void;
  onMinus: (event: any) => void;
  onPlus: (event: any) => void;
  title: string;
  transaction: Transaction;
};

const TransactionDetail: React.FC<Props> = ({
  currentItemId,
  error,
  onAmountChange,
  onDelete,
  onHtmlDialog,
  onMinus,
  onPlus,
  title,
  transaction: { items, transactionType },
  ...others
}) => {
  const classes = useStyles({});

  const isCurrent = useCallback((item) => propEq('id', currentItemId, item), [currentItemId]);
  const sort = useCallback(
    (list) => sortWith([descend(isCurrent), ascend(hasItemRequiredAmount), ascend(pathOr(0, ['order']))], list),
    [isCurrent]
  );
  const sortedItems = useMemo(() => sort(items), [sort, items]);

  return (
    <div {...others} className={classNames(classes.root, others.className)} data-test="transactionItem">
      {error && (
        <Box className={classes.errorBox}>
          <AlertIcon color="error" />
          <Box>
            <Typography color="error" variant="subtitle2" paragraph>
              {error}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Zkuste doklad znovu dokončit pro opakování akce
            </Typography>
          </Box>
        </Box>
      )}
      <List className={classes.list}>
        <div className={classes.legend}>
          <Typography variant="subtitle1">Položka</Typography>
          <Typography variant="subtitle1">Načteno / očekáváno</Typography>
        </div>
        {sortedItems.map((item) => (
          <TransactionItem
            data-test="transactionProduct"
            item={item}
            isCurrent={isCurrent(item)}
            key={item.product.code}
            onPlus={onPlus}
            onMinus={onMinus}
            onAmountChange={onAmountChange}
            onDelete={onDelete}
            onHtmlDialog={onHtmlDialog}
          />
        ))}
      </List>
    </div>
  );
};

export default TransactionDetail;
