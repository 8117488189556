import { mergeRight } from 'ramda';
import { defaultOptions, lineTerminator, Options } from './scannerMachineOptions';

export type EventKeyPress = { type: 'KEYPRESS'; key: string };

export enum State {
  waitingForChars = 'WAITING_FOR_CHARS',
  startSeq = 'START_SEQ',
  receivingWithoutStartSeq = 'RECEIVING_WITHOUT_START_SEQ',
  receiving = 'RECEIVING',
  endSeq = 'END_SEQ',
}

declare function setTimeout(handler: TimerHandler, timeout?: number, ...arguments: any[]): number;

export const fastMachineFactory = function (onCodeReceived: (code: string) => void, options: Partial<Options> = {}) {
  const currentOptions = mergeRight(defaultOptions, options);
  const defaultState = !currentOptions.startChar ? State.receivingWithoutStartSeq : State.waitingForChars;

  const machineState = {
    value: defaultState,
    context: {
      code: '',
      startCharCount: currentOptions.controlSequenceCount,
      endCharCount: currentOptions.controlSequenceCount,
      startSeqTimeoutId: 0,
      endSeqTimeoutId: 0,
      receivingTimeoutId: 0,
    },
  };

  function resetToInitialState(forceReset = false) {
    if (!forceReset && !currentOptions.endChar && machineState.context.code.length >= currentOptions.minLength) {
      onCodeReceived(machineState.context.code);
    }
    machineState.value = defaultState;
    machineState.context.startCharCount = currentOptions.controlSequenceCount;
    machineState.context.endCharCount = currentOptions.controlSequenceCount;
    machineState.context.code = '';
  }

  function startSeqTimeout() {
    if (machineState.value === State.startSeq) {
      resetToInitialState(true);
    }
  }

  function receivingTimeout() {
    if (machineState.value === State.receiving) {
      resetToInitialState();
    }
  }

  function endSeqTimeout() {
    if (machineState.value === State.endSeq) {
      resetToInitialState(true);
    }
  }

  function send(event: EventKeyPress) {
    const key = currentOptions.codeMap.hasOwnProperty(event.key) ? currentOptions.codeMap[event.key] : event.key;
    if (!key) {
      return;
    }
    switch (machineState.value) {
      // @ts-expect-error Fallthrough case in switch.
      case State.waitingForChars:
        if (key !== currentOptions.startChar) {
          return;
        } else {
          machineState.value = State.startSeq;
          machineState.context.startSeqTimeoutId = setTimeout(
            startSeqTimeout,
            currentOptions.controlSequenceTimeoutInMs
          );
        }

      // eslint-disable-next-line no-fallthrough
      case State.startSeq:
        if (key !== currentOptions.startChar) {
          resetToInitialState(true);
          return;
        }

        machineState.context.startCharCount--;
        if (machineState.context.startCharCount > 0) {
          return;
        } else {
          machineState.value = State.receiving;
          clearTimeout(machineState.context.startSeqTimeoutId);
          machineState.context.code = '';
          machineState.context.startCharCount = currentOptions.controlSequenceCount;
          machineState.context.receivingTimeoutId = setTimeout(receivingTimeout, currentOptions.avgTimeByChar);
          return;
        }

      case State.receivingWithoutStartSeq:
        if (key.length <= currentOptions.maxKeyLength) {
          machineState.value = State.receiving;
          machineState.context.code = key;
          machineState.context.startCharCount = currentOptions.controlSequenceCount;
          machineState.context.receivingTimeoutId = setTimeout(receivingTimeout, currentOptions.avgTimeByChar);
        }
        return;

      // @ts-expect-error Fallthrough case in switch.
      case State.receiving:
        if (key !== currentOptions.endChar && key !== lineTerminator) {
          clearTimeout(machineState.context.receivingTimeoutId);
          if (key.length <= currentOptions.maxKeyLength) {
            machineState.context.code = machineState.context.code.concat(key);
          }
          machineState.context.receivingTimeoutId = setTimeout(receivingTimeout, currentOptions.avgTimeByChar);
          return;
        } else {
          machineState.value = State.endSeq;
          clearTimeout(machineState.context.receivingTimeoutId);
          machineState.context.endSeqTimeoutId = setTimeout(endSeqTimeout, currentOptions.controlSequenceTimeoutInMs);
        }

      // eslint-disable-next-line no-fallthrough
      case State.endSeq:
        machineState.context.endCharCount--;
        if (machineState.context.endCharCount > 0) {
          return;
        } else {
          onCodeReceived(machineState.context.code);
          clearTimeout(machineState.context.endSeqTimeoutId);
          resetToInitialState(true);
          return;
        }
    }
  }

  return {
    state: machineState,
    send,
  };
};

export type Receiver = ReturnType<typeof fastMachineFactory>;
