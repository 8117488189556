import { useCallback, useMemo } from 'react';
import { Interpreter, Typestate } from 'xstate';
import { BatchAndExpiration, Product, User } from '../../model';
import { EventTypes, ScannerViewContext, ScannerViewEvent } from './stateMachine';
import { ScannerViewStateSchema } from './stateMachine/state';
import { TransactionLeaveDialogResult } from './transactionLeaveDialog/TransactionNoteDialog.types';

export function useStateMachineEvents(
  send: Interpreter<ScannerViewContext, ScannerViewStateSchema, ScannerViewEvent, Typestate<ScannerViewContext>>['send']
) {
  const enterCode = useCallback(
    (code: string) =>
      send({
        type: EventTypes.ENTER_CODE,
        code,
      }),
    [send]
  );

  const enterEan = useCallback(
    (code: string) =>
      send({
        type: EventTypes.ENTER_EAN,
        code,
      }),
    [send]
  );

  const enterBatchAndExpiration = useCallback(
    (batchId: string, date?: string, amount: number = 1) =>
      send({
        type: EventTypes.ENTER_BATCH_AND_EXPIRATION,
        batchId,
        date,
        amount,
      }),
    [send]
  );

  const plusBatch = useCallback(
    (batchId: BatchAndExpiration['id'], amount = 1) =>
      send({
        type: EventTypes.PLUS_BATCH,
        batchId,
        amount,
      }),
    [send]
  );

  const minusBatch = useCallback(
    (batchId: BatchAndExpiration['id'], amount = 1) =>
      send({
        type: EventTypes.MINUS_BATCH,
        batchId,
        amount,
      }),
    [send]
  );

  const deleteBatch = useCallback(
    (batchId: BatchAndExpiration['id']) =>
      send({
        type: EventTypes.DISCARD_BATCH,
        batchId,
      }),
    [send]
  );

  const start = useCallback(
    (user: User) =>
      send({
        type: EventTypes.START,
        user: user,
      }),
    [send]
  );

  const closeError = useCallback(
    () =>
      send({
        type: EventTypes.CLOSE_ERROR,
      }),
    [send]
  );

  const closeItem = useCallback(
    () =>
      send({
        type: EventTypes.CLOSE_ITEM,
      }),
    [send]
  );

  const fixItemAmount = useCallback(
    (code: string) =>
      send({
        type: EventTypes.FIX_ITEM_AMOUNT,
      }),
    [send]
  );

  const plus = useCallback(
    (amount = 1) =>
      send({
        type: EventTypes.PLUS,
        amount,
      }),
    [send]
  );

  const minus = useCallback(
    (amount = 1) =>
      send({
        type: EventTypes.MINUS,
        amount,
      }),
    [send]
  );

  const changeAmount = useCallback(
    (amount = 1) =>
      send({
        type: EventTypes.CHANGE_AMOUNT,
        amount,
      }),
    [send]
  );

  const interruption = useCallback(
    (transactionChange: TransactionLeaveDialogResult) =>
      send({
        type: EventTypes.INTERRUPTION,
        interruptionType: transactionChange.status,
        note: transactionChange.note,
      }),
    [send]
  );

  const deleteCurrentItem = useCallback(
    () =>
      send({
        type: EventTypes.DELETE_ITEM,
      }),
    [send]
  );

  const complete = useCallback(() => {
    return send({
      type: EventTypes.COMPLETE,
    });
  }, [send]);

  const htmlDialog = useCallback(
    (instruction: string, productId?: number) => {
      return send({
        type: EventTypes.HTML_DIALOG,
        instruction,
        productId,
      });
    },
    [send]
  );

  const goBack = useCallback(
    (target: string) => {
      return send({
        type: EventTypes.GO_BACK,
        target,
      });
    },
    [send]
  );

  const editNote = useCallback(
    () =>
      send({
        type: EventTypes.EDIT_NOTE,
      }),
    [send]
  );

  const saveNote = useCallback(
    (transactionChange: TransactionLeaveDialogResult) =>
      send({
        type: transactionChange.saveAndComplete ? EventTypes.SAVE_AND_COMPLETE : EventTypes.SAVE_NOTE,
        note: transactionChange.note,
      }),
    [send]
  );

  const editPositions = useCallback(
    (productId?: Product['productId']) =>
      send({
        type: EventTypes.EDIT_POSITIONS,
        productId,
      }),
    [send]
  );

  const savePositions = useCallback(
    (newPosition: string, positionsToDelete: string[]) => {
      return send({
        type: EventTypes.SAVE_POSITIONS,
        newPosition: newPosition,
        positionsToDelete,
      });
    },
    [send]
  );

  const editSerialNumbers = useCallback(
    (productId?: Product['productId']) =>
      send({
        type: EventTypes.EDIT_SERIAL_NUMBERS,
        productId,
      }),
    [send]
  );

  const discardSerialNumber = useCallback(
    (value: string) => {
      return send({
        type: EventTypes.DISCARD_SERIAL_NUMBER,
        value,
      });
    },
    [send]
  );

  const selectItemAndOpenCounter = useCallback(
    (code: Product['code']) =>
      send({
        type: EventTypes.ASSIGN_CURRENT_CODE,
        code: code,
      }),
    [send]
  );

  return useMemo(() => {
    return {
      enterCode,
      enterEan,
      start,
      closeError,
      closeItem,
      fixItemAmount,
      plus,
      minus,
      changeAmount,
      interruption,
      deleteCurrentItem,
      complete,
      htmlDialog,
      goBack,
      editNote,
      saveNote,
      editPositions,
      savePositions,
      selectItemAndOpenCounter,
      editSerialNumbers,
      discardSerialNumber,
      enterBatchAndExpiration,
      plusBatch,
      minusBatch,
      deleteBatch,
    };
  }, [
    enterCode,
    enterEan,
    start,
    closeError,
    closeItem,
    fixItemAmount,
    plus,
    minus,
    changeAmount,
    interruption,
    deleteCurrentItem,
    complete,
    htmlDialog,
    goBack,
    editNote,
    saveNote,
    editPositions,
    savePositions,
    selectItemAndOpenCounter,
    editSerialNumbers,
    discardSerialNumber,
    enterBatchAndExpiration,
    plusBatch,
    minusBatch,
    deleteBatch,
  ]);
}
